import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import { FaLocationArrow, FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  InfoWindow,
} from "@react-google-maps/api";
import { useRef, useState, useEffect } from "react";
import { MAPS_API_KEY } from "../../../constants/redux/api/constants";
import {
  useCompanyServiceMutation,
  useRideFareMutation,
} from "../../../constants/redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";

const center = { lat: 13.067439, lng: 80.237617 };

const DistanceMap = ({
  setLocationData,
  selectSet,
  type,
  fareEstimation,
  setFareEstimation,
  userType,
  setUserTypeErr,
  selectUser,
  types,
  ridetypes,
  roundTime,
  name,
  mobile,
  email,
  primaryNo,
  setSelectUserErr,
  setSelectSetErr,
  setTypeErr,
  setRideTypeErr,
  setRoundTimeErr,
  setNameErr,
  setEmailErr,
  setMobileErr,
  setPrimaryNoErr,
  setRoundDateErr,
  roundDate,
  bookingStatus,
  date,
  time,
  setTimeErr,
  setDateErr,
  setBtnEnable
}) => {
  const dispatch = useDispatch();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAPS_API_KEY,
    libraries: ["places"],
  });

  const [rideFareApi] = useRideFareMutation();
  const [serviceApi] = useCompanyServiceMutation();

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  // const [fareEstimation, setFareEstimation] = useState("");
  const [distanceErr, setDistanceErr] = useState(false);
  const [originRefErr, setoriginRefErr] = useState(false);
  const [fromPincode, setFromPincode] = useState("");
  const [toPincode, setToPincode] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  if (!isLoaded) {
    // return <SkeletonText />;
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }, []);
  const getEstimation = (distance) => {
    const fromdata = new FormData();
    fromdata.append("distance", distance);
    fromdata.append("ride_id", selectSet?.value);
    fromdata.append("type", type?.value);
    dispatch(saveLoader(true));
    rideFareApi(fromdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          setFareEstimation(res?.estimated_ride_fare);
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  const getPincode = (lat, lng) => {
    return new Promise((resolve) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const postalCode = results[0].address_components.find((component) =>
            component.types.includes("postal_code")
          );
          if (postalCode) {
            resolve(postalCode.long_name);
          } else {
            resolve("Postal code not found");
          }
        } else {
          resolve("No results found");
        }
      });
    });
  };

  async function calculateRoute() {
    if (originRef.current.value === "" || destiantionRef.current.value === "") {
      toast.error("Enter from loaction and to loaction", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 2000,
      });
    } else if (userType == "") {
      setUserTypeErr(true);
    } else if (
      (userType?.value == "exist" &&
        (selectUser == "" ||
          selectSet == "" ||
          types == "" ||
          ridetypes == "" ||
          (bookingStatus == "4" &&
            (date?.length == "" || time?.length == "")) ||
          (ridetypes?.value == "4" &&
            (roundTime?.length == "" || roundDate?.length == "")))) ||
      (userType?.value == "new" &&
        (types == "" ||
          selectSet == "" ||
          ridetypes == "" ||
          (bookingStatus == "4" &&
            (date?.length == "" || time?.length == "")) ||
          (ridetypes?.value == "4" &&
            (roundTime?.length == "" || roundDate?.length == "")) ||
          name?.length == 0 ||
          mobile?.length == 0 ||
          email?.length == 0 ||
          primaryNo?.length == 0))
    ) {
      if (userType?.value == "exist") {
        setSelectUserErr(true);
        setSelectSetErr(true);
        setTypeErr(true);
        setRideTypeErr(true);
        if (bookingStatus == "4") {
          setTimeErr(true);
          setDateErr(true);
        }
        if (ridetypes?.value == "4") {
          setRoundTimeErr(true);
          setRoundDateErr(true);
        }
      } else {
        setSelectSetErr(true);
        setTypeErr(true);
        setRideTypeErr(true);
        setNameErr(true);
        setEmailErr(true);
        setMobileErr(true);
        setPrimaryNoErr(true);
        if (bookingStatus == "4") {
          setTimeErr(true);
          setDateErr(true);
        }
        if (ridetypes?.value == "4") {
          setRoundTimeErr(true);
          setRoundDateErr(true);
        }
      }
    } else {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: originRef.current.value,
        destination: destiantionRef.current.value,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setLocationData(results);

      // Get the pincode for the origin and destination
      const startLocation = results.routes[0].legs[0].start_location;
      const endLocation = results.routes[0].legs[0].end_location;

      // Get pincodes first
      const fromPincodePromise = getPincode(
        startLocation.lat(),
        startLocation.lng()
      );
      const toPincodePromise = getPincode(endLocation.lat(), endLocation.lng());

      const [fromPincode, toPincode] = await Promise.all([
        fromPincodePromise,
        toPincodePromise,
      ]);

      setFromPincode(fromPincode);
      setToPincode(toPincode);

      setDirectionsResponse(results);
      console.log(results, "results");
      let split_string = results.routes[0].legs[0].distance.text.split("km");
      const price = split_string[0];
      const distance = results.routes[0].legs[0].distance.text;
      const Duration = results.routes[0].legs[0].duration.text;
      findZone(fromPincode, toPincode, price, distance, Duration);
    }
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance("");
    setDuration("");
    setFromPincode("");
    setToPincode("");
    originRef.current.value = "";
    destiantionRef.current.value = "";
  }
  console.log(fromPincode, toPincode, "pincode");

  // useEffect(() => {
  //   if (fromPincode && toPincode) {
  //     findZone();
  //   }
  // }, [fromPincode, toPincode]);

  const findZone = (from, to, price, distance, duration) => {
    console.log("zooo");
    const fromdata = new FormData();
    fromdata.append("pickup_pincode", from);
    fromdata.append("drop_pincode", to);
    serviceApi(fromdata)
      .unwrap()
      .then((res) => {
        console.log(res, "zone");
        setBtnEnable(false)
        if (res?.status == "success") {
          getEstimation(price);
          setDistance(distance);
          setDuration(duration);
        }
      })
      .catch((err) => {
        setBtnEnable(true)
        console.log(err);
      });
  };
  return (
    <>
      {isLoaded ? (
        <>
          <div>
            <Flex
              position="relative"
              flexDirection="column"
              alignItems="center"
              h="80vh"
              w="100%"
            >
              <Box position="absolute" left={0} top={0} h="100%" w="100%">
                {/* Google Map Box */}
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  options={{
                    zoomControl: true,
                    streetViewControl: false,
                    mapTypeControl: true,
                    fullscreenControl: true,
                  }}
                  onLoad={(map) => setMap(map)}
                >
                  <Marker position={center} />
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap>
              </Box>
              <Box
                p={4}
                borderRadius="lg"
                m={4}
                bgColor="white"
                shadow="base"
                minW="container.md"
                zIndex="1"
              >
                <HStack spacing={2} justifyContent="space-between">
                  <Box flexGrow={1}>
                    <Autocomplete>
                      <div>
                        <input type="text" placeholder="From" ref={originRef} />
                        {originRefErr && (
                          <p className="err-p">Enter To Place</p>
                        )}
                      </div>
                    </Autocomplete>
                  </Box>
                  <Box flexGrow={1}>
                    <Autocomplete>
                      <div>
                        <input
                          type="text"
                          placeholder="To"
                          ref={destiantionRef}
                        />
                        {distanceErr && (
                          <p className="err-p">Enter From Place </p>
                        )}
                      </div>
                    </Autocomplete>
                  </Box>

                  <ButtonGroup>
                    <Button
                      colorScheme="purple"
                      type="submit"
                      onClick={calculateRoute}
                    >
                      Click Route
                    </Button>
                    <IconButton
                      aria-label="center back"
                      icon={<FaTimes />}
                      onClick={clearRoute}
                    />
                  </ButtonGroup>
                </HStack>
                <HStack spacing={4} mt={4} justifyContent="space-between">
                  <Text>Distance: {distance} </Text>
                  <Text>Duration: {duration} </Text>
                  <Text>Fare Estimation: {"Rs." + fareEstimation} </Text>
                  <IconButton
                    aria-label="center back"
                    icon={<FaLocationArrow />}
                    isRound
                    // onClick={() => {
                    //   map.panTo(center);
                    //   map.setZoom(15);
                    // }}
                  />
                </HStack>
              </Box>
            </Flex>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DistanceMap;
