import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Col, Form } from "react-bootstrap";
import { json, Link } from "react-router-dom";
import { bannerList, maleAvatar, Nologo } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import DriverHistorys from "./driverHistorys";
import { toast } from "react-toastify";
import axios from "axios";
import {
  useLazyDriverRideHistoryQuery,
  useLazyDriverViewRequestQuery,
  useLazyShareCabRideHistoryListQuery,
} from "../../../constants/redux/api/api";

const DriverProfile = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const Id = location?.state?.data;
  console.log(location);
  const [list, setList] = useState([]);

  const [viewData, setViewData] = useState({});
  const [detail, setDetails] = useState([]);
  const [aadh, setAadh] = useState();
  const [lic, setLic] = useState();
  const [vehic, setVechic] = useState();
  const [rcNo, setRc] = useState();
  const [aadhars, setAadhars] = useState();
  const [insuran, setInsuran] = useState();
  const [licNo, setLicNo] = useState();
  const [btn, setBtn] = useState(false);

  const [userData, setUserData] = useState();
  const [licenData, setLicenData] = useState();
  const [VehicleData, seVehicleData] = useState();
  const [aadhaarAdd, setAadhaarAdd] = useState();
  const [respon, setRespon] = useState();
  const [endDete, setEndDate] = useState();
  const [latLong, setLatLong] = useState();
  const [licenAdd, setLicenAdd] = useState();
  const [reason, setReason] = useState("");
  const [reasonErr, setReasonErr] = useState(false);
  const [data, setData] = useState({});
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  //RTK
  const [getViewApi] = useLazyDriverViewRequestQuery();
  const [rideHistoryApi] = useLazyDriverRideHistoryQuery();
  const [shareCabHistoryApi] = useLazyShareCabRideHistoryListQuery();
  // DRIVER LIST

  const viewDriver = () => {
    getViewApi(Id?.id)
      .unwrap()
      .then((res) => {
        console.log(res);
        setViewData(res?.driver);
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Id?.id) {
      viewDriver();
    }
  }, []);

  // PUSH NOTIFICATION

  const [image, setImage] = useState("");
  const [imagePre, setImagePre] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [btnEnable, setBtnEnable] = useState(false);

  const [input, setInput] = useState([]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const addNotify = () => {
    let data = {
      title: title,
      description: description,
      image: image,
      type: 4,
      driver_id: Id,
    };
    if (title?.length !== 0 && description?.length !== 0) {
      setBtnEnable(true);
    } else {
      toast("Please Fill All Details", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 1500,
      });
    }
  };
  const userHistory = () => {
    let params;
    if (from && to) {
      params = `${Id?.id}?start_date=${from}&end_date=${to}`;
    } else {
      params = `${Id?.id}`;
    }
    if (Id?.driver_type == "3") {
      shareCabHistoryApi(params)
        .unwrap()
        .then((res) => {
          console.log(res, "res");
          setList(res?.driver_ride_history);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      rideHistoryApi(params)
        .unwrap()
        .then((res) => {
          console.log(res, "res");
          setList(res?.driver_ride_history);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    userHistory();
  }, [from, to]);
  return (
    <aside>
      <TopHeader type="view-profile" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Profile">
            <article>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      {viewData?.name}
                    </p>
                    <img
                      className="avatar-img"
                      src={viewData?.img_url || maleAvatar}
                    />
                  </div>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver Name
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver Name"
                            className="bg-transparent"
                            type="text"
                            value={viewData?.name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Company Name
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver Name"
                            className="bg-transparent"
                            type="text"
                            value={viewData?.company_name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver ID
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver id"
                            className="bg-transparent"
                            type="text"
                            value={viewData?.id ? "#00" + viewData?.id : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Phone Number
                          </label>
                          <input
                            id="example-if-email"
                            name="Phone_Number"
                            placeholder="Phone Number"
                            className="bg-transparent"
                            type="text"
                            value={viewData?.mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Emergency Contact
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver Name"
                            className="bg-transparent"
                            type="text"
                            value="8838726354"
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={viewData?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Number
                          </label>
                          <input
                            placeholder="vehicle number"
                            className="bg-transparent"
                            type="text"
                            value={viewData?.vehicle_no}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            UPI ID
                          </label>
                          <input
                            placeholder="UPI ID"
                            className="bg-transparent"
                            type="text"
                            value={viewData?.upi_id}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Latitude
                          </label>
                          <input
                            placeholder="address"
                            type="text"
                            name="address"
                            className="bg-transparent"
                            value={viewData?.latitude}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Longitude
                          </label>
                          <input
                            placeholder="address"
                            type="text"
                            name="address"
                            className="bg-transparent"
                            value={viewData?.longitude}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <input
                            placeholder="address"
                            type="text"
                            name="address"
                            className="bg-transparent"
                            value={viewData?.address}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              {Id?.driver_type == "2" && (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Current Subscription Plan
                            </label>
                            <input
                              placeholder="Subscription plan"
                              type="text"
                              name="current_plan"
                              className="bg-transparent"
                              value={
                                data?.driver_subsctiption?.subscription?.name
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Subscription Expiry Date
                            </label>

                            <div class="input-group mb-3 ">
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Expiry Date"
                                aria-label="Expiry Date"
                                aria-describedby="basic-addon2"
                                // onChange={(e) => setEndDate(e.target.value)}
                                value={data?.driver_subsctiption?.end_date}
                                disabled
                              />
                              {/* <div class="input-group-append">
                                <button
                                  class="btn btn-outline-secondary "
                                  style={{ zIndex: "auto" }}
                                  type="button"
                                  // onClick={changeDate}
                                  disabled={btnEnable ? true : false}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Change Date"
                                >
                                  Change
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
              {Id?.driver_type == "3" && (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Total Share Paid
                            </label>
                            <input
                              placeholder="Paid"
                              type="text"
                              name="date"
                              className="bg-transparent"
                              value={data?.total_share_paid}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Outstanding Share Amount
                            </label>
                            <input
                              placeholder="Tax"
                              type="text"
                              name="date"
                              className="bg-transparent"
                              value={data?.outstanding_share_amt}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Rides
                          </label>
                          <input
                            placeholder="Rides"
                            type="text"
                            name="date"
                            className="bg-transparent"
                            value={data?.total_rides}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Cancelled Rides
                          </label>
                          <input
                            placeholder="Cancelled"
                            type="text"
                            name="Cancelled"
                            className="bg-transparent"
                            value={data?.cancel_rides}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Rating
                          </label>
                          {/* <p>{data?.total_ratings}</p> */}
                          {data?.total_ratings == 1 ? (
                            <p style={{ color: "#FBC740" }}>&#9733;</p>
                          ) : data?.total_ratings == 2 ? (
                            <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                          ) : data?.total_ratings == 3 ? (
                            <p style={{ color: "#FBC740" }}>
                              &#9733; &#9733; &#9733;
                            </p>
                          ) : data?.total_ratings == 4 ? (
                            <p style={{ color: "#FBC740" }}>
                              &#9733; &#9733; &#9733; &#9733;
                            </p>
                          ) : data?.total_ratings == 5 ? (
                            <p style={{ color: "#FBC740" }}>
                              &#9733; &#9733; &#9733; &#9733; &#9733;
                            </p>
                          ) : data?.total_ratings == 0 ? (
                            <p style={{ color: "#FBC740" }}>
                              &#9734; &#9734; &#9734; &#9734; &#9734;
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <DriverHistorys
                Id={Id}
                list={list}
                from={from}
                to={to}
                setTo={setTo}
                setFrom={setFrom}
              />
            </article>
          </Tab>
          <Tab eventKey="profile" title="Document">
            <article>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      {viewData?.name}
                    </p>
                    <img
                      className="avatar-img"
                      src={viewData?.img_url || maleAvatar}
                    />
                  </div>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={viewData?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="roles-page mt-0 d-flex flex-wrap">
                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Aadhar
                        </label>
                        <p>{viewData?.driverDocument?.aadhar_no}</p>
                        <img
                          src={
                            viewData?.driverDocument?.img_url_aadhar || Nologo
                          }
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>

                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          QR Image
                        </label>
                        <p>&#8203;</p>
                        <img
                          src={viewData?.img_url_qr || Nologo}
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>
                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Licence
                        </label>
                        <p>{viewData?.driverDocument?.licence_no}</p>
                        <img
                          src={
                            viewData?.driverDocument?.img_url_licence || Nologo
                          }
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>

                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Rc
                        </label>
                        <p>{viewData?.driverDocument?.rc_no}</p>
                        <img
                          src={viewData?.driverDocument?.img_url_rc || Nologo}
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>

                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Insurance Image
                        </label>
                        <p>&#8203;</p>
                        <img
                          src={
                            viewData?.driverDocument?.img_url_insurance
                              ? viewData?.driverDocument?.img_url_insurance
                              : Nologo
                          }
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3 mb-3 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Vehicle Image
                      </label>
                      <p>{viewData?.vehicle_no}</p>
                      <div className="roles-page mt-0 d-flex flex-wrap">
                        {viewData?.driverVehicleImages?.map((item, ind) => {
                          return (
                            <div
                              className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-3 mb-3 modal-label-input"
                              key={ind}
                            >
                              <img
                                src={item?.img_url || Nologo}
                                alt="no"
                                className="add_img object-fit-fill w-50"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Policy No
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={viewData?.driverDocument?.policy_no}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Policy Start Date
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={viewData?.driverDocument?.policy_start_date}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Policy End Date
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={viewData?.driverDocument?.policy_end_date}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              {btn == true ? (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Reason
                            </label>
                            <textarea
                              placeholder="reason"
                              type="text"
                              name="reason"
                              className="bg-transparent"
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                            {reason?.length == 0 && reasonErr && (
                              <span className="err">enter reason</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : null}
            </article>
          </Tab>
        </Tabs>
      </article>
      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add PushNotification</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Tittle
            </label>
            <input
              type="title"
              id="example-if-email"
              name="title"
              placeholder="title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group col-12 col-sm-12 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Description
                </label>
                <textarea
                  placeholder="Description"
                  type="text"
                  className="bg-transparent"
                  cols="30"
                  rows="10"
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Image
            </label>
            <input
              type="file"
              id="example-if-email"
              name="img"
              placeholder="img"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImagePre(URL.createObjectURL(e.target.files[0]));
              }}
            />
            {imagePre ? (
              <div className="w-90 prev-Image">
                <img className="w-100" src={imagePre} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setAddModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={addNotify}
            disabled={btnEnable == true ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default DriverProfile;
