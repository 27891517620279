import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import {
  useLazyCompanyScheduleBookingListQuery,
} from "../../../constants/redux/api/api";
import { GlobalFilter } from "../../../views/screens/Roles/GlobalFilter";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const data = [
  {
    id: 1,
    role: "Super Admin",
    status: "Active",
  },
  {
    id: 2,
    role: "Admin",
    status: "Active",
  },
  {
    id: 3,
    role: "Super Admin",
    status: "Active",
  },
  {
    id: 4,
    role: "Test Admin",
    status: "DeActive",
  },
];

const CompanyScheduleLisst = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));
  const [list, setList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  // RTK
  const [scheduleOrder] = useLazyCompanyScheduleBookingListQuery();

  const getList = () => {
    let params;
    if (from && to) {
      params = `?start_date=${from}&end_date=${to}`;
    } else {
      params = "";
    }
    scheduleOrder(params)
      .unwrap()
      .then((res) => {
        console.log(res?.pre_bookings, "res");
        setList(res?.pre_bookings);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, [from, to]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Schedule Ride ID",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <p>{"#" + value}</p>;
        },
      },
      {
        Header: "Passenger Name",
        accessor: "user.name",
        Cell: ({ value, row }) => {
          return (
            <p
              // style={{
              //   cursor: "pointer",
              //   "text-decoration-line": "underline",
              //   background: "none",
              //   color: "var(--bs-link-color)",
              // }}
              // onClick={() =>
              //   navigate("/user/view-profile", {
              //     state: { id: row.original.user_id },
              //   })
              // }
            >
              {value}
            </p>
          );
        },
      },

      {
        Header: "Booking Date and Time Stamp",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY, hh:mm A")}</p>;
        },
      },

      {
        Header: "Scheduled Ride Date and Time Stamp",
        accessor: "prebooking_time",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY, hh:mm A")}</p>;
        },
      },
      {
        Header: "From",
        accessor: "pickup_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "To",
        accessor: "drop_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Distance",
        accessor: "distance",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Cost and Payment Method",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Ride Duration",
        accessor: "total_hrs",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Options",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                onClick={() =>
                  navigate("/company/schedule-ride/view", {
                    state: { id: row.original.id, data: row.original },
                  })
                }
                className="comn-btn bg-primary edit-btn-svg"
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  return (
    <aside>
      <TopHeader type="schedule-ride" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
            <Col md={3} xs={6} className="px-2 my-2">
              <span>From Date</span>
              <input
                type="date"
                className="form-control"
                onChange={startDate}
                value={from}
              />
            </Col>
            <Col md={3} xs={6} className="px-2 my-2">
              <span>To Date</span>
              <input
                type="date"
                className="form-control"
                onChange={ToDate}
                value={to}
              />
            </Col>
          </div>
          <Table columns={columns} data={list} className="" />
        </div>
      </article>
    </aside>
  );
};

export default CompanyScheduleLisst;
