import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";
import {
  useLazyCompanyofflineAcceptViewQuery,
  useLazyOfflineAcceptViewQuery,
  useLazyScheduleViewCompanyQuery,
} from "../../../constants/redux/api/api";
import TopHeader2 from "../../../component/TopHeader2";

const CompanyCancelView = () => {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      name: "person1",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 2,
      name: "person2",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 3,
      name: "person3",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 4,
      name: "person4",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 5,
      name: "person5",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 6,
      name: "person6",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 7,
      name: "person7",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 8,
      name: "person8",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 9,
      name: "person9",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 10,
      name: "person10",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 11,
      name: "person11",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 12,
      name: "person12",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 13,
      name: "person13",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 14,
      name: "person14",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 15,
      name: "person15",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
  ];

  const [adminData, setadminData] = useState(data);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [list, setList] = useState([]);
  const [searcharr, setSearchArr] = useState("");
  const [viewData, setViewData] = useState({});
  //RTK
  const [viewDataApi] = useLazyCompanyofflineAcceptViewQuery();
  const [sheduleApi] = useLazyScheduleViewCompanyQuery();
  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const getList = () => {
    viewDataApi(Id)
      .unwrap()
      .then((res) => {
        console.log(res);
        setList(res?.offline_bookings);
        setViewData(res?.offline_bookings);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getView = () => {
    sheduleApi(Id)
      .unwrap()
      .then((res) => {
        console.log(res?.booking);
        setList(res?.booking);
        setViewData(res?.booking);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Id && (type == "reject" || type == "cancell")) {
      getList();
    }
  }, []);
  useEffect(() => {
    if (Id && type == "missedout") {
      getView();
    }
  }, []);

  return (
    <aside>
      <TopHeader2 type="view-rides" />
      <article className="p-3">
        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                ></p>
                {type != "missedout" && (
                  <img
                    className="avatar-img"
                    src={list?.driver?.img_url || maleAvatar}
                  />
                )}
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                ></p>
                <img
                  className="avatar-img"
                  src={list?.user?.img_url || maleAvatar}
                />
              </div>
              <Form className="d-flex flex-wrap">
                {type != "missedout" && (
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Driver Name
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Driver Name"
                          className="bg-transparent"
                          type="text"
                          value={list?.driver?.name}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Company Name
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Driver Name"
                          className="bg-transparent"
                          type="text"
                          value={list?.driver?.company_name}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="User Name"
                        className="bg-transparent"
                        type="text"
                        value={list?.user?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {type == "cancell" && (
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Cancelled By
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="User Name"
                          className="bg-transparent"
                          type="text"
                          value={list?.status == 2 ? "User" : "driver"}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Type
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="User Name"
                        className="bg-transparent"
                        type="text"
                        value={
                          list?.type == 1
                            ? "Intercity"
                            : list?.type == 2
                            ? "Intracity"
                            : list?.type == 3
                            ? "sharecab"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Type
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Type"
                        className="bg-transparent"
                        type="text"
                        value={
                          list?.ride_type == 1
                            ? "One Way"
                            : list?.ride_type == 2
                            ? "Schedule"
                            : list?.ride_type == 3
                            ? "Point To Point"
                            : list?.ride_type == 4
                            ? "Round"
                            : "-"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Status
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Status"
                        className="bg-transparent"
                        type="text"
                        value={
                          list?.status == 1
                            ? "Driver allocated"
                            : list?.status == 2
                            ? "Ride cancelled"
                            : list?.status == 3
                            ? "Driver on the way"
                            : list?.status == 4
                            ? "Driver reached to pickup"
                            : list?.status == 5
                            ? "Otp verified and ride started"
                            : list?.status == 6
                            ? "Reached to drop location"
                            : list?.status == 7
                            ? "Changed destination request"
                            : list?.status == 8
                            ? "Change destination accepted"
                            : list?.status == 9
                            ? "Change destination rejected"
                            : list?.status == 10
                            ? "Ride completed and driver acknowledged"
                            : list?.status == 11
                            ? "Change destination reached drop location"
                            : list?.status == 12
                            ? "Change destination and ride completed"
                            : list?.status == 13
                            ? "Ride cancelled by driver"
                            : list?.status == 14
                            ? "Return trip start"
                            : list?.status == 15
                            ? "Return trip complete"
                            : list?.status == 16
                            ? "Missedout"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        From
                      </label>
                      <p className="address-request textarea-height" disabled>
                      {type == "missedout"
                          ? list?.pickup_address
                          : list?.searchRide?.pickup_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        To
                      </label>
                      <p className="address-request textarea-height" disabled>
                      {type == "missedout"
                          ? list?.drop_address
                          : list?.searchRide?.drop_address}
                      </p>
                    </div>
                  </div>
                </div>
                {list?.searchRide?.searchRidePoints?.lenght > 0 && (
                  <div className="form-group col-12 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          points
                        </label>

                        {type == "missedout"
                          ? list?.searchRidePoints?.map((item) => {
                              return (
                                <p
                                  className="address-request textarea-height"
                                  disabled
                                >
                                  {item}
                                </p>
                              );
                            })
                          : list?.searchRide?.searchRidePoints?.map((item) => {
                              return (
                                <p
                                  className="address-request textarea-height"
                                  disabled
                                >
                                  {item}
                                </p>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Start Date and Time Stamp
                      </label>
                      <input
                        type="text"
                        name="license-number"
                        id="example-if-email"
                        placeholder="Start Date"
                        className="bg-transparent"
                        value={
                          list?.created_at
                            ? moment(list?.created_at).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                            : "-"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Cost
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Cost"
                        className="bg-transparent"
                        value={list?.final_amount || list?.amount}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </article>
      </article>
    </aside>
  );
};

export default CompanyCancelView;
