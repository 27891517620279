import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate ,useLocation} from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import Fair from "./fair";
import Zone from "./zone";
import FareSetting from "./fareSetting";
import Tax from "./tax";
import VehicleTypes from "./VehicleTypes";
import ZoneSettings from "./ZoneSettings";
import Companylist from "./companylist";

function Setting() {
  const location = useLocation();
  console.log("loc",location);
  const [active,setActive] = useState("Vechile")
  useEffect(()=>{
    if(location?.state?.tab){
    setActive(location?.state?.tab)
  }
  },[])
  console.log(active,"active");
  return (
    <>
      <aside>
        <TopHeader type="setting" />
        <article>
          <div className="p-3">
            <Tabs
              activeKey={active}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e)=>setActive(e)}
              fill
            >
              <Tab eventKey="Vechile" title="Vehicle Types">
                <VehicleTypes />
              </Tab>
              <Tab eventKey="Detail" title="Place">
                <Zone setActive={setActive}/>
              </Tab>
              <Tab eventKey="Tax" title="Margin">
                <Tax setActive={setActive}/>
              </Tab>
              <Tab eventKey="Zone" title="Zone">
               <ZoneSettings setActive={setActive}/>
              </Tab>
              <Tab eventKey="Company" title="Company">
               <Companylist setActive={setActive}/>
              </Tab>

            </Tabs>
          </div>
        </article>
      </aside>
    </>
  );
}

export default Setting;
