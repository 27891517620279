import React from "react";
import { BrowserRouter, HashRouter as Router } from "react-router-dom";
import { ReactRoute } from "./routes";
import { BallTriangle, LineWave, Oval } from "react-loader-spinner";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useToken from "./constants/Storage/useToken";
import { useDispatch, useSelector } from "react-redux";
import { saveAlertMessage } from "./constants/redux/slice/alertMessage";
import ErrorPopup from "./constants/Storage/ErrorPopup";

function App() {
  // const { token } = useToken();
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.alertMessage);
  const [loading, setLoading] = useState(false);

  // For Loader
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );

  // For Custom Loader
  const customLoader = useSelector((state) => state.loaderNoti);

  useEffect(() => {
    if (isSomeQueryPending) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [isSomeQueryPending]);
  useEffect(() => {
    if (customLoader == false) {
      setLoading(false);
    } else if (customLoader == true) {
      setLoading(true);
    }
  }, [customLoader]);

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "9999",
  };

  const onCloseClick = () => {
    dispatch(saveAlertMessage(null));
  };

  return (
    <Router>
      {alertMessage && (
        <ErrorPopup alertMessage={alertMessage} onCloseClick={onCloseClick} />
      )}
      
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#86ca17"
          ariaLabel="loading"
          wrapperStyle={style}
          wrapperClass
          visible={loading}
        />
      
      <ToastContainer autoClose={false} theme={"colored"} hideProgressBar />
      <ReactRoute />
    </Router>
  );
}

export default App;
