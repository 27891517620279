import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";
import { BsDownload } from "react-icons/bs";
import { useCompanyBookingHistoryViewMutation } from "../../../constants/redux/api/api";
import TopHeader2 from "../../../component/TopHeader2";

const CompanyBookingView = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [rideDReview, setRideDReview] = useState({});
  const [rideUReview, setRideUReview] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [viewData, setViewData] = useState({});

  const location = useLocation();
  const Id = location?.state?.id;
  const index = location?.state?.index;
  console.log(Id);
  // console.log(index);
  //RTK
  const [viewDataApi] = useCompanyBookingHistoryViewMutation();
  const getList = () => {
    viewDataApi(Id)
      .unwrap()
      .then((res) => {
        console.log(res);
        setViewData(res?.booking);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Id) {
      getList();
    }
  }, []);

  return (
    <aside>
      <TopHeader2 type="view-booking" />
      <article className="p-3">
        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              {viewData?.status == 10 ? (
                <div className="d-flex justify-content-end py-3">
                  <a
                    href={`https://testingsharescript.ninositsolution.com/company/completed-booking-invoice/${Id}`}
                    // href={`http://autokar-crm.premiumfreshfoods.com/#/booking/view-booking/invoice?Id=${Id}&index=${index}`}
                    // className="p-2 comn-btn active-deact-btn bg-primary d-flex align-items-center"
                    // target="_blank"
                  >
                    <BsDownload className="mx-1" /> Invoice
                  </a>
                </div>
              ) : viewData?.status == 12 || viewData?.status == 15? (
                <div className="d-flex justify-content-end py-3">
                  <a
                    href={`https://testingsharescript.ninositsolution.com/company/completed-booking-invoice/${Id}`}
                    // href={`http://autokar-crm.premiumfreshfoods.com/#/booking/view-booking/invoice?Id=${Id}&index=${index}`}
                    // className="p-2 comn-btn active-deact-btn bg-primary d-flex align-items-center"
                    // target="_blank"
                  >
                    <BsDownload className="mx-1" /> Invoice
                  </a>
                </div>
              ) : null}

              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                ></p>
                <img
                  className="avatar-img"
                  src={viewData?.driver?.img_url || maleAvatar}
                />
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                ></p>
                <img
                  className="avatar-img"
                  src={viewData?.user?.img_url || maleAvatar}
                />
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Driver Name"
                        className="bg-transparent"
                        type="text"
                        value={viewData?.driver?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Company Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Company Name"
                        className="bg-transparent"
                        type="text"
                        value={viewData?.driver?.company_name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="User Name"
                        className="bg-transparent"
                        type="text"
                        value={viewData?.user?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride ID
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Ride Id"
                        className="bg-transparent"
                        type="text"
                        value={
                          viewData?.ride_id ? "#" + viewData?.ride_id : "-"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Type
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Type"
                        className="bg-transparent"
                        type="text"
                        value={
                          viewData?.ride_type == 1
                            ? "Oneway"
                            : viewData?.ride_type == 2
                            ? "Schedule"
                            : viewData?.ride_type == 3
                            ? "Point To Points"
                            : viewData?.ride_type == 4
                            ? "Round"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Type
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Type"
                        className="bg-transparent"
                        type="text"
                        value={
                          viewData?.type == 1
                            ? "Intercity"
                            : viewData?.type == 2
                            ? "Intracity"
                            : viewData?.type == 3
                            ? "ShareCab"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Status
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Status"
                        className="bg-transparent"
                        type="text"
                        value={
                          viewData?.status == 1
                            ? "Driver allocated"
                            : viewData?.status == 2
                            ? "Ride cancelled"
                            : viewData?.status == 3
                            ? "Driver on the way"
                            : viewData?.status == 4
                            ? "Driver reached to pickup"
                            : viewData?.status == 5
                            ? "Otp verified and ride started"
                            : viewData?.status == 6
                            ? "Reached to drop location"
                            : viewData?.status == 7
                            ? "Changed destination request"
                            : viewData?.status == 8
                            ? "Change destination accepted"
                            : viewData?.status == 9
                            ? "Change destination rejected"
                            : viewData?.status == 10
                            ? "Ride completed and driver acknowledged"
                            : viewData?.status == 11
                            ? "Change destination reached drop location"
                            : viewData?.status == 12
                            ? "Change destination and ride completed"
                            : viewData?.status == 13
                            ? "Ride cancelled by driver"
                            : viewData?.status == 14
                            ? "Return trip start"
                            : viewData?.status == 15
                            ? "Return trip complete"
                            : viewData?.status == 16
                            ? "Missedout"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        OTP
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="otp"
                        className="bg-transparent"
                        value={viewData?.otp}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Cost
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Cost"
                        className="bg-transparent"
                        value={viewData?.final_amount}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Payment Method
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Payment"
                        className="bg-transparent"
                        value={
                          viewData?.payment_type == 1
                            ? "Manual pay"
                            : viewData?.payment_type == 2
                            ? "UPI"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Start Date and Time Stamp
                      </label>
                      <input
                        type="text"
                        name="license-number"
                        id="example-if-email"
                        placeholder="Start Date"
                        className="bg-transparent"
                        value={
                          viewData?.ride_started_time
                            ? moment(viewData?.ride_started_time).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                            : moment(viewData?.created_at).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride End Date and Time stamp
                      </label>
                      <input
                        placeholder="End Date"
                        className="bg-transparent"
                        type="text"
                        value={
                          viewData?.status == 3
                            ? "Progress"
                            : viewData?.status == 4
                            ? "Progress"
                            : viewData?.status == 5
                            ? "Progress"
                            : viewData?.status == 6
                            ? "Progress"
                            : viewData?.status == 7
                            ? "Progress"
                            : viewData?.status == 8
                            ? "Progress"
                            : viewData?.status == 9
                            ? "Progress"
                            : viewData?.status == 10
                            ? viewData?.updated_at
                              ? moment(viewData?.updated_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"
                            : viewData?.status == 11
                            ? "-"
                            : viewData?.status == 12
                            ? viewData?.updated_at
                              ? moment(viewData?.updated_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"
                            : viewData?.status == 1
                            ? "Progress"
                            : viewData?.status == 2
                            ? viewData?.updated_at
                              ? moment(viewData?.updated_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"
                            : viewData?.updated_at
                            ? moment(viewData?.updated_at).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                            : "-"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12  ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        From
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {viewData?.searchRide?.pickup_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        To
                      </label>
                      {/* <input
                        placeholder="to"
                        type="text"
                        name="aadhaar number"
                        className="bg-transparent"
                        value={list?.ride_detail?.drop_address}
                        disabled
                      /> */}
                      <p className="address-request textarea-height" disabled>
                        {viewData?.searchRide?.drop_address}
                      </p>
                    </div>
                  </div>
                </div>
                {viewData?.searchRide?.searchRidePoints?.lenght > 0 && (
                  <div className="form-group col-12 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          points
                        </label>
                        {/* <input
                        placeholder="to"
                        type="text"
                        name="aadhaar number"
                        className="bg-transparent"
                        value={list?.ride_detail?.drop_address}
                        disabled
                      /> */}
                        {viewData?.searchRide?.searchRidePoints?.map((item) => {
                          return (
                            <p
                              className="address-request textarea-height"
                              disabled
                            >
                              {item}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Review
                      </label>
                      {rideUReview?.user_rating == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : rideUReview?.user_rating == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : rideUReview?.user_rating == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : rideUReview?.user_rating == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : rideUReview?.user_rating == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {rideUReview?.user_review}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Review
                      </label>
                      {rideDReview?.driver_rating == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : rideDReview?.driver_rating == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : rideDReview?.driver_rating == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : rideDReview?.driver_rating == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : rideDReview?.driver_rating == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : (
                        ""
                      )}
                      {/* {} */}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {rideDReview?.driver_review}
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </article>
      </article>
      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-2 ">
            <p>Invoice</p>
          </div>
          <div>
            <button
              className="p-2 py-1 comn-btn active-deact-btn bg-primary"
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Ride ID
                </label>
                <input
                  id="example-if-email"
                  name="Phone_Number"
                  placeholder="Ride Id"
                  className="bg-transparent"
                  type="text"
                  value={list?.id ? "#00" + list?.id : "-"}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Ride Type
                </label>
                <input
                  id="example-if-email"
                  name="Phone_Number"
                  placeholder="Type"
                  className="bg-transparent"
                  type="text"
                  value={
                    list?.ride_type == 1
                      ? "Ride Now"
                      : list?.ride_type == 2
                      ? "Pre Booking"
                      : list?.ride_type == 3
                      ? "Offline Booking by Admin"
                      : ""
                  }
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Driver Name
                </label>
                <input
                  id="example-if-email"
                  name="Phone_Number"
                  placeholder="Driver Name"
                  className="bg-transparent"
                  type="text"
                  value={list?.driver?.name}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  User Name
                </label>
                <input
                  id="example-if-email"
                  name="Phone_Number"
                  placeholder="User Name"
                  className="bg-transparent"
                  type="text"
                  value={list?.user?.name}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group col-12  ps-0">
          <div className="form-check ps-0">
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                From
              </label>
              <p className="address-request textarea-height" disabled>
                {list?.searchRide?.pickup_address}
              </p>
            </div>
          </div>
        </div>
        <div className="form-group col-12 ps-0">
          <div className="form-check ps-0">
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                To
              </label>
              <p className="address-request textarea-height" disabled>
              {list?.searchRide?.drop_address}
              </p>
            </div>
          </div>
        </div>
        {viewData?.searchRide?.searchRidePoints?.lenght > 0 && (
                  <div className="form-group col-12 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          points
                        </label>
                        {viewData?.searchRide?.searchRidePoints?.map((item) => {
                          return (
                            <p
                              className="address-request textarea-height"
                              disabled
                            >
                              {item}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
          <div className="form-check ps-0">
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Ride Cost
              </label>
              <input
                type="text"
                id="example-if-email"
                placeholder="Cost"
                className="bg-transparent"
                value={list?.final_amount}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
          <div className="form-check ps-0">
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Payment Method
              </label>
              <input
                type="text"
                id="example-if-email"
                placeholder="Payment"
                className="bg-transparent"
                value={
                  list?.payment_type == 1
                    ? "Manual pay"
                    : list?.payment_type == 2
                    ? "UPI"
                    : ""
                }
                disabled
              />
            </div>
          </div>
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
          <div className="form-check ps-0">
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Ride Status
              </label>
              <input
                id="example-if-email"
                name="Phone_Number"
                placeholder="Status"
                className="bg-transparent"
                type="text"
                value={
                  list?.status == 10
                    ? "Completed"
                    : list?.status == 12
                    ? "Completed"
                    : " "
                }
                disabled
              />
            </div>
          </div>
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
          <div className="form-check ps-0">
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Ride Start Date and Time Stamp
              </label>
              <input
                type="text"
                name="license-number"
                id="example-if-email"
                placeholder="Start Date"
                className="bg-transparent"
                value={
                  list?.ride_started_time
                    ? moment(list?.created_at).format("DD/MM/YYYY, hh:mm A")
                    : moment(list?.created_at).format("DD/MM/YYYY, hh:mm A")
                }
                disabled
              />
            </div>
          </div>
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
          <div className="form-check ps-0">
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Ride End Date and Time stamp
              </label>
              <input
                type="text"
                name="license-number"
                id="example-if-email"
                placeholder="Start Date"
                className="bg-transparent"
                value={
                  list?.ride_started_time
                    ? moment(list?.updated_at).format("DD/MM/YYYY, hh:mm A")
                    : moment(list?.updated_at).format("DD/MM/YYYY, hh:mm A")
                }
                disabled
              />
            </div>
          </div>
        </div>
      </Modal>
    </aside>
  );
};

export default CompanyBookingView;
