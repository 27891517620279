import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useLazyMarginListQuery,
  useLazyMarginViewQuery,
  useMarginAddMutation,
  useMarginDeleteMutation,
  useMarginEditMutation,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      {/* <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link>
        </Col>
      </Row> */}
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      {/* <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row> */}
      {/*paginations  */}
    </>
  );
}

const Tax = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));
  const [list, setList] = useState([]);

  const [currentItems, setCurrentItems] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [input, setInput] = useState([]);
  const [btn, setBtn] = useState(false);
  const [sub, setSub] = useState();
  const [value, setValue] = useState("");
  const [valueErr, setValueErr] = useState(false);
  const [id, setId] = useState("");

  //RTK

  const [marginListApi] = useLazyMarginListQuery();
  const [deleteMarginApi] = useMarginDeleteMutation();
  const [addMarginApi] = useMarginAddMutation();
  const [editMarginApi] = useMarginEditMutation();
  const [viewMarginApi] = useLazyMarginViewQuery();
  //vehicle list
  const getList = () => {
    marginListApi()
      .unwrap()
      .then((res) => {
        // console.log(res, "res");
        setList(res?.settings);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
  }, []);
  const viewCompany = (id) => {
    viewMarginApi(id)
      .unwrap()
      .then((res) => {
        // console.log(res?.company);
        const data = res?.setting;
        setValue(data?.value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // useEffect(() => {
  //   if (id) {
  //     viewCompany();
  //   }
  // }, []);

  const deleteFares = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        deleteMarginApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
            setValue("")
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };

  const addCompany = () => {
    if (value?.length == "") {
      setValueErr(true);
    } else {
      let formData = new FormData();
      formData.append("value", value);

      if (editModal) {
        setBtn(true);
        formData.append("_method", "PUT");
        editMarginApi({ payload: formData, id: id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            // navigate("/setting");
            setEditModal(false);
            setValue("")
            setBtn(false);
            getList();
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      } else {
        setBtn(true);
        addMarginApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            // navigate("/setting");
            setBtn(false);
            setAddModal(false);
            setValue("")
            getList();
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      }
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },

      {
        Header: "Value",
        accessor: "value",
        Cell: ({ value, row }) => {
          return (
            <>
              <p>{value}</p>
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div >
                <button
                style={{ margin: "0px 5px"}}
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() => {
                    viewCompany(row.original.id);
                    setId(row.original.id);
                    setEditModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <button
                  className="comn-btn bg-danger edit-btn-svg"
                  onClick={() => deleteFares(row.original.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
console.log(value,"value");
  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          {/* <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end"> */}
            {list?.length < 1 && (
              // <div className="flex-ac-jc">
                <button
                  onClick={() => setAddModal(true)}
                  className="comn-btn bg-primary1 px-3 py-2"
                  style={{
                    width: "150px",
                    margin: "auto 0 10px auto",
                    display: "block",
                  }}
                >
                  Add Margin
                </button>
              // {/* </div> */}
            )}
          {/* </div> */}
          <Table columns={columns} data={list} />
        </div>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Settings</h4>
          </div>

          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Percentage
            </label>
            <input
              type="number"
              id="example-if-email"
              name="value"
              placeholder="text"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            {value?.length == 0 && valueErr && (
              <p className="err-p">Enter Value</p>
            )}
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setAddModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={addCompany}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Edit Location</h4>
          </div>

          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Percentage
            </label>
            <input
              type="number"
              id="example-if-email"
              name="value"
              placeholder="value"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            {value?.length == 0 && valueErr && (
              <p className="err-p">Enter Value</p>
            )}
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setEditModal(false);
              setId("");
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className={`comn-btn bg-success px-3 py-2 me-2${btn && "opacity-50"}`}
            data-dismiss="modal"
            onClick={addCompany}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default Tax;
