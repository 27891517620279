import React, { useEffect, useState } from "react";
import TopHeader from "../../../component/TopHeader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Reject from "./reject";
import Request from "./request";
import { useLazyDriverListRequestQuery } from "../../../constants/redux/api/api";

const DriverRequest = () => {
  const [reject, setReject] = useState([]);
  const [request, setRequest] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    // setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  //RTK
  const [driversListApi] = useLazyDriverListRequestQuery();

  const getList = () => {
    let params = "";
    if (from) {
      params = `?start_date=${from}`;
    }
    if (from && to) {
      params = params + `&end_date=${to}`;
    }
    driversListApi(params)
      .unwrap()
      .then((res) => {
        setRequest(res?.request_drivers);
        setReject(res?.reject_drivers);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, [from, to]);

  return (
    <aside>
      <TopHeader type="driver-request" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="Detail-View" title="Request List">
            <Request
              request={request}
              setFrom={setFrom}
              setTo={setTo}
              to={to}
              from={from}
            />
          </Tab>
          <Tab eventKey="Detail" title="Rejected List">
            <Reject
              reject={reject}
              setFrom={setFrom}
              setTo={setTo}
              to={to}
              from={from}
            />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default DriverRequest;
