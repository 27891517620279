import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const PaidList = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const action = "total_hrs";

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Drivername",
        accessor: "driver.name",
        Cell: ({ value, row }) => {
          return (
            <button
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() =>
                navigate("/driver/view-profile", {
                  state: { id: row.original.driver_id },
                })
              }
            >
              {value}{" "}
            </button>
          );
        },
      },

      {
        Header: "Vehicle Number",
        accessor: "driver.driver_proofs[0].number",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Ride Count",
        accessor: "total_ride",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Tax Amount",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Date and Time",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY hh:mm A")}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              className={
                value != 3
                  ? "p-2 py-1 comn-btn active-deact-btn bg-danger"
                  : "p-2 py-1 comn-btn active-deact-btn bg-success"
              }
            >
              {value != 3 ? "Not Paid" : "Paid"}
            </button>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                onClick={() =>
                  navigate("/driver/view-profile", {
                    state: { id: row.original.driver_id },
                  })
                }
                className="comn-btn bg-primary edit-btn-svg"
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const [list, setList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  const getList = () => {
    let params = `?start_date=${from}&end_date=${to}`;
    if (from && to) {
    } else if (!from && !to) {
    }
  };

  useEffect(() => {
    getList();
  }, [from, to]);

  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
            <Col md={3} xs={6} className="px-2 my-2">
              <span>From Date</span>
              <input
                type="date"
                className="form-control"
                onChange={startDate}
                value={from}
              />
            </Col>
            <Col md={3} xs={6} className="px-2 my-2">
              <span>To Date</span>
              <input
                type="date"
                className="form-control"
                onChange={ToDate}
                value={to}
              />
            </Col>
          </div>
          <Table columns={columns} data={list} className="" />
        </div>
      </article>
    </aside>
  );
};

export default PaidList;

// import React, { useEffect, useState } from "react";
// import { Modal, Col } from "react-bootstrap";
// import TopHeader from "../../../component/TopHeader";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-regular-svg-icons";
// import { Form } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import ReactPaginate from "react-paginate";
// import { api } from "../../../constant/Services";
// import moment from "moment/moment";

// const data = [
//   {
//     id: 1,
//     name: "person1",
//     mobile: 948727366,
//     vehicleNo: "TN18AT6353",
//     rc: "86753798545",
//     status: "Active",
//   },
//   {
//     id: 2,
//     name: "person2",
//     mobile: 948727367,
//     vehicleNo: "TN18AT6354",
//     rc: "86753798546",
//     status: "Active",
//   },
//   {
//     id: 3,
//     name: "person3",
//     mobile: 948727368,
//     vehicleNo: "TN18AT6355",
//     rc: "86753798547",
//     status: "Active",
//   },
//   {
//     id: 4,
//     name: "person4",
//     mobile: 948727369,
//     vehicleNo: "TN18AT6356",
//     rc: "86753798548",
//     status: "DeActive",
//   },
//   {
//     id: 5,
//     name: "person5",
//     mobile: 948727366,
//     vehicleNo: "TN18AT6353",
//     rc: "86753798545",
//     status: "Active",
//   },
//   {
//     id: 6,
//     name: "person6",
//     mobile: 948727366,
//     vehicleNo: "TN18AT6353",
//     rc: "86753798545",
//     status: "Active",
//   },
//   {
//     id: 7,
//     name: "person7",
//     mobile: 948727366,
//     vehicleNo: "TN18AT6353",
//     rc: "86753798545",
//     status: "Active",
//   },
//   {
//     id: 8,
//     name: "person8",
//     mobile: 948727366,
//     vehicleNo: "TN18AT6353",
//     rc: "86753798545",
//     status: "Active",
//   },
//   {
//     id: 9,
//     name: "person9",
//     mobile: 948727366,
//     vehicleNo: "TN18AT6353",
//     rc: "86753798545",
//     status: "Active",
//   },
//   {
//     id: 10,
//     name: "person10",
//     mobile: 948727366,
//     vehicleNo: "TN18AT6353",
//     rc: "86753798545",
//     status: "Active",
//   },
// ];

// function PaidList() {
//   const navigate = useNavigate();
//   const [adminData, setadminData] = useState([]);

//   const [itemsPerPage, setItemsPerPage] = useState(10);

//   const [currentItems, setCurrentItems] = useState([]);
//   const [pageCount, setPageCount] = useState(0);
//   const [counter, setCounter] = useState(0);
//   const [last, setLast] = useState(10);
//   const [itemOffset, setItemOffset] = useState(0);

//   const [searcharr, setSearchArr] = useState("");

//   useEffect(() => {
//     let temp = [...adminData];

//     if (searcharr == "") {
//       const endOffset = itemOffset + itemsPerPage;

//       setCurrentItems(adminData?.slice(itemOffset, endOffset));
//       setPageCount(Math.ceil(adminData?.length / itemsPerPage));
//     } else {
//       let datas = temp?.filter(
//         (val) =>
//           val?.driver?.name.toLowerCase().includes(searcharr.toLowerCase()) ||
//           val?.driver?.driver_proofs[0]?.number
//             .toLowerCase()
//             .includes(searcharr.toLowerCase())
//       );

//       setCurrentItems(datas);
//     }
//   }, [searcharr]);

//   useEffect(() => {
//     const endOffset = itemOffset + itemsPerPage;

//     setCurrentItems(adminData?.slice(itemOffset, endOffset));
//     setPageCount(Math.ceil(adminData?.length / itemsPerPage));
//   }, [itemOffset, itemsPerPage, adminData]);

//   const handlePageClick = (event) => {
//     const newOffset = (event.selected * itemsPerPage) % adminData?.length;
//     setItemOffset(newOffset);

//     let temp2 = event.selected;
//     setCounter(temp2);

//     let temp = event.selected + 1 + "0";
//     setLast(temp);
//   };

//   const setListItems = (e) => {
//     const value = e.target.value;

//     setItemsPerPage(parseInt(value));
//   };

//   const [from, setFrom] = useState();
//   const [to, setTo] = useState();

//   const startDate = (e) => {
//     setFrom(e.target.value);
//     setTo("");
//   };

//   const ToDate = (e) => {
//     setTo(e.target.value);
//   };

//   const getList = () => {
//     let params = `?start_date=${from}&end_date=${to}`;
//     if (from && to) {
//       api.payTaxListFilter(params).then((res) => {
//         let temp = res?.pay_taxes.filter((i) => i?.status == 3);
//         setadminData(temp.reverse());
//       });
//     } else if (!from && !to) {
//       api.payTaxList().then((res) => {
//         let temp = res?.pay_taxes.filter((i) => i?.status == 3);
//         setadminData(temp.reverse());
//       });
//     }
//   };

//   useEffect(() => {
//     getList();
//   }, [from, to]);

//   // const getList = () => {
//   //   api.payTaxList().then((res) => {
//   //     let temp = res?.pay_taxes.filter((i) => i?.status == 3);
//   //     setadminData(temp.reverse());
//   //   });
//   // };

//   return (
//     <aside>
//       <article>
//         <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
//           <Col md={3} xs={6} className="px-2 my-2">
//             <span>From Date</span>
//             <input
//               type="date"
//               className="form-control"
//               onChange={startDate}
//               value={from}
//             />
//           </Col>
//           <Col md={3} xs={6} className="px-2 my-2">
//             <span>To Date</span>
//             <input
//               type="date"
//               className="form-control"
//               onChange={ToDate}
//               value={to}
//             />
//           </Col>
//           <Col md={4} xs={6} className="px-2 my-2">
//             <Form className="search-input me-2">
//               <input
//                 onChange={(e) => setSearchArr(e.target.value)}
//                 placeholder="search..."
//                 type="search"
//                 className="bg-transparent rounded-1 px-2 py-1 form-control"
//               />
//             </Form>
//           </Col>
//         </div>
//         <div className="p-2 bg-white rounded-2">
//           <table className="custom-table">
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Drivername</th>
//                 <th>Vehicle Number</th>
//                 <th>Ride Count</th>
//                 <th>Tax Amount</th>
//                 <th>Date and Time</th>
//                 <th className="text-center">Status</th>
//                 <th className="text-center">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentItems?.map((list, index) => (
//                 <tr key={index}>
//                   <td>
//                     {index == 9
//                       ? last
//                       : counter
//                       ? `${counter}${index + 1}`
//                       : index + 1}
//                   </td>
//                   <td
//                     style={{
//                       cursor: "pointer",
//                       "text-decoration-line": "underline",
//                       background: "none",
//                       color: "var(--bs-link-color)",
//                     }}
//                     onClick={() =>
//                       navigate("/driver/view-profile", {
//                         state: { id: list?.driver?.id },
//                       })
//                     }
//                   >
//                     {list?.driver?.name}
//                   </td>
//                   <td>{list?.driver?.driver_proofs[0]?.number}</td>
//                   <td>{list?.total_ride}</td>
//                   <td>{list?.amount}</td>
//                   <td>{moment(list?.updated_at).format("DD/MM/YYYY")}</td>
//                   <td className="text-center">
//                     <button
//                       className={
//                         list?.status != 3
//                           ? "comn-btn active-deact-btn bg-danger"
//                           : "comn-btn active-deact-btn bg-success"
//                       }
//                     >
//                       {list.status != 3 ? "Deactived" : "Paid"}
//                     </button>
//                   </td>
//                   <td className="flex-ac text-center">
//                     <Link
//                       state={{ id: list.id }}
//                       to="/driver/view-profile"
//                       className="comn-btn bg-primary edit-btn-svg"
//                     >
//                       <FontAwesomeIcon icon={faEye} />
//                     </Link>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//         <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
//           <ReactPaginate
//             nextLabel=">"
//             onPageChange={handlePageClick}
//             pageRangeDisplayed={3}
//             marginPagesDisplayed={2}
//             pageCount={pageCount}
//             previousLabel="<"
//             pageClassName="page-item"
//             pageLinkClassName="page-link"
//             previousClassName="page-item"
//             previousLinkClassName="page-link"
//             nextClassName="page-item"
//             nextLinkClassName="page-link"
//             breakLabel="..."
//             breakClassName="page-item"
//             breakLinkClassName="page-link"
//             containerClassName="pagination"
//             activeClassName="active"
//             renderOnZeroPageCount={null}
//           />
//         </div>
//       </article>
//     </aside>
//   );
// }

// export default PaidList;
