import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import { useLazyCompanyCancelListQuery } from "../../../constants/redux/api/api";
import CompanyRejected from "./CompanyRejected";
import CompanyMissedOut from "./CompanyMissedOut";
import CompanyCancelled from "./CompanyCancelled";

const CompanyCancelRide = () => {
  const [rejectedlist, setRejectedList] = useState([]);
  const [missedlist, setMissedList] = useState([]);
  const [cancellist, setCancellList] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  //RTK
  const [cancelOrderApi] = useLazyCompanyCancelListQuery();
  //vehicle list
  const getList = () => {
    let params;
    if (from && to) {
      params = `?start_date=${from}&end_date=${to}`;
    } else {
      params = "";
    }
    cancelOrderApi(params)
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        setRejectedList(res?.rejected_rides);
        setMissedList(res?.missed_rides);
        setCancellList(res?.cancel_rides);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, [from, to]);
  return (
    <aside>
      <TopHeader />
      <article>
        <div className="p-3">
          <Tabs defaultActiveKey="Detail-View">
            <Tab eventKey="Detail-View" title="Rejected">
              <CompanyRejected
                rejectedlist={rejectedlist}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                from={from}
              />
            </Tab>
            <Tab eventKey="Detail" title="Cancelled">
              <CompanyCancelled
                cancellist={cancellist}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                from={from}
              />
            </Tab>
            <Tab eventKey="View" title="Missed Out">
              <CompanyMissedOut
                missedlist={missedlist}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                from={from}
              />
            </Tab>
          </Tabs>
        </div>
      </article>
    </aside>
  );
};

export default CompanyCancelRide;
