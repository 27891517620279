import React from "react";
import { useNavigate } from "react-router-dom";
import { FourHandred, FourNotFour } from "../../assets/img";
// import useToken from "../../constants/Storage/useToken";

function FourHandredPage() {
  // const { token } = useToken();
  const navigate = useNavigate();
  const token = "";
  return (
    <div>
      <img
        src={FourNotFour}
        className="w-100"
        onClick={() => {
          token ? navigate("/") : navigate("/login");
        }}
      />
    </div>
  );
}

export default FourHandredPage;
