import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Link, NavLink, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import TopHeader from "../../../component/TopHeader";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mt-4">
        <Col className="d-flex align-items-center">
          Show
          <select
            className="form-control w-auto mx-2"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          entries
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </Col>
      </Row>
      <BTable className="mt-3" hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between">
        <Col>
          <span className="d-flex align-items-center">
            Page{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            | Go to page:{" "}
            <input
              type="number"
              className="form-control ml-2"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
const ReferReport = ({ reports }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  useEffect(()=>{
    if(Array.isArray(reports))
     setList(reports)
   },[reports])

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },

      {
        Header: "Refferer",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <span
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() => {
                list?.status == 1
                  ? navigate("/user/view-profile", {
                      state: { id: row.original.user_id },
                    })
                  : navigate("/driver/view-profile", {
                      state: { id: row.original.driver_id },
                    });
              }}
            >
              {value == 1
                ? row.original.user_refferal !== null
                  ? row.original.user_refferal
                  : ""
                : row.original.refferer !== null
                ? row.original.refferer
                : ""}
            </span>
          );
        },
      },
      {
        Header: "User Account",
        accessor: "sta",
        Cell: ({ value, row }) => {
          return <p>{row.original.status == 1 ? "User" : "Driver"}</p>;
        },
      },
      {
        Header: "Refered Date",
        accessor: "stat",
        Cell: ({ value, row }) => {
          return (
            <p>
              {row.original.status == 1
                ? moment(row.original.user_refferal?.created_at).format(
                    "DD/MM/YYYY"
                  )
                : moment(row.original.refferer?.created_at).format(
                    "DD/MM/YYYY"
                  )}
            </p>
          );
        },
      },

      // {
      //   Header: "Reffer Person",
      //   accessor: "name",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },

      {
        Header: "Installed Date",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY")}</p>;
        },
      },
      {
        Header: "Registered Account",
        accessor: "reg",
        Cell: ({ value, row }) => {
          return <p>{row.original.status == 1 ? "User" : "Driver"}</p>;
        },
      },
    ],
    []
  );

  return (
    <>
      <aside>
        <article className="p-3">
          <div className="p-2 bg-white rounded-2">
            <Table columns={columns} data={list} className="" />
          </div>
        </article>
      </aside>
    </>
  );
};

export default ReferReport;
