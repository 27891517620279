import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TopHeader from "../../../component/TopHeader";
import Drivers from "./drivers";
import ShareRideList from "./ShareRideList";
import SubscriptionList from "./SubscriptionList";
import { useLazyDriverListNewQuery, useLazyDriverListQuery, useLazyDriverListRequestQuery } from "../../../constants/redux/api/api";

const DriverTab = () => {
 const [inhouseList,setinhouseList]=useState([])
 const [shareRideList,setShareRideList]=useState([])
 const [subscriptionList,setSubscriptionList]=useState([])
 const [list, setList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };



   // RTK QUERY
   const [driversListApi] = useLazyDriverListNewQuery();
   const getList = () => {
    let params 
    if(from&&to){
       params = `?start_date=${from}&end_date=${to}`;
    }else{
      params = ""
    }
    driversListApi(params)
    .unwrap()
    .then(res=>{
      console.log(res)
    }).catch(err=>{
      console.log(err)
    })
  };
useEffect(()=>{
  getList()
},[from,to])


  return (
    <aside>
      <TopHeader type="driver" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" >
            <Drivers />
          </Tab>
          {/* <Tab eventKey="Detail" title="Share Ride Driver">
            <ShareRideList />
          </Tab>
          <Tab eventKey="Details" title="Subscription Driver">
            <SubscriptionList />
          </Tab> */}
        </Tabs>
      </article>
    </aside>
  );
};

export default DriverTab;
