import React, { useState, useEffect } from "react";
import TopHeader from "../../../component/TopHeader";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { maleAvatar } from "../../../assets/img";
import moment from "moment/moment";
import { useLazyScheduleViewQuery } from "../../../constants/redux/api/api";

const data = [
  {
    id: 1,
    userName: "user1",
    driverName: "driver1",
    from: "vadapalani",
    to: "kodampakkam",
    cost: "50rs",
    date: "20/02/2023",
    paymentMethod: "UPI",
    status: "Active",
  },
  {
    id: 2,
    userName: "user2",
    driverName: "driver2",
    from: "vadapalani",
    to: "kodampakkam",
    cost: "50rs",
    date: "20/02/2023",
    paymentMethod: "UPI",
    status: "Active",
  },
  {
    id: 3,
    userName: "user3",
    driverName: "driver3",
    from: "vadapalani",
    to: "kodampakkam",
    cost: "50rs",
    date: "20/02/2023",
    paymentMethod: "UPI",
    status: "Active",
  },
  {
    id: 4,
    userName: "user4",
    driverName: "driver4",
    from: "vadapalani",
    to: "kodampakkam",
    cost: "50rs",
    date: "20/02/2023",
    paymentMethod: "UPI",
    status: "Active",
  },
  {
    id: 5,
    userName: "user5",
    driverName: "driver5",
    from: "vadapalani",
    to: "kodampakkam",
    cost: "50rs",
    date: "20/02/2023",
    paymentMethod: "UPI",
    status: "Active",
  },
];

function ViewScheduleRide() {
  const location = useLocation();
  const detail = location?.state?.data;
  const Id = location?.state?.id;
  // console.log("Id", Id);
  // console.log("type", detail);

  //RTK
  // const [sheduleView] = useLazyScheduleViewQuery();
  const [adminData, setadminData] = useState(data);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(data.status);

  //  Pagenation

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter(
        (val) =>
          val?.userName?.toLowerCase().includes(searcharr.toLowerCase()) ||
          val?.driverName?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const [secheduleView] = useLazyScheduleViewQuery();
  const [view,setView]=useState({})

  const viewSupport = () => {
    secheduleView(Id)
      .unwrap()
      .then((res) => {
        console.log(res?.booking);
        setView(res?.booking)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   if (Id) {
  //     viewSupport();
  //   }
  // }, []);

  return (
    <aside>
      <TopHeader type="view-schedule" />
      <article className="p-3">
       
        <article className="p-3">
          <article>
            <div className="p-3">
              <div className="p-3 bg-white rounded-2">
                <div className="flex-ac-jb">
  
                  <p
                    className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                    for=""
                  ></p>
                  <img
                    className="avatar-img"
                    src={detail?.user?.img_url || maleAvatar}
                  />
                </div>
                <Form className="d-flex flex-wrap">
                  {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Driver Name
                        </label>
                        <input
                          id="example-if-email"
                          name="Driver Name"
                          placeholder="Driver Name"
                          className="bg-transparent"
                          type="text"
                          value={detail?.driver?.name}
                          disabled
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          User Name
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="User Name"
                          className="bg-transparent"
                          type="text"
                          value={detail?.user?.name}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Ride ID
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder=" Ride ID"
                          className="bg-transparent"
                          type="text"
                          value={"#" + detail?.id}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Ride Type
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Ride Type"
                          className="bg-transparent"
                          type="text"
                          value={
                            detail?.ride_type == 1
                              ? "Oneway"
                              : detail?.ride_type == 2
                              ? "Schedule"
                              : detail?.ride_type == 3
                              ? "Point To Points"
                              : detail?.ride_type == 4
                              ? "Round"
                              : ""
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Type
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Ride Type"
                          className="bg-transparent"
                          type="text"
                          value={
                            detail?.type == 1
                              ? "Intercity"
                              : detail?.type == 2
                              ? "Intracity"
                              : detail?.type == 3
                              ? "ShareCab"
                              : ""
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 col-sm-6 col-lg-12 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Ride Status
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Ride Status"
                          className="bg-transparent"
                          type="text"
                          value={detail?.status == 1
                            ? "Driver allocated"
                            : detail?.status == 2
                            ? "Ride cancelled"
                            : detail?.status == 3
                            ? "Driver on the way"
                            : detail?.status == 4
                            ? "Driver reached to pickup"
                            : detail?.status == 5
                            ? "Otp verified and ride started"
                            : detail?.status == 6
                            ? "Reached to drop location"
                            : detail?.status == 7
                            ? "Changed destination request"
                            : detail?.status == 8
                            ? "Change destination accepted"
                            : detail?.status == 9
                            ? "Change destination rejected"
                            : detail?.status == 10
                            ? "Ride completed and driver acknowledged"
                            : detail?.status == 11
                            ? "Change destination reached drop location"
                            : detail?.status == 12
                            ? "Change destination and ride completed"
                            : detail?.status == 13
                            ? "Ride cancelled by driver"
                            : detail?.status == 14
                            ? "Return trip start"
                            : detail?.status == 15
                            ? "Return trip complete"
                            : detail?.status == 16
                            ? "Missedout"
                            : ""}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12  ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          From
                        </label>
                        <p className="address-request textarea-height" disabled>
                          {detail?.pickup_address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12  ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          To
                        </label>

                        <p className="address-request textarea-height" disabled>
                          {detail?.drop_address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Ride Booked Date and Time Stamp
                        </label>
                        <input
                          type="text"
                          name="license-number"
                          id="example-if-email"
                          placeholder="Start Date"
                          className="bg-transparent"
                          value={
                            detail?.ride_started_time
                              ? moment(detail?.created_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : moment(detail?.created_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Ride Start Date and Time Stamp
                        </label>
                        <input
                          type="text"
                          name="license-number"
                          id="example-if-email"
                          placeholder="Start Date"
                          className="bg-transparent"
                          value={
                            detail?.ride_started_time
                              ? moment(detail?.ride_started_time).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : moment(detail?.ride_started_time).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group col-12 col-sm-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Ride End Date and Time stamp
                        </label>
                        <input
                          placeholder="End Date"
                          className="bg-transparent"
                          type="text"
                          //   value={moment(detail?.updated_at).format(
                          //     "DD/MM/YYYY, hh:mm A"
                          //   )
                          // }
                          value={
                            detail?.status == 3
                              ? "Progress"
                              : detail?.status == 4
                              ? "Progress"
                              : detail?.status == 5
                              ? "Progress"
                              : detail?.status == 6
                              ? "Progress"
                              : detail?.status == 7
                              ? "Progress"
                              : detail?.status == 8
                              ? "Progress"
                              : detail?.status == 9
                              ? "Progress"
                              : detail?.status == 10
                              ? detail?.updated_at
                                ? moment(detail?.updated_at).format(
                                    "DD/MM/YYYY, hh:mm A"
                                  )
                                : "-"
                              : detail?.status == 11
                              ? "-"
                              : detail?.status == 12
                              ? detail?.updated_at
                                ? moment(detail?.updated_at).format(
                                    "DD/MM/YYYY, hh:mm A"
                                  )
                                : "-"
                              : detail?.status == 1
                              ? "progress"
                              : detail?.status == 2
                              ? detail?.updated_at
                                ? moment(detail?.updated_at).format(
                                    "DD/MM/YYYY, hh:mm A"
                                  )
                                : "-"
                              : detail?.updated_at
                              ? moment(detail?.updated_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Ride Cost
                        </label>
                        <input
                          type="text"
                          id="example-if-email"
                          placeholder="rc number"
                          className="bg-transparent"
                          value={detail?.final_amount || detail?.amount}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Payment Method
                        </label>
                        <input
                          type="text"
                          id="example-if-email"
                          placeholder="Payment"
                          className="bg-transparent"
                          value={
                            detail?.payment_type == 1 ? "Manual pay" : "UPI"
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group col-12 col-sm-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          User Review
                        </label>
                        {/* <input
                          type="text"
                          name="city"
                          className="bg-transparent"
                          placeholder="city"
                          value={"*****"}
                          disabled
                        /> 
                        {detail?.ride_review?.user_rating == 1 ? (
                          <p style={{ color: "#FBC740" }}>&#9733;</p>
                        ) : detail?.ride_review?.user_rating == 2 ? (
                          <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                        ) : detail?.ride_review?.user_rating == 3 ? (
                          <p style={{ color: "#FBC740" }}>
                            &#9733; &#9733; &#9733;
                          </p>
                        ) : detail?.ride_review?.user_rating == 4 ? (
                          <p style={{ color: "#FBC740" }}>
                            &#9733; &#9733; &#9733; &#9733;
                          </p>
                        ) : detail?.ride_review?.user_rating == 5 ? (
                          <p style={{ color: "#FBC740" }}>
                            &#9733; &#9733; &#9733; &#9733; &#9733;
                          </p>
                        ) : (
                          <p>&#9733; &#9733; &#9733; &#9733; &#9733;</p>
                        )}
                      </div>
                    </div>
                  </div> */}
                </Form>
              </div>
            </div>
          </article>
        </article>
      </article>
    </aside>
  );
}

export default ViewScheduleRide;
