import React, { useEffect, useState } from "react";
import FareSetting from "./fareSetting";
import TopHeader from "../../../component/TopHeader";
import { TbArmchair2 } from "react-icons/tb";
import {
  useEditStoreMutation,
  useLazyVehicleViewQuery,
} from "../../../constants/redux/api/api";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";
import { Nologo } from "../../../assets/img";

const EditVehicleTypes = () => {
  const location = useLocation();
  const data = location?.state?.data;
   const dispatch=useDispatch()
  const [seatPrice, setSeatPrice] = useState([]);
  const [seats, setSeats] = useState("");
  const [name, setname] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [seatsErr, setSeatsErr] = useState(false);
  const [nameErr, setnameErr] = useState(false);
  const [listErr, setListErr] = useState(false);
  const [btn, setBtn] = useState(false);
  

  //RTK
  const [vehicleEditApi] = useEditStoreMutation();
  const [vehicleViewApi] = useLazyVehicleViewQuery();

  //vehicle list
  const getList = () => {
    vehicleViewApi(data?.id)
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        let data = res?.ride;
        setname(data?.name);
        setSeats(data?.no_of_seats);
        let temp = [];
        data?.ride_seat_prices?.map((item) => {
          temp?.push(item);
        });
        setSeatPrice(temp);
        setImage(data?.img_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Edit
  const addtax = () => {
    let empty;
    if (seatPrice?.length > 0) {
      seatPrice?.map((item) => {
        empty = Object.values(item).some(
          (x) => x === null || x === "" || x === " "
        );
      });
    }

    if (
      name?.length == "" ||
      seats?.length == "" ||
      empty == true ||
      image?.length == ""
    ) {
      setSeatsErr(true);
      setnameErr(true);
      setListErr(true);
      setImageErr(true);
    } else {
      let formData = new FormData();

      formData.append("name", name);
      formData.append("_method", "PUT");
      formData.append("no_of_seats", seats);
      if (image?.name) {
        formData.append("image", image);
      }
      seatPrice?.map((item, ind) => {
        // formData.append(`seats[${ind}][id]`,item?.id)
        formData.append(`seats[${ind}][seat_no]`, item?.seat_no);
        formData.append(`seats[${ind}][amount]`, item?.amount);
      });
      setBtn(true);
      dispatch(saveLoader(true))
      vehicleEditApi({ payload: formData, id: data?.id })
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
          }
        //   setAddModal(false);
        //   setname("");
        //   setSeats("");
        getList()
        dispatch(saveLoader(false))
          setBtn(false);
        })
        .catch((err) => {
          console.log(err);
          setBtn(false);
          dispatch(saveLoader(false))
        });
    }
  };
  useEffect(() => {
    if (data?.id) {
      getList();
    }
  }, []);

  const seatHandler = (num) => {
    let temp = [];

    if (num <= 12) {
      let i;
      for (i = 0; i < num; i++) {
        temp.push({ seat_no: "", amount: "" });
      }
      setSeatPrice(temp);
      setSeats(num);
    }
  };

  // console.log(seatPrice,"seatPrice");
  const changeSeat = (e, ind, type) => {
    let temp = [...seatPrice];
    temp[ind] = {
      ...temp[ind],
      [type]: e,
    };
    setSeatPrice(temp);
  };

  return (
    <>
      <div>
        <TopHeader type="edit-vehicle-types" />
      </div>
      <div className="p-2 pt-0 bg-white rounded-2 w-90 m-auto mt-0">
        <div className="p-2 bg-white rounded-2">
          <form className="d-flex flex-wrap">
            <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark">Name</label>
                <input
                  type="text"
                  id="example-if-email"
                  name="Name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Name"
                />
                {name?.length == 0 && nameErr && (
                  <p className="err-p">Enter Name</p>
                )}
              </div>
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark">Seat</label>
                <input
                  type="number"
                  id="example-if-email"
                  name="Seat"
                  value={seats}
                  onChange={(e) => seatHandler(e.target.value)}
                  placeholder="Seat"
                />
                {seats?.length == 0 && seatsErr && (
                  <p className="err-p">Enter Seats</p>
                )}
              </div>
            </div>
            {seatPrice?.map((item, ind) => {
              return (
                <div className="px-1 col-sm-6 modal-label-input" key={ind}>
                  <label className="text-primary-dark" for="">
                    <TbArmchair2 style={{ width: "30px", height: "25px" }} />
                  </label>
                  <div style={{ position: "relative", marginBottom: "10px" }}>
                    <input
                      type="text"
                      id="example-if-email"
                      name="Seat"
                      value={item?.seat_no}
                      onChange={(e) =>
                        changeSeat(e.target.value, ind, "seat_no")
                      }
                      placeholder="Seat"
                    />
                    {item?.seat_no?.length == 0 && listErr && (
                      <p className="err-p">Enter Seat</p>
                    )}
                  </div>
                  <div style={{ position: "relative", marginBottom: "10px" }}>
                    <input
                      type="number"
                      id="example-if-email"
                      name="Price"
                      value={item?.amount}
                      onChange={(e) =>
                        changeSeat(e.target.value, ind, "amount")
                      }
                      placeholder="Price"
                    />
                    {item?.amount?.length == 0 && listErr && (
                      <p className="err-p">Enter Value</p>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Image
              </label>

              <input
                type="file"
                id="example-if-email"
                name="Name"
                onChange={(e) => setImage(e.target.files[0])}
                placeholder="Name"
              />
              {image?.length == 0 && imageErr && (
                <p className="err-p">Enter Image</p>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <img
                src={image?.name ? URL.createObjectURL(image) : image?image: Nologo }
                style={{ width: "30%", marginBottom: "20px" }}
              />
            </div>
            <div className="mt-auto ms-auto">
              <button
                type="button"
                className={`comn-btn bg-success px-3 py-2 me-2 ${btn && "opacity-50"}`}
                data-dismiss="modal"
                onClick={addtax}
                disabled={btn ? true : false}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-90 m-auto mb-4">
        <FareSetting data={data}/>
      </div>
    </>
  );
};

export default EditVehicleTypes;
