import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import {
  useCouponsAddMutation,
  useCouponsUpdateMutation,
  useLazyActiveUserListQuery,
  useLazyCouponsViewQuery,
  useLazyRewardsViewQuery,
  useRewardsAddMutation,
  useRewardsUpdateMutation,
} from "../../../constants/redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";
// import state from "sweetalert/typings/modules/state";

const AddCoupon = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;
  const dispatch = useDispatch()

  const [input, setInput] = useState([]);
  const [types, setTypes] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [percentage, setPercentage] = useState("");
  const [offerTitle,setOfferTitle]=useState("")
  const [offerTitleErr,setOfferTitleErr]=useState(false)
  const [percentageErr, setPercentageErr] = useState(false);
  const [validity, setValidity] = useState("");
  const [time,setTime]=useState("")
  const [timeErr,setTimeErr]=useState(false)
  const [user, setUser] = useState("");
  const [userErr, setUserErr] = useState(false);
  const [typesErr, setTypesErr] = useState(false);
  const [maxPriceErr, setMaxPriceErr] = useState(false);
  const [userList, setUserList] = useState([]);
  const [btn, setBtn] = useState(false);
  const [Title,setTitle]=useState("");
  const [TitleErr,setTitleErr]=useState(false);

  const [nameErr, setNameErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [validityErr, setValidityErr] = useState(false);

  //RTK

  const [userListApi] = useLazyActiveUserListQuery();
  const [couponAdd] = useCouponsAddMutation();
  const [couponView] = useLazyCouponsViewQuery();
  const [couponEdit] = useCouponsUpdateMutation();

  //RTK REWARD
  const[rewardsAdd]=useRewardsAddMutation()
  const[rewardsupdate]=useRewardsUpdateMutation()
  const[rewardsview]=useLazyRewardsViewQuery()
  
  const apiUser = () => {
    userListApi()
      .unwrap()
      .then((res) => {
        console.log(res);
        setUserList(res?.active_users);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    apiUser();
  }, []);

  const addZone = () => {
    if (
      Title?.length == 0 ||
      name?.length == 0 ||
      price?.length == 0 ||
      validity?.length == 0 ||
      time?.length == 0 ||
      types?.length == 0 ||
      percentage?.length == 0 ||
      maxPrice?.length == 0 ||
      offerTitle?.length == 0
    ) {
      setTitleErr(true);
      setNameErr(true);
      setPriceErr(true);
      setValidityErr(true);
      setTimeErr(true)
      setPercentageErr(true);
      setTypesErr(true);
      setMaxPriceErr(true);
      setOfferTitleErr(true)
    } else {
      if (Id) {
        let formData = new FormData();
        formData.append("offer_title",offerTitle)
        formData.append("title",Title)
        formData.append("code", name);
        formData.append("value", price);
        formData.append("max_amount", maxPrice);
        const date =[validity,time]
        formData.append("expires_at", date.toString());
        formData.append("type", types);
        {
          types == "2" && formData.append("user_id", user);
        }
        formData.append("payment_type", percentage);

        setBtn(true);
        dispatch(saveLoader(true))
        couponEdit({ payload: formData, id: Id })
          .unwrap()
          .then((res) => {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            console.log(res);
            setBtn(false);
            dispatch(saveLoader(false))
            navigate("/coupon-list",{state:{tab:"pending"}});
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false))
            console.log(err);
          });
      } else if (!Id) {
        let formData = new FormData();
        formData.append("offer_title",offerTitle) 
        formData.append("title",Title)
        formData.append("code", name);
        formData.append("value", price);
        formData.append("max_amount", maxPrice);
        formData.append("expires_at", validity);
        formData.append("type", types);
        {
          types == "2" && formData.append("user_id", user);
        }
        formData.append("payment_type", percentage);
        setBtn(true);
        dispatch(saveLoader(true))
        couponAdd(formData)
          .unwrap()
          .then((res) => {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            console.log(res);
            setBtn(false);
            dispatch(saveLoader(false))
            navigate("/coupon-list",{state:{tab:"pending"}});
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false))
            console.log(err);
          });
      }
    }
  };
  const addreward = () => {
    if (
      // Title?.length == 0 ||
      name?.length == 0 ||
      price?.length == 0 ||
      validity?.length == 0 ||
      percentage?.length == 0 ||
      maxPrice?.length == 0
    ) {
      // setTitleErr(true);
      setNameErr(true);
      setPriceErr(true);
      setValidityErr(true);
      setPercentageErr(true);
      setMaxPriceErr(true);
    } else {
      if (type=="rewardEdit") {
        let formData = new FormData();

        formData.append("_method", "PUT");
        // formData.append("title",Title);
        formData.append("referral_count", name);
        formData.append("value", price);
        formData.append("max_amount", maxPrice);
        formData.append("no_of_days", validity);
        formData.append("payment_type", percentage);

        setBtn(true);
        dispatch(saveLoader(true))
        rewardsupdate({ payload: formData, id: Id })
          .unwrap()
          .then((res) => {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            console.log(res);
            setBtn(false);
            dispatch(saveLoader(false))
            navigate("/coupon-list",{state:{tab:"resolved"}});
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false))
            console.log(err);
            navigate("/coupon-list",{state:{tab:"resolved"}});
          });
      } else if (!Id) {
        let formData = new FormData();
        // formData.append("title",Title)
        formData.append("referral_count", name);
        formData.append("value", price);
        formData.append("max_amount", maxPrice);
        formData.append("no_of_days", validity);
        formData.append("payment_type", percentage);
        setBtn(true);
        dispatch(saveLoader(true))
        rewardsAdd(formData)
          .unwrap()
          .then((res) => {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            console.log(res);
            setBtn(false);
            dispatch(saveLoader(false))
            navigate("/coupon-list",{state:{tab:"resolved"}});
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false))
            console.log(err);
          });
      }
    }
  };
  //View
  const getView = () => {
    couponView(Id)
      .unwrap()
      .then((res) => {
        console.log(res?.coupon);
        const data = res?.coupon;
        setOfferTitle(data?.offer_title)
        setTitle(data?.title)
        setName(data?.code);
        setPrice(data?.value);
        setMaxPrice(data?.max_amount);
        const date = data?.expires_at?.split(" ")
        setValidity(date[0]);
        setTime(date[1])
        setPercentage(data?.payment_type);
        setTypes(data?.type);
        setUser(data?.user_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRewardsView = () => {
    rewardsview(Id)
      .unwrap()
      .then((res) => {
        console.log(res?.reward);
        const data = res?.reward;
        setName(data?.referral_count);
        setPrice(data?.value);
        setMaxPrice(data?.max_amount);
        setValidity(data?.no_of_days);
        setPercentage(data?.payment_type);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if ((type=="edit" || type=="view") && Id) {
      getView();
    }
  }, []);
  useEffect(() => {
    if ((type=="rewardsView" || type=="rewardEdit") && Id) {
      getRewardsView();
    }
  }, []);
   console.log(type);
  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="editCoupen" />
      ) : (
        <TopHeader type="addCoupen" />
      )}
      <article className="p-3">
        {type != "rewardsAdd" && type!="rewardsView" && type!="rewardEdit" && (
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Coupon Name
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="tittle"
                        value={Title}
                        disabled={type == "view" ? true : false}
                      />
                      {Title?.length == 0 && TitleErr && (
                        <p className="err-p">enter tittle</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Offer Title
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => setOfferTitle(e.target.value)}
                        placeholder="tittle"
                        value={offerTitle}
                        disabled={type == "view" ? true : false}
                      />
                      {offerTitle?.length == 0 && offerTitleErr && (
                        <p className="err-p">enter offer tittle</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Code
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="name"
                        value={name}
                        disabled={type == "view" ? true : false}
                      />
                      {name?.length == 0 && nameErr && (
                        <p className="err-p">enter Code</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        value
                      </label>
                      <input
                        type="Number"
                        id="example-if-email"
                        name="username"
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="value"
                        value={price}
                        disabled={type == "view" ? true : false}
                      />
                      {price?.length == 0 && priceErr && (
                        <p className="err-p">enter price</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Max_Amount
                      </label>
                      <input
                        type="Number"
                        id="example-if-email"
                        name="username"
                        onChange={(e) => setMaxPrice(e.target.value)}
                        placeholder="Max_value"
                        value={maxPrice}
                        disabled={type == "view" ? true : false}
                      />
                      {maxPrice?.length == 0 && maxPriceErr && (
                        <p className="err-p">enter MaxPriceErr</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Validity
                      </label>
                      <input
                        type="date"
                        id="example-if-email"
                        name="number"
                        onChange={(e) => setValidity(e.target.value)}
                        placeholder="validity"
                        value={validity}
                        disabled={type == "view" ? true : false}
                      />
                      {validity?.length == 0 && validityErr && (
                        <p className="err-p">enter validity</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Validity Time
                      </label>
                      <input
                        type="time"
                        id="example-if-email"
                        name="number"
                        onChange={(e) => setTime(e.target.value)}
                        placeholder="validity"
                        value={time}
                        disabled={type == "view" ? true : false}
                      />
                      {time?.length == 0 && timeErr && (
                        <p className="err-p">enter validity Time</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-1 col-sm-6 modal-label-input">
                  <label className="text-primary-dark" for="">
                    payment Type
                  </label>
                  <select
                    name="type"
                    onChange={(e) => setPercentage(e.target.value)}
                    value={percentage}
                    disabled={type == "view" ? true : false}
                  >
                    <option selected>select Payment</option>
                    <option value={1}>Amount</option>
                    <option value={2}>Percentage</option>
                  </select>
                  {percentage?.length == "" && percentageErr && (
                    <p className="err-p">Enter Payment Type</p>
                  )}
                </div>
                <div className="px-1 col-sm-6 modal-label-input">
                  <label className="text-primary-dark" for="">
                    Type
                  </label>
                  <select
                    name="type"
                    onChange={(e) => setTypes(e.target.value)}
                    value={types}
                    disabled={type == "view" ? true : false}
                  >
                    <option selected>select type</option>
                    <option value={1}>Normal Coupon</option>
                    <option value={2}>Reward Coupon</option>
                  </select>
                  {types?.length == "" && typesErr && (
                    <p className="err-p">Enter Types </p>
                  )}
                </div>
                {types == "2" && (
                  <div className="px-1 col-sm-6 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Send to User
                    </label>
                    <select
                      name="type"
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      disabled={type == "view" ? true : false}
                    >
                      <option selected>select type</option>
                      {userList?.map((item) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                    </select>
                    {user?.length == "" && userErr && (
                      <p className="err-p">Enter User </p>
                    )}
                  </div>
                )}
                {type != "view" && (
                  <div className="mt-auto ms-auto">
                    <button
                      type="button"
                      className={`comn-btn bg-success-gradient px-3 py-2 me-2 mt-4 ${
                        btn && "opacity-50"
                      }`}
                      onClick={addZone}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        )}
        {(type == "rewardsAdd"|| type == "rewardsView" || type == "rewardEdit" )&& (
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <Form className="d-flex flex-wrap">
              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Rewards Name
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="tittle"
                        value={Title}
                        disabled={type == "view" ? true : false}
                      />
                      {Title?.length == 0 && TitleErr && (
                        <p className="err-p">enter tittle</p>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                         Refferal_Code
                      </label>
                      <input
                        type="Number"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="tittle"
                        value={name}
                        disabled={type == "rewardsView" ? true : false}
                      />
                      {name?.length == 0 && nameErr && (
                        <p className="err-p">enter tittle</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        value
                      </label>
                      <input
                        type="Number"
                        id="example-if-email"
                        name="username"
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="value"
                        value={price}
                        disabled={type == "rewardsView" ? true : false}
                      />
                      {price?.length == 0 && priceErr && (
                        <p className="err-p">enter price</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Max_Amount
                      </label>
                      <input
                        type="Number"
                        id="example-if-email"
                        name="username"
                        onChange={(e) => setMaxPrice(e.target.value)}
                        placeholder="Max_value"
                        value={maxPrice}
                        disabled={type == "rewardsView" ? true : false}
                      />
                      {maxPrice?.length == 0 && maxPriceErr && (
                        <p className="err-p">enter MaxPriceErr</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Validity
                      </label>
                      <input
                        type="number"
                        id="example-if-email"
                        name="number"
                        onChange={(e) => setValidity(e.target.value)}
                        placeholder="validity"
                        value={validity}
                        disabled={type == "rewardsView" ? true : false}
                      />
                      {validity?.length == 0 && validityErr && (
                        <p className="err-p">enter validity</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-1 col-sm-6 modal-label-input">
                  <label className="text-primary-dark" for="">
                    payment Type
                  </label>
                  <select
                    name="type"
                    onChange={(e) => setPercentage(e.target.value)}
                    value={percentage}
                    disabled={type == "rewardsView" ? true : false}
                  >
                    <option selected>select Payment</option>
                    <option value={1}>Amount</option>
                    <option value={2}>Percentage</option>
                  </select>
                  {percentage?.length == "" && percentageErr && (
                    <p className="err-p">Enter Payment Type</p>
                  )}
                </div>

                {type != "rewardsView" && (
                  <div className="mt-auto ms-auto">
                    <button
                      type="button"
                      className={`comn-btn bg-success-gradient px-3 py-2 me-2 mt-4 ${
                        btn && "opacity-50"
                      }`}
                      onClick={addreward}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        )}
      </article>
    </aside>
  );
};

export default AddCoupon;
