import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  adminIcon,
  AppointmentIcon,
  BannerIcon,
  categoryIcon,
  dashbaordIcon,
  doctorIcon,
  FaqIcon,
  HelpIcon,
  hospitalIcon,
  Logout,
  NotifIcon,
  patientIcon,
  PrivacyIcon,
  RequestIcon,
  roleIcon,
  TermsCondiIcon,
} from "../assets/img";
import { ToastContainer, toast } from "react-toastify";
import { RxDashboard } from "react-icons/rx";
import { GiNotebook } from "react-icons/gi";
import {
  MdOutlineSubscriptions,
  MdOutlinePayment,
  MdOutlineSettings,
  MdOutlineNotificationAdd,
  MdOutlineStarRate,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { CgList } from "react-icons/cg";
import { VscReferences } from "react-icons/vsc";
import {
  RiAdminLine,
  RiUserSettingsLine,
  RiMoneyCnyCircleLine,
  RiLogoutCircleRLine,
} from "react-icons/ri";
import {
  TbUserPlus,
  TbUserCheck,
  TbUsers,
  TbFileTime,
  TbReport,
} from "react-icons/tb";
import { useLazyLogoutQuery } from "../constants/redux/api/api";

const Navbar2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  console.log("loca", pathname);
  const splitLocation = pathname.split("/company/");

  const permissons = JSON.parse(localStorage.getItem("role"));

  const [logoutApi] = useLazyLogoutQuery();

  // LOGOUT

  const Log = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.reload();
        // logoutApi()
        //   .unwrap()
        //   .then((res) => {
        //     if (res?.status == "success") {
        //       localStorage.clear();
        //       window.location.reload();
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("err", err);
        //   });
      }
    });
  };

  return (
    <nav>
      <ul>
        <li>
      <Link
            type=""
            className={
              splitLocation[1] === "company" ? "menu-list active" : "menu-list"
            }
            to="/company"
          >
            {/* <img className="menu-icon" src={dashbaordIcon} /> */}
            <RxDashboard />
            Dashboard
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "driver" ? "menu-list active" : "menu-list"
            }
            to="/company/driver"
          >
            {/* <img className="menu-icon" src={adminIcon} /> */}
            <TbUserCheck />
            Driver
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "booking" ? "menu-list active" : "menu-list"
            }
            to="/company/booking"
          >
            {/* <img className="menu-icon" src={AppointmentIcon} /> */}
            <GiNotebook />
            Booking History
          </Link>
        </li>
        {/* <li>
          <Link
            className={
              splitLocation[1] === "subscription"
                ? "menu-list active"
                : splitLocation[1] === "subscription-add"
                ? "menu-list active"
                : "menu-list"
            }
            to="/company/subscription"
          >
            {/* <img className="menu-icon" src={NotifIcon} /> */}
          
        <li>
          <Link
            className={
              splitLocation[1] === "revenue-list"
                ? "menu-list active"
                : "menu-list"
            }
            to="/company/revenue-list"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> */}
            <VscReferences />
            Revenue
          </Link>
        </li>
      
        <li>
          <Link
            className={
              splitLocation[1] === "cancelled"
                ? "menu-list active"
                : "menu-list"
            }
            to="/company/cancelled"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> */}
            <CgList />
            Cancel Order
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "offline-booking"
                ? "menu-list active"
                : "menu-list"
            }
            to="/company/offline-booking"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> */}
            <GiNotebook />
            Offline Booking
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "schedule-ride"
                ? "menu-list active"
                : "menu-list"
            }
            to="/company/schedule-ride"
          >
            {/* <img className="menu-icon" src={RequestIcon} /> */}
            <AiOutlineSchedule />
            Schedule ride
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "share-cab"
                ? "menu-list active"
                : "menu-list"
            }
            to="/company/share-cab"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> */}
            <GiNotebook />
            Share Cab
          </Link>
        </li>
        <li>
          <Link
            className="menu-list"
            onClick={() => {
              Log();
            }}
          >
            {/* <img className="menu-icon" src={Logout} /> */}
            <RiLogoutCircleRLine />
            Logout
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar2;
