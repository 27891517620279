import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useCompanyUserListMutation, useLazyCompanyUserDeleteQuery, useLazyCompanyUserStatusQuery } from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
              <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
                <i className="feather icon-plus" /> Add Ticket
              </Button>
            </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const Company = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);

  const [companyListApi]=useCompanyUserListMutation();
  const [deleteCompanyApi] = useLazyCompanyUserDeleteQuery();
  const [statusComapnyApi] = useLazyCompanyUserStatusQuery();
  const getList = () => {
    companyListApi()
      .unwrap()
      .then((res) => {
        // console.log(res, "res");
        setList(res?.lists);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(()=>{
    getList()
  },[])

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "User Name",
        accessor: "username",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Company Name",
        accessor: "company.name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => getStatus(row.original.id)}
              disabled={btn ? true : false}
              className={
                value == 1
                  ? "p-2 py-1 comn-btn active-deact-btn bg-success"
                  : "p-2 py-1 comn-btn active-deact-btn bg-danger"
              }
            >
              {value == 1 ? "Active" : "Deactive"}
            </button>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex justify-content-center gap-2">
                {/* {permissons?.includes("CAN-VIEW-ADMINS") ? ( */}
                <button
                  onClick={() =>
                    navigate("/company-nav/add-company", {
                      state: { id: row.original.id, type: "edit" },
                    })
                  }
                  className="comn-btn bg-primary edit-btn-svg"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                {/* ) : null} */}
                {/* {permissons?.includes("CAN-EDIT-ADMINS") ? ( */}
                <button
                  onClick={() => deleteFares(row.original.id)}
                  className="comn-btn bg-danger edit-btn-svg"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                {/* ) : null} */}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const getStatus = (id) => {
    setBtn(true);
    statusComapnyApi(id)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.status == "success") {
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
        }
        getList()
        setBtn(false);
      })
      .catch((err) => {
        console.log(err);
        setBtn(false);
      });
  };
  const deleteFares = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        deleteCompanyApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };


  return (
    <aside>
      <TopHeader type="company" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          {/* <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end"> */}
            {/* <div className="flex-ac-jc"> */}
              {/* {permissons?.includes("CAN-ADD-ADMINS") ? ( */}
              <button
                onClick={() => navigate("/company-nav/add-company")}
                className="comn-btn bg-primary1 px-3 py-2 ad-rol-a" 
                style={{
                  width: "150px",
                  margin: "auto 0 10px auto",
                  display: "block",
                }}
              >
                Add Company
              </button>
              {/* ) : null} */}
            {/* </div> */}
          {/* </div> */}
          <Table columns={columns} data={list} />
        </div>
      </article>
    </aside>
  );
};

export default Company;
