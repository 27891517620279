import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../component/Header";
import Header2 from "../component/Header2";

const Layout2 = () => {
  return (
    <div className="d-flex flex-wrap">
      <Header2/>
      <div className="out-left col ">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout2 ;
