import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import {
  useLazySubscriptionViewQuery,
  useSubscriptionAddMutation,
  useSubscriptionEditMutation,
} from "../../../constants/redux/api/api";

const AddSubscription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const [input, setInput] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [validity, setValidity] = useState("");
  const [btn, setBtn] = useState(false);

  const [nameErr, setNameErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [validityErr, setValidityErr] = useState(false);

  const [subscriptionAdd] = useSubscriptionAddMutation();
  const [subscriptionView] = useLazySubscriptionViewQuery();
  const [subscriptionEdit] = useSubscriptionEditMutation();

  const addZone = () => {
    if (name?.length == 0 || price?.length == 0 || validity?.length == 0) {
      setNameErr(true);
      setPriceErr(true);
      setValidityErr(true);
    } else {
      if (Id) {
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("name", name);
        formData.append("amount", price);
        formData.append("validity", validity);
        // setBtn(true);
        subscriptionEdit({ id: Id, payload: formData })
          .unwrap()
          .then((resp) => {
            console.log(resp);
            toast.success(resp?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (!Id) {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("amount", price);
        formData.append("validity", validity);
        console.log(formData);
        subscriptionAdd(formData)
          .unwrap()
          .then((resp) => {
            console.log(resp);
            toast.success(resp?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
          });
        // setBtn(true);
      }
    }
  };

  const view = () => {
    subscriptionView(Id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          console.log("res", res);
          setName(res?.subscription?.name);
          setPrice(res?.subscription?.amount);
          setValidity(res?.subscription?.validity);
        }
        // console.log(admin);
      });
  };

  useEffect(() => {
    if (Id) {
      view();
    }
  }, []);

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="edit-subscription" />
      ) : (
        <TopHeader type="add-subscription" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Tittle
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="tittle"
                      value={name}
                      disabled={type=="view"?true:false}
                    />
                    {name?.length == 0 && nameErr && (
                      <p className="err-p">enter tittle</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Price
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="username"
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="price"
                      value={price}
                      disabled={type=="view"?true:false}
                    />
                    {price?.length == 0 && priceErr && (
                      <p className="err-p">enter price</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Validity
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="number"
                      onChange={(e) => setValidity(e.target.value)}
                      placeholder="validity"
                      value={validity}
                      disabled={type=="view"?true:false}
                    />
                    {validity?.length == 0 && validityErr && (
                      <p className="err-p">enter validity</p>
                    )}
                  </div>
                </div>
              </div>
              {
                type != "view" && 
              <div className="mt-auto ms-auto">
                <button
                  type="button"
                  className="comn-btn bg-success-gradient px-3 py-2 me-2"
                  onClick={() => addZone()}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
              }
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default AddSubscription;
