import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import {
  useCompanyListSettingMutation,
  useCompanyUserAddMutation,
  useCompanyUserEditMutation,
  useLazyCompanyUserViewQuery,
} from "../../../constants/redux/api/api";

const AddCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);
  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [role, setRole] = useState("");
  const [pass, setPass] = useState("");
  const [cPass, setCPass] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [userErr, setUserErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [numberErr, setNumberErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [cPassErr, setCPassErr] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCPwd, setIsRevealCPwd] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [cPasswordClass, setCPasswordClass] = useState("form-control");
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  //RTK
  const [zoneListApi] = useCompanyListSettingMutation();
  const [companyUserAddApi] = useCompanyUserAddMutation();
  const [companyUserEditApi] = useCompanyUserEditMutation();
  const [companyViewApi] = useLazyCompanyUserViewQuery();

  const viewCompany = () => {
    companyViewApi(Id)
      .unwrap()
      .then((res) => {
        // console.log(res);
        const data = res?.company_user;
        setEmail(data?.email);
        setRole(data?.company?.id);
        setUser(data?.username);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (Id) {
      viewCompany();
    }
  }, []);
  //vehicle list
  const getList = () => {
    zoneListApi()
      .unwrap()
      .then((res) => {
        setList(res?.lists);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, []);

  const admin = () => {
    if (
      role?.length == "" ||
      email?.length == "" ||
      user?.length == ""  ||
     (type!="edit"&&(pass?.length < 8 ||
        cPass?.length < 8))
    ) {
      setRoleErr(true);
      setUserErr(true);
      setEmailErr(true);
      setPassErr(true);
      setCPassErr(true);
    } else {
      const formData = new FormData();
      formData.append("company_id", role);
      formData.append("username", user);
      formData.append("password", pass);
      formData.append("email", email);
      if (type == "edit") {
        formData.append("company_user_id", Id);
        setBtn(true);
        companyUserEditApi({ payload: formData, id: Id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/company-nav");
            setBtn(false);
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      } else {
        setBtn(true);
        companyUserAddApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/company-nav");
            setBtn(false);
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      }
    }
  };
  useEffect(() => {
    if (isCPasswordDirty) {
      if (pass === cPass) {
        if (pass == "" && cPass == "") {
          setCPasswordClass("form-control");
          setShowErrorMessage(false);
        } else {
          setShowErrorMessage(false);
          setCPasswordClass("form-control is-valid");
        }
      } else if (pass !== cPass || pass.length < 6 || cPass.length < 6) {
        if (pass == "" && cPass == "") {
          setCPasswordClass("form-control");
          setShowErrorMessage(false);
        } else {
          setShowErrorMessage(true);
          setCPasswordClass("form-control is-invalid");
        }
      }
    }
  }, [cPass, pass]);

  const handleCPassword = (e) => {
    setCPass(e.target.value);
    setIsCPasswordDirty(true);
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="edit-company" />
      ) : (
        <TopHeader type="add-company" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Company list
                    </label>
                    <select
                      name="role"
                      id=""
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                    >
                      <option>Select Type</option>
                      {list?.map((i) => (
                        <option key={i?.id} value={i?.id}>
                          {i?.name}
                        </option>
                      ))}
                    </select>
                    {role?.length == 0 && roleErr && (
                      <p className="err-p">Select Role</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Username
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="username"
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      placeholder="Username"
                    />
                    {user?.length == 0 && userErr && (
                      <p className="err-p">Enter Username</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="Email"
                    />
                    {email?.length == 0 && emailErr && (
                      <p className="err-p">Enter Email</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Password
                    </label>
                    <div className="pwd-container">
                      <input
                        type={isRevealPwd ? "text" : "password"}
                        id="example-if-email"
                        name="password"
                        onChange={(e) => setPass(e.target.value)}
                        value={pass}
                        placeholder="Password"
                        autocomplete="off"
                      />
                      <img
                        src={isRevealPwd ? showPwdImg : hidePwdImg}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                    {pass?.length < 8 && passErr && (
                      <p className="err-p">Enter Password</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Confirm Password
                    </label>
                    <div className="pwd-container2">
                      <input
                        type={isRevealCPwd ? "text" : "password"}
                        id="example-if-email"
                        name="password_confirmation                "
                        onChange={(e) => {
                          setCPass(e.target.value);
                          handleCPassword(e);
                        }}
                        value={cPass}
                        className={cPasswordClass}
                        placeholder="Confirm Password"
                        autocomplete="off"
                      />
                      <img
                        src={isRevealCPwd ? showPwdImg : hidePwdImg}
                        onClick={() =>
                          setIsRevealCPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                    {cPass?.length < 8 && cPassErr && (
                      <p className="err-p">Enter Confirm Password</p>
                    )}
                  </div>
                </div>
              </div>{" "}
              <div className="mt-auto ms-auto">
                <button
                  type="button"
                  className="comn-btn bg-success-gradient px-3 py-2 me-2"
                  onClick={admin}
                  disabled={btn ? true : false}
                >
                  {type == "edit" ? "Edit" : "submit"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default AddCompany;
