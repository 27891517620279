import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import PresetedRideList from "./PresetedRideList";
import DriverRideList from "./DriverRideList";
import UserRequestList from "./UserRequestList";

function ShareCab() {
  const location = useLocation();
   const [active,setActive]=useState("Detail-View");
   useEffect(()=>{
    if(location?.state?.tab){
    setActive(location?.state?.tab)
  }
  },[])
  return (
    <aside>
      <TopHeader type="share-cab" />
      <article>
        <div className="p-3">
          <Tabs
            activeKey={active}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(e)=>setActive(e)}
          >
            <Tab eventKey="Detail-View" title="Preseted Ride List">
              <PresetedRideList />
            </Tab>
            <Tab eventKey="Detail" title="Driver Ride List">
              <DriverRideList />
            </Tab>
            <Tab eventKey="View" title="User Request List">
              <UserRequestList />
            </Tab>
          </Tabs>
        </div>
      </article>
    </aside>
  );
}

export default ShareCab;
