import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useDeleteChargesMutation,
  useLazyOtherChargeListQuery,
  useOtherChargeDeleteMutation,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}

const OtherCharge = ({chargesList,getList}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.data;
  const type = location?.state?.type;
  console.log(location,"loc");
  const permissons = JSON.parse(localStorage.getItem("role"));

  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [otherChargeListApi] = useLazyOtherChargeListQuery();
  const [otherChargeDeleteApi] = useDeleteChargesMutation();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "KM",
        accessor: "min_km_time",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: "Type",
        accessor: "charge_for",
        Cell: ({ value, row }) => {
          return <span>{value==1?"Intercity":value==2?"Intracity":"sharecab"}</span>;
        },
      },

      {
        Header: "Price",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {permissons?.includes("CAN-EDIT-FARE PRICE") ? (
                <div className="flex-ac-tr">
                  <button
                    className="comn-btn bg-primary edit-btn-svg"
                    onClick={() =>
                      navigate("/setting/add-fare-setting", {
                        state: {
                          id: row.original.id,
                          type: "editDistanceFare",
                          ride_id: Id,
                        },
                      })
                    }
                    disabled={type == "view" ? true : false}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  {permissons?.includes("CAN-DELETE-FARE PRICE") ? (
                    <button
                      className="comn-btn bg-primary edit-btn-svg"
                      onClick={() => deleteOther(row.original.id)}
                      disabled={btn ? true : type == "view" ? true : false}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  ) : null}
                </div>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );



  const deleteOther = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        const formData=new FormData()
        formData.append("other_charge_id",id)
        otherChargeDeleteApi({payload:formData,id:id})
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };

  // useEffect(() => {
  //   getList();
  // }, []);

  // console.log("type", type);

  return (
    <aside>
      <article className="">
        <div className="p-2 bg-white rounded-2">
          {/* <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end"> */}
            {chargesList?.length <3 &&<div className="flex-ac-jc">
              {/* {permissons?.includes("CAN-ADD-FARE PRICE") && type !== "view" ? ( */}
              <button
                onClick={() =>
                  navigate("/setting/add-fare-setting", {
                    state: {
                      type: "addDistanceFare",
                      ride_id: Id,
                      // data:data
                    },
                  })
                }
                className="comn-btn bg-primary1 px-3 py-2" 
                style={{
                  width: "150px",
                  margin: "auto 0 10px auto",
                  display: "block",
                }}
              > 
                Add Base Fare
              </button>
              {/* ) : null} */}
            </div>}
          {/* </div> */}
          <Table columns={columns} data={chargesList} />
        </div>
      </article>
    </aside>
  );
};

export default OtherCharge;
