import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import {
  faBell,
  faCalendar,
  faHospital,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBed,
  faCoins,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Col, Card, Row } from "react-bootstrap";
import TopHeader from "../../../component/TopHeader";
import moment from "moment/moment";
import { MdElectricRickshaw, MdOutlineElectricRickshaw } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi";
import { VscLayersActive } from "react-icons/vsc";
import { GrCompliance } from "react-icons/gr";
import { DivIcon } from "leaflet";
import { useSelector } from "react-redux";
import {
  useLazyDashBoardOverallListQuery,
  useLazyDashBoardPayTaxListQuery,
  useLazyDashBoardPieListQuery,
  useLazyDashBoardRevenueListQuery,
  useLazyDashBoardSubScriptionListQuery,
} from "../../../constants/redux/api/api";

const Dashboard = () => {
  const [list, setList] = useState([]);
  const selector = useSelector((state) => state.saveTokens);
  const [Count, setCount] = useState({});

  //RTK
  const [overAllList] = useLazyDashBoardOverallListQuery();
  const [pieChartList] = useLazyDashBoardPieListQuery();
  const [revenueApi] = useLazyDashBoardRevenueListQuery();
  const [payApi] = useLazyDashBoardPayTaxListQuery();
  const [subcriptionApi]=useLazyDashBoardSubScriptionListQuery();
  const getList = () => {
    overAllList()
      .unwrap()
      .then((res) => {
        // console.log(res);
        setCount(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // PIE CHART

  const [supports, setSupports] = useState([]);
  const [ride, setRide] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [pie, setPie] = useState([]);

  const getPieChart = () => {
    pieChartList()
      .unwrap()
      .then((res) => {
        let help_support = [];
        // help_support.push(res?.total_support);
        help_support.push(res?.open_support_percentage + res?.close_support_percentage)
        help_support.push(res?.open_support_percentage);
        help_support.push(res?.close_support_percentage);
        let cancell = [];
        cancell.push(res?.cancelled_ride_percentage);
        cancell.push(res?.missed_ride_percentage);
        cancell.push(res?.rejected_ride_percentage);
        let Subscription = [];
        Subscription.push(100-res?.active_subs_percentage)
        Subscription.push(res?.active_subs_percentage);
        // Subscription.push(res?.total_subscriptions);
        setPie(res);
        setSupports(help_support);
        setRide(cancell);
        setSubscriptions(Subscription);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // GRAPH
  // REVENU
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [revenue, setRevenu] = useState([]);
  const [revenueDate, setRevenuDate] = useState([]);

  const fromDate = (value) => {
    setStart(value);
    setEnd("");
  };

  const toDate = (value) => {
    setEnd(value);
  };

  const revenueGraph = () => {
    let params;
    if (start && end) {
      params = `?start_date=${start}&end_date=${end}`;
    } else {
      params = "";
    }
    revenueApi(params)
      .unwrap()
      .then((res) => {
        console.log(res);
        const points = res?.revenue.map((item) => item)
        const dates = res?.date.map((item) => item)
        setRevenu(points)
        setRevenuDate(dates)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // PAY TAX
  const [started, setStarted] = useState();
  const [ended, setEnded] = useState();
  const [payTax, setPayTax] = useState([]);
  const [payTaxDate, setPayTaxDate] = useState([]);
  const [startedList, setStartedList] = useState("");
  const [endedList, setEndedList] = useState("");
  const [subscriptiondate,setSubscriptiondate]=useState([])
  const [subscriptionItem,setSubscriptionItem]=useState([])
  

  const fromDates = (value) => {
    setStarted(value.target.value);
    setEnded("");
  };

  const toDates = (value) => {
    setEnded(value.target.value);
  };


  const fromDatesub = (value) => {
    setStartedList(value.target.value)
  };

  const toDatesSub = (value) => {
    setEndedList(value.target.value);
  };
  const Subscription = () => {
    let params;

    if (startedList && endedList) {
      params = `?start_date=${startedList}&end_date=${endedList}`;
    } else {
      params = "";
    }
    subcriptionApi(params)
      .unwrap()
      .then((res) => {
        console.log(res);
        const points = res?.revenue_amt.map((item) => item)
        const dates = res?.date.map((item) => item)
        setSubscriptionItem(points)
        setSubscriptiondate(dates)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const payTaxGraph = () => {
    let params;

    if (start && end) {
      params = `?start_date=${started}&end_date=${ended}`;
    } else {
      params = "";
    }
    payApi(params)
      .unwrap()
      .then((res) => {
        console.log(res);
        const points = res?.tax_amt.map((item) => item)
        const dates = res?.date.map((item) => item)
        setPayTax(points)
        setPayTaxDate(dates)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
    getPieChart();
  }, []);

  useEffect(() => {
    revenueGraph();
  }, [start, end]);

  useEffect(() => {
    payTaxGraph();
  }, [started, ended]);
  useEffect(() => {
    Subscription();
  }, [startedList, endedList]);
  // LINE CHART

  const PayTax = {
    height: 300,
    type: "line",
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#86ca17"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#5ca811"],
          shadeIntensity: 0.5,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        width: 3,
        // curve: "smooth",
      },
      xaxis: {
        categories:payTaxDate,
      },
      markers: {
        size: 5,
        colors: ["#4caf50"],
        opacity: 0.9,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
    },
    series: [
      {
        name: "PayTax",
        data: payTax,
      },
    ],
  };

  const Revenue = {
    height: 300,
    type: "line",
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#86ca17"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#5ca811"],
          shadeIntensity: 0.5,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        width: 3,
        // curve: "smooth",
      },
      xaxis: {
        categories: revenueDate,
      },
      markers: {
        size: 5,
        colors: ["#4caf50"],
        opacity: 0.9,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
    },
    series: [
      {
        name: "Revenue",
        data: revenue,
      },
    ],
  };


  const SubscriptionChart = {
    height: 300,
    type: "line",
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#86ca17"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#5ca811"],
          shadeIntensity: 0.5,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        width: 3,
        // curve: "smooth",
      },
      xaxis: {
        categories:subscriptiondate,
      },
      markers: {
        size: 5,
        colors: ["#4caf50"],
        opacity: 0.9,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
    },
    series: [
      {
        name: "Subscription",
        data: subscriptionItem,
      },
    ],
  };
  // PIE CHART

  const Ticket = {
    height: 150,
    type: "pie",
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          size: "75%",
        },
      },
      labels: ["Open","Close"],
      legend: {
        show: false,
      },
      tooltip: {
        theme: "datk",
      },
      grid: {
        padding: {
          top: 20,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ["#41DBAA", "#ffc901", "#DC143C"],
      fill: {
        opacity: [1, 1],
      },
      stroke: {
        width: 2,
      },
    },
    series: supports,
  };

  const cancelled = {
    height: 150,
    type: "pie",
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          size: "75%",
        },
      },
      labels: ["Cancelled", "Missed", "Rejected"],
      legend: {
        show: false,
      },
      tooltip: {
        theme: "datk",
      },
      grid: {
        padding: {
          top: 20,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ["#41DBAA", "#ffc901", "#DC143C"],
      fill: {
        opacity: [1, 1],
      },
      stroke: {
        width: 2,
      },
    },
    series: ride,
  };
  // console.log(ride);
  const subscription = {
    height: 150,
    type: "pie",
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          size: "75%",
        },
      },
      labels: ["Total Subscription", "Active Subscription"],
      legend: {
        show: false,
      },
      tooltip: {
        theme: "datk",
      },
      grid: {
        padding: {
          top: 20,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ["#41DBAA", "#ffc901"],
      fill: {
        opacity: [1, 1],
      },
      stroke: {
        width: 2,
      },
    },
    // series: [50, 90],
    series: subscriptions,
  };
  console.log(pie, "pie");
  return (
    <aside>
      <TopHeader type="dashboard" />
      <article className="w-90">
        <div className="row">
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-danger-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdElectricRickshaw />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Users</h5>
              </div>
              {/* <h3 className="text-end text-">{list?.users}</h3> */}
              <h3 className="text-end text-">{Count?.users}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-primary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <HiOutlineUsers />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Passengers</h5>
              </div>
              <h3 className="text-end text-">{Count?.passengers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{Count?.drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Inhouse</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{Count?.inhouse_drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-primary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Share Ride</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{Count?.sharecab_drivers}</h3>
            </div>
          </Col>

          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Subscription</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{Count?.subscription_drivers}</h3>
            </div>
          </Col>

          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-success-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Active</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{Count?.active_drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-prim-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Offline</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{Count?.offline_drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-danger-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdElectricRickshaw />
                </button>
                <h6 className="text-start">Approved</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{Count?.approved_drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-primary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Pending Approval</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">
                {Count?.pending_approval_drivers}
              </h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <GrCompliance />
                </button>
                <h6 className="text-start">Completed</h6>
                <h5 className="text-start">Rides</h5>
              </div>
              <h3 className="text-end text-">{Count?.completed_rides}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-success-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <VscLayersActive />
                </button>
                <h6 className="text-start">Active</h6>
                <h5 className="text-start">Rides</h5>
              </div>
              <h3 className="text-end text-">{Count?.active_rides}</h3>
            </div>
          </Col>
          {/* <Col xs={6} sm={4} lg={3} className="p-2">
              <div className="dash-box px-3 py-2">
                <div className="">
                  <button className="bg-prim-gradient">
                    <FontAwesomeIcon icon={faCoins} />
                  </button>
                  <h6 className="text-start">Offline</h6>
                  <h5 className="text-start">Rides</h5>
                </div>
                <h3 className="text-end text-">890</h3>
              </div>
            </Col> */}
        </div>

        <Row className="my-4 chart-dash">
          <Col xs={12} sm={6} lg={4} className="my-2 px-2">
            <Card>
              <Card.Header>
                <b>Open Tickets - Help & Support</b>
              </Card.Header>
              <Card.Body>
                <Chart {...Ticket} />
                <Row className="mt-3 text-center">
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle f-10 m-r-5 text-success" />
                      {pie?.total_support}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Total</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.open_support}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Open</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.close_support}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Closed</p>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2 px-2">
            <Card>
              <Card.Header>
                <b>Cancelled Rides</b>
              </Card.Header>
              <Card.Body>
                <Chart {...cancelled} />
                <Row className="mt-3 text-center">
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle f-10 m-r-5 text-success" />
                      {pie?.cancelled_rides}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Cancelled</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.missed_rides}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Missed</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.rejected_rides}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Rejected</p>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2 px-2">
            <Card>
              <Card.Header>
                <b>Subscription</b>
              </Card.Header>
              <Card.Body>
                <Chart {...subscription} />
                <Row className="mt-3 text-center">
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle f-10 m-r-5 text-success" />
                      {pie?.active_subscriptions}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Active Subscription</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.total_subscriptions}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Total Subscription</p>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="my-4 justify-content-center chart-dash">
          <Col md={12}>
            <Card>
              <Card.Header>
                <b>Revenue</b>
                <Col className="d-flex flex-wrap">
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">From Date</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => fromDate(e.target.value)}
                      value={start}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2"> To Date</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => toDate(e.target.value)}
                      value={end}
                    />
                  </div>
                </Col>
              </Card.Header>
              <Card.Body>
                <Chart {...Revenue} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="my-4 justify-content-center chart-dash">
          <Col md={12}>
            <Card>
              <Card.Header>
                <b>Share - Filtering Monthly Basis</b>
                <Col className="d-flex flex-wrap">
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">From Date</span>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="date"
                      onChange={(e) => fromDates(e)}
                      value={started}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">To Date</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => toDates(e)}
                      value={ended}
                    />
                  </div>
                </Col>
              </Card.Header>
              <Card.Body>
                <Chart {...PayTax} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="my-4 justify-content-center chart-dash">
          <Col md={12}>
            <Card>
              <Card.Header>
                <b>Subscription</b>
                <Col className="d-flex flex-wrap">
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">From Date</span>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="date"
                      onChange={(e) => fromDatesub(e)}
                      value={startedList}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">To Date</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => toDatesSub(e)}
                      value={ended}
                    />
                  </div>
                </Col>
              </Card.Header>
              <Card.Body>
                <Chart {...SubscriptionChart} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </article>
    </aside>
  );
};

export default Dashboard;
