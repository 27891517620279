import { configureStore } from "@reduxjs/toolkit";
import { api } from "../api/api";
import { rtkQueryErrorLogger } from "../slice/errorLogger";
import alertMessage from "../slice/alertMessage";
import loaderNoti from "../slice/loaderNoti";

const store = configureStore({
  reducer: {
    alertMessage: alertMessage,
    loaderNoti:loaderNoti,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

export default store;
