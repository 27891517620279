import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../assets/img";
import Navbar from "../navbar/Navbar";

const Header = () => {
  return (
    <header className="menu-bar">
      <Link to={"/"}>
        <div className="top-head gap-3 justify-content-center">
          {/* <img className="logo" src={Logo} /> */}
          <h5 className="app-name">SHARE CAB</h5>
        </div>
      </Link>
      <div className="btm-header-rit-top"></div>
      <Navbar />
    </header>
  );
};

export default Header;
