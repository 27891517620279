import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import {
  useAddPlaceMutation,
  useLazyListZoneQuery,
  useLazyViewPlaceQuery,
  useLazyViewZoneQuery,
  useUpdatePlaceMutation,
  useZonePlaceMutation,
  useZoneUpdatePlaceMutation,
} from "../../../constants/redux/api/api";

const AddZone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  //RTK
  const [addPlaceApi] = useAddPlaceMutation();
  const [placeViewApi]=useLazyViewPlaceQuery();
  const [updatePlaceApi]=useUpdatePlaceMutation();

  const [addZoneApi] = useZonePlaceMutation();
  const [zoneViewApi] = useLazyViewZoneQuery();
  const [updateZoneApi] = useZoneUpdatePlaceMutation();

  const [input, setInput] = useState([]);
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pin, setPin] = useState("");
  const [btn, setBtn] = useState(false);
  const [title, seTitle] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [titleErr, seTitleErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [LatitudeErr, setLatitudeErr] = useState(false);
  const [LongitudeErr, setLongitudeErr] = useState(false);

  const [areaErr, setAreaErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [pinErr, setPinErr] = useState(false);


  const viewPlace =()=>{
    placeViewApi(Id)
    .unwrap()
    .then(res=>{
      // console.log(res?.zone);
      let data=res?.place
      seTitle(data?.title)
      setAddress(data?.address)
      setLatitude(data?.latitude)
      setLongitude(data?.longitude)
    })
    .catch(err=>{
      console.log(err);
    })
  }
  useEffect(()=>{
    if(Id&&type=="editPlace"){
      viewPlace()
    }
    },[])
  const viewZone =()=>{
    zoneViewApi(Id)
    .unwrap()
    .then(res=>{
      // console.log(res?.zone);
      let data=res?.zone
      setArea(data?.from_pincode);
      setCity(data?.to_pincode);
    })
    .catch(err=>{
      console.log(err);
    })
  }

  useEffect(()=>{
  if(Id&&type=="editZone"){
    viewZone()
  }
  },[])
  const addZone = () => {
    if (area?.length == 0 || city?.length == 0) {
      setAreaErr(true);
      setCityErr(true);
    } else {
      let formData = new FormData();
      formData.append("from_pincode", area);
      formData.append("to_pincode", city);
      if (type == "editZone") {
        formData.append("_method", "PUT");
        setBtn(true);
        updateZoneApi({payload:formData,id:Id})
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/setting",{state:{tab:"Zone",}});
            setBtn(false);
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      } else {
        setBtn(true);
        addZoneApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/setting",{state:{tab:"Zone"}});
            setBtn(false);
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      }
    }
  };
  const addPlace = () => {
    if (
      longitude?.length == 0 ||
      latitude?.length == 0 ||
      address?.length == 0 ||
      title?.length == 0
    ) {
      seTitleErr(true);
      setAddressErr(true);
      setLatitudeErr(true);
      setLongitudeErr(true);
    } else {
      let formData = new FormData();
      formData.append("title", title);
      formData.append("address", address);
      formData.append("longitude", longitude);
      formData.append("latitude", latitude);
      if (type=="editPlace") {
        formData.append("_method", "PUT");
        setBtn(true);
        updatePlaceApi({payload:formData,id:Id})
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
          }
          navigate("/setting",{state:{tab:"Detail"}});
        })
        .catch((err) => {
          console.log(err);
        });
      } else {
      setBtn(true);
      addPlaceApi(formData)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
          }
          navigate("/setting",{state:{tab:"Detail"}});
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }
  };



  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="edit-zone" />
      ) : (
        <TopHeader type="add-zone" />
      )}
      {(type == "addPlace" || type=="editPlace") && (
        <article className="p-3">
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Title
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => seTitle(e.target.value)}
                        placeholder="Title"
                        value={title}
                      />
                      {title?.length == 0 && titleErr && (
                        <p className="err-p">Enter Title</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Address
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="username"
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="address"
                        value={address}
                      />
                      {address?.length == 0 && addressErr && (
                        <p className="err-p">Enter Address</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Latitude
                      </label>
                      <input
                        type="number"
                        id="example-if-email"
                        name="number"
                        onChange={(e) => setLatitude(e.target.value)}
                        placeholder="Latitude"
                        value={latitude}
                      />
                      {latitude?.length == 0 && LatitudeErr && (
                        <p className="err-p">Enter Latitude</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Longitude
                      </label>
                      <input
                        type="number"
                        id="example-if-email"
                        name="number"
                        onChange={(e) => setLongitude(e.target.value)}
                        placeholder="Longitude"
                        value={longitude}
                      />
                      {longitude?.length == 0 && LongitudeErr && (
                        <p className="err-p">Enter Longitude</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-auto ms-auto">
                  <button
                    type="button"
                    className="comn-btn bg-success-gradient px-3 py-2 me-2 my-4"
                    onClick={addPlace}
                    disabled={btn ? true : false}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </article>
      )}
      {(type == "addZone" ||
        type == "editZone" )&& (
          <article className="p-3">
            <div className="p-3">
              <div className="p-3 bg-white rounded-2">
                <Form className="d-flex flex-wrap">
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          From Pincode
                        </label>
                        <input
                          type="number"
                          id="example-if-email"
                          name="name"
                          onChange={(e) => setArea(e.target.value)}
                          placeholder="From date"
                          value={area}
                        />
                        {area?.length == 0 && areaErr && (
                          <p className="err-p">Enter From date</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          To Pincode
                        </label>
                        <input
                          type="number"
                          id="example-if-email"
                          name="username"
                          onChange={(e) => setCity(e.target.value)}
                          placeholder="To Pincode"
                          value={city}
                        />
                        {city?.length == 0 && cityErr && (
                          <p className="err-p">Enter To Pincode</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-auto ms-auto">
                    <button
                      type="button"
                      className="comn-btn bg-success-gradient px-3 py-2 me-2"
                      onClick={addZone}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </article>
        )}
    </aside>
  );
};

export default AddZone;
