import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import CompanyBooking from "./CompanyBooking";
import CompanyBookingCompleted from "./CompanyBookingCompleted";
import TopHeader2 from "../../../component/TopHeader2";

const CompanyBookingList = () => {
  return (
    <aside>
      <TopHeader2 type="booking" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="pending"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="pending" title="Booking List">
             <CompanyBooking/>
          </Tab>

          <Tab eventKey="resolved" title="Booking Completed">
            <CompanyBookingCompleted/>
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default CompanyBookingList;
