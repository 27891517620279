// export const BASE_URL = "https://testingbackend.thesharecab.com/"; //testing
export const BASE_URL = "https://testingsharescript.ninositsolution.com/"; // new testing
// export const BASE_URL = "http://192.168.1.16/sharecab-script/public/";
export const URL = {
  // LOGIN
  LOGIN: "crmapi/admin/login",
  LOGOUT: "crmapi/admin-logout",
  CHANGE_PASSWORD: "crmapi/admin-change-password",

  // DASHBOARDF
  DASHBOARD: "crmapi/counts",
  DASHBOARD_PIE: "crmapi/count-percentage",
  DASHBOARD_REVENUE: "crmapi/revenue-graph",
  DASHBOARD_PAY_TAX: "crmapi/pay-tax-graph",

  // ROLES
  ROLE: "crmapi/role",
  ROLECHECKLIST: "crmapi/modules",

  // ADMIN
  ADMINS: "crmapi/admins",
  ADMIN: "crmapi/admin",
  ADMIN_ROLES: "crmapi/active-roles",

  // SUBSCRIPTIONS

  SUBSCRIPTIONS: "crmapi/subscriptions",
  SUBSCRIPTION: "crmapi/subscription",
  SUBSCRIPTION_PAYMENT: "crmapi/subscription-payment-details",

  // ZONES
  ZONE: "crmapi/zone",
  IMPORT_ZONE: "crmapi/import-zone",
  EXAMPLE_ZONE: "crmapi/export-sample-zone",

  // FARE
  FARE: "crmapi/price",
  FARE_ESTIMATION: "crmapi/ride-fare-detail",

  // FARE -  PEAK
  PEAK: "crmapi/peak-charge",

  // FARE - OTHER CHARGES
  OTHERCHARGES: "crmapi/other-charge",

  // SETTING

  SETTING: "crmapi/setting",

  // DRIVER REQUEST

  DRIVER_REQUSET: "crmapi/driver-request",
  DRIVER_REQUSET_REJECT: "crmapi/request-reject",
  PROOF_ACCEPT: "crmapi/proof/approve-reject",

  // DRIVER

  DRIVERS: "crmapi/accepted-drivers",
  DRIVER_STATUS: "crmapi/driver-status/",
  DRIVER_VIEW: "crmapi/driver-profile/",
  DRIVER_RIDE_HISTORY: "crmapi/driver-ride-history",
  DRIVER_RIDE_HISTORY_VIEW: "crmapi/driver-ride-history-view",
  DRIVER_SUBSCRIPTION_DATE: "crmapi/change-subscription-end-date",
  DRIVER_SHARE_CAB_RIDE_HISTORY:"crmapi/share-cab-driver-ride-history/",

  // USERS

  USERS: "crmapi/users-list",
  // USERS_VIEW: "crmapi/user-view/",
  USER_STATUS: "crmapi/user-status/",
  USER_RIDE_HISTORY: "crmapi/user-ride-history",
  // USER_RIDE_HISTORY_VIEW: "ride-history-view/",
  USER_RIDE_HISTORY_VIEW: "crmapi/user-ride-history-view/",

  // HELP & SUPPORT

  HELP_SUPPORT_PENDING: "crmapi/supports-raised",
  HELP_SUPPORT_RESOLVED: "crmapi/supports-resolved ",
  REPLY_SUPPORT: "crmapi/support-reply",

  // OFFLINE BOOKING

  OFFLINE_BOOKING: "crmapi/offline-prebooking",
  OFFLINE_FIRST_SEARCH: "crmapi/offline-first-search",
  OFFLINE_USER_LIST: "crmapi/user-list",
  OFFLINE_BOOKING_LIST: "crmapi/offline-booking-list",
  OFFLINE_SECOND_SEARCH: "crmapi/offline-second-search",
  OFFLINE_BOOKING_STATUS: "crmapi/search-ride-status",
  CANCEL_RIDE: "crmapi/cancel-search-ride",

  // REWARDS

  REQUEST_REWARD: "crmapi/request-reward-list",
  REQUEST_REWARD_DETAILS: "crmapi/request-reward-detail",
  REWARD_PAY: "crmapi/reward-paid",
  REWARD_HISTORY: "crmapi/reward-history",

  // PAY TAX

  PAY_TAX_LIST: "crmapi/pay-tax-list",

  // BOOKING

  BOOKING_LIST: "crmapi/bookings",
  BOOKING_VIEW: "crmapi/booking-view",

  // SCHEDULED BOOKING

  SCHEDULED_BOOKING: "crmapi/pre-bookings",

  // REFFERALLS

  REFFERAL: "crmapi/refferals",

  // CANCEL && REJECTERD  && MISSED OUT

  CANCEL_REJECT_MISSED: "crmapi/cancel-ride",
  REJECT_RIDE_VIEW: "crmapi/reject-ride-view/",
  CANCEL_RIDE_VIEW: "crmapi/cancel-ride-view/",

  // REVIEW

  USER_REVIEW: "crmapi/user-reviews",
  DRIVER_REVIEW: "crmapi/driver-reviews",

  // ACTIVITY LOG

  ACTIVITY_LOG: "crmapi/activity-log",

  // REPORTS

  REPORTS: "crmapi/report",
  EXCEL: "crmapi/export",

  // NOTIFICATION

  READ_STATUS: "crmapi/notification-read-status",
  READ_ENTRY: "crmapi/notification-read-entry",
  NOTIFY_LIST: "crmapi/all-notifications",

  // PUSH NOTIFICATION

  PUSH_NOTIFY: "crmapi/d-notification",

  // PDF COMPLETED RIDE

  COMPLETED_PDF: "crmapi/completed-rides-pdf",

  COMPLETED_RIDES: "crmapi/completed-rides",

  // /RIDE TYPE
  RIDE_TYPE: "crmapi/ride-type",

  //DRIVER LIST
  DRIVER_LIST_REQUESTS: "crmapi/driver/request-rejects",
  DRIVER_LIST: "crmapi/driver-list",
  DRIVER_LIST_VIEW: "crmapi/driver/",

  //RIDE LIST
  RIDE_STORE: "crmapi/ride-seats",
  //OTEHR CHARGES
  OTHER_CHARGES: "crmapi/other-charges",

  //PEAK CHARGES
  PEAK_CHARGES: "crmapi/peak-charges",
  //DISTANCE
  DISTANCE: "crmapi/prices",
  //PLACE
  PLACE: "crmapi/places",
  //ZONE
  ZONE: "crmapi/zones",
  //OFFLINE
  OFFLINE: "crmapi/offline-booking-list",
  OFFLINE_ACCEPT_LIST: "crmapi/offline-accepted-bookings",
  OFFLINE_ACCEPT_VIEW: "crmapi/offline-booking/",
  PREBOOKING: "crmapi/offline-prebooking",
  ACCEPT_DRIVER: "crmapi/accepted-drivers",
  FIRST_ACCEPT: "crmapi/offline-first-search",
  SECOND_ACCEPT: "crmapi/offline-second-search/",
  SEARCH_RIDE_STATUS: "crmapi/search-ride-status",
  RIDEFARE: "crmapi/ride-fare-detail",

  //SHARECAB

  SHARE_CAB_LIST: "crmapi/share-cab-ride-list",
  CREATE_SHARE_CAB_RIDE: "crmapi/create-share-cab-ride",
  EDIT_SHARE_CAB_RIDE: "crmapi/edit-share-cab-ride/",
  LIST_SHARE_CAB: "crmapi/share-cab-driver-ride-list",
  VIEW_SHARE_CAB: "crmapi/share-cab-ride-view/",
  SEAT_AVAILABLE_CLICK: "crmapi/seat-availability-check",
  SHARE_CAB_PRICE: "crmapi/share-cab-seat-price",
  ADMIN_SHARECAB_RIDE: "crmapi/admin-request-share-cab-ride",

  //COMPANY
  COMPANY_ADDED: "crmapi/add-company",
  COMPANY_LIST: "crmapi/list-company",
  COMPANY_EDIT: "crmapi/edit-company/",
  COMPANY_VIEW: "crmapi/view-company/",
  COMPANY_STATUS: "crmapi/status-change-company/",
  COMPANY_DELETE: "crmapi/delete-company/",

  // COMPANY USER
  COMPANY_USER_LIST: "crmapi/list-company-user",
  COMPANY_USER_ADD: "crmapi/add-company-user",
  COMPANY_USER_EDIT: "crmapi/edit-company-user/",
  COMPANY_USER_VIEW: "crmapi/view-company-user/",
  COMPANY_USER_STATUS: "crmapi/status-change-company-user/",
  COMPANY_USER_DELETE: "crmapi/delete-company-user/",

  //USER
  USER_VIEW: "crmapi/user-detail/",
  ACTIVE_USER_LIST: "crmapi/active-user-list",

  //SETTING MARGIN
  MARGIN: "crmapi/settings",

  //NOTIFICATION
  NOTIFICATION_LIST: "crmapi/notification-list",
  NOTIFICATION_ADD: "crmapi/post-notification",
  NOTIFICATION_DELETE: "crmapi/remove-notification/",
  NOTIFICATION_VIEW: "crmapi/notification/",
  USER_DRIVER_NOTIFICATION_LIST:"crmapi/booking-notifications",
  USER_DRIVER_NOTIFICATION_View:"/crmapi/notifications/",
  RIDE_SEARCH_NOTIFICTION:"/crmapi/search-ride-notifications/",
  //BOOKING HISTORY
  CANCEL_ORDER: "crmapi/cancel-booking-list",
  SCHEDULED_BOOKING_LIST: "crmapi/pre-booking-list",
  COMPLETED_BOOKING_LIST: "crmapi/completed-booking-list",
  ONGOING_BOOKING_LIST: "crmapi/ongoing-booking-list",
  SCHEDULED_BOOKING_VIEW:"crmapi/schedule-ride-view/",

  // COUPONS
  COUPONS_LIST: "crmapi/coupons-list",
  COUPONS_CREATE: "crmapi/create-coupon",
  COUPONS_VIEW: "crmapi/view-coupon/",
  COUPONS_UPDATE: "crmapi/update-coupon/",
  COUPONS_DELETE: "crmapi/delete-coupon/",

  // REWARDS
  REWARDS_LIST: "crmapi/rewards",
  REWARDS_CREATE: "crmapi/rewards",
  REWARDS_VIEW: "crmapi/rewards/",
  REWARDS_UPDATE: "crmapi/rewards/",
  REWARDS_DELETE: "crmapi/rewards/",
  //HELP&SUPPORT
  SUPPORT_LIST: "crmapi/supports-list",
  SUPPORT_VIEW: "/crmapi/support-view/",
  SUPPORT_REPLY: "crmapi/support-reply",

  //SHARE CAB LISTS
  USER_REQUEST_LIST:"crmapi/search-place-list",
  PRESETED_LIST:"crmapi/share-cab-preseted-ride-list",
  
  //USER HISTORY VIEW 
  USER_RIDE_HISTORY:"crmapi/user-ride-history/",
  USER_RIDE_HISTORY_VIEW:"crmapi/user/ride-history/",
  USER_RIDE_HISTORY_STATUS:"crmapi/user-status/",
  //DRIVER HISTORY VIEW
  DRIVER_RIDE_HISTORY:"crmapi/driver-ride-history/",
  DRIVER_RIDE_HISTORY_VIEW:"crmapi/driver/ride-history-view/",


  //REFER AND EARN
  REFER_USER_LIST:"crmapi/referrer-list",

  //REVENUE
  REVENUE_SUBSCRIPTION:"crmapi/subscription-revenue",
  REVENUE_INHOUSE:"crmapi/inhouse-revenue",
  REVENUE_SHARECAB:"crmapi/sharecab-revenue",

  // REVIEW
  REVIEW_USERS:"crmapi/user-review-list",
  REVIEW_DRIVER:"crmapi/driver-review-list",

  //REPORT
  REPORT_LIST:"crmapi/report",
  REPORT_EXCEL:"crmapi/export",

  //Company Update
  COMPANY_DRIVER_UPDATE:"crmapi/driver-company-update",
  //Dashboard
  DASHBOARD_OVERALL:"crmapi/overall-count-list",
  DASHBOARD_PERCENTAGE:"crmapi/percentage-list",
  DASHBOARD_REVENUE:"crmapi/revenue-graph",
  DASHBOAD_PAY_TAX_GRAGH:"crmapi/pay-tax-graph",
  DASHBOAD_SUBSCRIPTION:"crmapi/subs-revenue-graph",

  //BOOking Invoice

  BOOKING_INVOICE:"crmapi/completed-booking-invoice/",

  //----------------------------------------------------------------------------------//
  //COMPANY LOGIN

  COMPANY_LOGIN: "company/company/login",
  COMPANY_LOGOUT: "company/company/logout",
  COMPANY_CHANGE_PASSWORD: "company/company-change-password",

  //OFFLINE
  OFFLINE_COMPANY: "company/offline-booking-list",
  OFFLINE_ACCEPT_LIST_COMPANY: "company/offline-accepted-bookings",
  OFFLINE_ACCEPT_VIEW_COMPANY: "company/offline-booking/",
  PREBOOKING_COMPANY: "company/offline-prebooking",
  ACCEPT_DRIVER_COMPANY: "company/accepted-drivers",
  FIRST_ACCEPT_COMPANY: "company/offline-first-search",
  SECOND_ACCEPT_COMPANY: "company/offline-second-search/",
  SEARCH_RIDE_STATUS_COMPANY: "company/search-ride-status",
  RIDEFARE_COMPANY: "company/ride-fare-detail",
  // DRIVER ACCEPT
  COMPANY_ACCEPT_DRIVER: "company/list-company-drivers",
  COMPANY_SERVICE: "user/check-location",


  //SHARECAB
  COMPANY_SHARE_CAB_LIST: "company/share-cab-ride-list",
  COMPANY_CREATE_SHARE_CAB_RIDE: "company/create-share-cab-ride",
  COMPANY_EDIT_SHARE_CAB_RIDE: "company/edit-share-cab-ride/",
  COMPANY_LIST_SHARE_CAB: "company/share-cab-driver-ride-list",
  COMPANY_VIEW_SHARE_CAB: "company/share-cab-ride-view/",
  COMPANY_SEAT_AVAILABLE_CLICK: "company/seat-availability-check",
  COMPANY_SHARE_CAB_PRICE: "company/share-cab-seat-price",
  COMPANY_COMPANY_ADMIN_SHARECAB_RIDE: "company/company-request-share-cab-ride",
  COMPANY_PRESETED_RIDE_LIST:"company/share-cab-preseted-ride-list",

  //BOOKING HISTORY
//  COMPANY_BOOKING_HISTORY_LIST:"company/booking-history",
 COMPANY_BOOKING_HISTORY_LIST:"company/ongoing-booking-list",
//  COMPANY_BOOKING_HISTORY_COMPLETED:"company/completed-booking-history",
 COMPANY_BOOKING_HISTORY_COMPLETED:"company/completed-booking-list",
 COMPANY_BOOKING_HISTORY_VIEW:"company/view-booking/",

 //CANCEL ORDER
 COMPANY_CANCEL_BOOKING_LIST:"company/cancel-booking-list",

 //SCHEDULE LIST
  COMPANY_SCHEDULE_BOOKING_LIST:"company/pre-booking-list",
  COMPANY_SCHEDULE_BOOKING_VIEW:"company/schedule-ride-view/",
  //DRIVER
  COMPANY_DRIVER_LIST:"company/list-company-drivers",
  COMPANY_PROFILE_VIEW:"company/view-driver/",
  COMPANY_RIDE_HISTORY:"company/driver-ride-history/",
  COMPANY_DRIVER_SHARE_CAB_RIDE_HISTORY:"company/share-cab-driver-ride-history/",
  //REVENUE
  COMPANY_REVENUE_SUBSCRIPTION:"company/subscription-revenue",
  COMPANY_SHARECAB_LIST:"company/sharecab-revenue",
  COMPANY_INHOUSE_REVENUE:"company/inhouse-revenue",
  //Dashboard
  COMPANY_DASHBOARD_OVERALL:"company/company-drivers-count",
  COMPANY_DASHBOARD_PERCENTAGE:"company/percentage-list",
  COMPANY_DASHBOARD_REVENUE:"company/revenue-graph",
  COMPANY_DASHBOAD_PAY_TAX_GRAGH:"company/pay-tax-graph",
  COMPANY_SUBS_REVENUE_GRAPH:"company/subs-revenue-graph",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";
export const ERROR_STATUS = "error_status";
export const MAPS_API_KEY = "AIzaSyC16FUHDJkJ7aCwPH1RNfN3tjtpCqObiq4";
// export const MAPS_API_KEY = "AIzaSyDcF4o_gvM3iw-ftLKHHCc4fGpkksWYRCU";

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
