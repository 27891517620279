import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { TbArmchair2 } from "react-icons/tb";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";
import { useDispatch } from "react-redux";
import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useAddedStoreMutation,
  useDeleteStoreMutation,
  useLazyListVehicleQuery,
  useLazyVehicleViewQuery,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const VehicleTypes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "Seat",
        accessor: "no_of_seats",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },
      // {
      //   Header: "Price",
      //   accessor: "total_price",
      //   Cell: ({ value, row }) => {
      //     return <span>{value}</span>;
      //   },
      // },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="flex-ac">
                <button
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() =>
                    navigate("/vehicle-edit", { state: { data: row.original } })
                  }
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                {/* <button
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() => view(row.original.id)}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button> */}
                <button
                  className="comn-btn bg-danger edit-btn-svg ms-4"
                  onClick={() => deleteFares(row.original.id)}
                
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  //RTK
  const [storeApi] = useAddedStoreMutation();
  const [listVehicleApi] = useLazyListVehicleQuery();
  const [vechileViewApi] = useLazyVehicleViewQuery();
  const [deleteApi]=useDeleteStoreMutation();

  const [currentItems, setCurrentItems] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [input, setInput] = useState([]);
  const [Id, setId] = useState();
  const [btn, setBtn] = useState(false);
  const [sub, setSub] = useState();
  const [list, setList] = useState([]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((val) => ({ ...val, [name]: value }));
  };

  const [seatPrice, setSeatPrice] = useState([]);
  const [seats, setSeats] = useState("");
  const [name, setname] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [seatsErr, setSeatsErr] = useState(false);
  const [nameErr, setnameErr] = useState(false);
  const [listErr, setListErr] = useState(false);

  const seatHandler = (num) => {
    let temp = [];

    if (num <= 12) {
      for (let i = 0; i < num; i++) {
        temp.push({ value: "", seat: "" });
      }
      setSeatPrice(temp);
      setSeats(num);
    }
  };

  const changeSeat = (event, ind, type) => {
    let temp = [...seatPrice];

    temp[ind] = {
      ...temp[ind],
      [type]: event,
    };

    setSeatPrice(temp);
  };

  //vehicle list
  const getList = () => {
    listVehicleApi()
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        setList(res?.rides);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
  }, []);
  //ADD Vehicle

  const addtax = () => {
    let empty;
    if (seatPrice?.length > 0) {
      seatPrice?.map((item) => {
        empty = Object.values(item).some(
          (x) => x === null || x === "" || x === " "
        );
      });
    }
    console.log(empty, "empty");
    if (
      name?.length == "" ||
      seats?.length == "" ||
      empty == true ||
      image?.length == ""
    ) {
      setSeatsErr(true);
      setnameErr(true);
      setListErr(true);
      setImageErr(true);
    } else {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("no_of_seats", seats);
      if (image?.name) {
        formData.append("image", image);
      }
      seatPrice?.map((item, ind) => {
        formData.append(`seats[${ind}][seat_no]`, item?.seat);
        formData.append(`seats[${ind}][amount]`, item?.value);
      });
      setBtn(true);
      dispatch(saveLoader(true));
      storeApi(formData)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
          }
          setAddModal(false);
          setname("");
          setSeats("");
          getList();
          dispatch(saveLoader(false));
          setBtn(false);
        })
        .catch((err) => {
          console.log(err);
          dispatch(saveLoader(false));
          setBtn(false);
        });
    }
  };

  const editTax = () => {
    let formData = new FormData();
    formData.append("value", input?.value);
    formData.append("type", input?.type);
    formData.append("_method", "PUT");
    setBtn(true);
  };

  const deleteFares = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        deleteApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };

  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          {/* <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end"> */}
          {/* <div className="flex-ac-jc"> */}
          <button
            onClick={() => setAddModal(true)}
            className="comn-btn bg-primary1 px-3 py-2 aligm-end"
            style={{
              width: "150px",
              margin: "auto 0 10px auto",
              display: "block",
            }}
          >
            Add Vehicle Types
          </button>
          {/* </div> */}
          {/* </div> */}
          <Table columns={columns} data={list} />
        </div>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Vehicle Types</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Name
            </label>
            <input
              type="text"
              id="example-if-email"
              name="Name"
              value={name}
              onChange={(e) => setname(e.target.value)}
              placeholder="Name"
            />
            {name?.length == 0 && nameErr && (
              <p className="err-p">Enter Name</p>
            )}
          </div>

          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Seat
            </label>
            <input
              type="number"
              id="example-if-email"
              name="Seat"
              value={seats}
              onChange={(e) => seatHandler(e.target.value)}
              placeholder="Seat"
            />
            {seats?.length == 0 && seatsErr && (
              <p className="err-p">Enter Seats</p>
            )}
          </div>
          {seatPrice?.map((item, ind) => {
            return (
              <div className="px-1 col-sm-6 modal-label-input" key={ind}>
                <label className="text-primary-dark" for="">
                  <TbArmchair2 style={{ width: "30px", height: "25px" }} />
                </label>
                <div style={{ position: "relative", marginBottom: "10px" }}>
                  <input
                    type="text"
                    id="example-if-email"
                    name="Seat"
                    value={item?.seat}
                    onChange={(e) => changeSeat(e.target.value, ind, "seat")}
                    placeholder="Seat"
                  />
                  {item?.seat?.length == 0 && listErr && (
                    <p className="err-p">Enter Seat</p>
                  )}
                </div>
                <div style={{ position: "relative", marginBottom: "10px" }}>
                  <input
                    type="number"
                    id="example-if-email"
                    name="Price"
                    value={item?.value}
                    onChange={(e) => changeSeat(e.target.value, ind, "value")}
                    placeholder="Price"
                  />
                  {item?.value?.length == 0 && listErr && (
                    <p className="err-p">Enter Value</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="px-1 col-sm-6 modal-label-input">
          <label className="text-primary-dark" for="">
            Image
          </label>
          <input
            accept="image/png, image/gif, image/jpeg"
            type="file"
            id="example-if-email"
            name="Name"
            onChange={(e) => setImage(e.target.files[0])}
            placeholder="Name"
          />
          {image?.length == 0 && imageErr && (
            <p className="err-p">Enter Image</p>
          )}
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setAddModal(false);
              setSeatPrice([]);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={addtax}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Edit Vehicle Types</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Name
            </label>
            <input
              type="text"
              id="example-if-email"
              name="Name"
              onChange={handleChange}
              placeholder="Name"
            />
          </div>

          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Seat
            </label>
            <input
              type="text"
              id="example-if-email"
              name="Seat"
              onChange={handleChange}
              placeholder="Seat"
            />
            {seats?.length == 0 && seatsErr && (
              <p className="err-p">Enter Seats</p>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Price
            </label>
            <input
              type="number"
              id="example-if-email"
              name="Price"
              onChange={handleChange}
              placeholder="Price"
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setEditModal(false);
              setId("");
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className={`comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none me-2  ${
              btn && "opacity-50"
            }`}
            data-dismiss="modal"
            onClick={editTax}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default VehicleTypes;
