import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import {
  useCompanyAddMutation,
  useCompanyEditMutation,
  useLazyCompanyViewQuery,
} from "../../../constants/redux/api/api";

const CompanyAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  //RTK
  const [addCompanyApi] = useCompanyAddMutation();
  const [editCompanyApi] = useCompanyEditMutation();
  const [viewCompanyApi] = useLazyCompanyViewQuery();

  const [name, setName] = useState("");
  const [btn, setBtn] = useState(false);
  const [nameErr, setNameErr] = useState(false);

  const viewCompany = () => {
    viewCompanyApi(Id)
      .unwrap()
      .then((res) => {
        // console.log(res?.company);
        const data = res?.company;
        setName(data?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (Id) {
      viewCompany();
    }
  }, []);

  const addCompany = () => {
    if (name?.length == "") {
      setNameErr(true);
    } else {
      let formData = new FormData();
      formData.append("name", name);

      if (type == "editCompany") {
        setBtn(true);
        editCompanyApi({ payload: formData, id: Id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/setting", { state: { tab: "Company" } });
            setBtn(false);
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      } else {
        setBtn(true);
        addCompanyApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/setting", { state: { tab: "Company" } });
            setBtn(false);
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
          });
      }
    }
  };

  return (
    <aside>
      <TopHeader type="company add" />

      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <h3 className="text-primary-dark">Company Add</h3>
                    <label className="text-primary-dark" for="">
                      Name
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                      value={name}
                    />
                    {name?.length == 0 && nameErr && (
                      <p className="err-p">Enter Name</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-auto ms-auto">
                <button
                  type="button"
                  className="comn-btn bg-success-gradient px-3 py-2 me-2"
                  onClick={addCompany}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default CompanyAdd;
