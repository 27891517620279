import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import { useLazyDriverRequestQuery } from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const Requests = ({ request, setTo, setFrom, to, from }) => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const action = "total_hrs";

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <p>{"#AD" + value}</p>;
        },
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicle_no",
        Cell: ({ value, row }) => {
          // let data = value.filter((i) => i?.type == 1);
          // let number = data[0];
          return <p>{value}</p>;
        },
      },
      {
        Header: "Type",
        accessor: "driver_type",
        Cell: ({ value, row }) => {
          return (
            <p>
              {value == 1
                ? "Inhouse"
                : value == 2
                ? "subscription"
                : value == 3
                ? "share Cab"
                : ""}
            </p>
          );
        },
      },
      {
        Header: "Installation Date",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{value ? moment(value).format("DD/MM/YYYY") : ""}</p>;
        },
      },
      {
        Header: "Documents Submission Date",
        accessor: "updated_at",
        Cell: ({ value, row }) => {
          return <p>{value ? moment(value).format("DD/MM/YYYY") : ""}</p>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {permissons?.includes("CAN-VIEW-DRIVERS") ? (
                <button
                  onClick={() =>
                    navigate("/driver-request/document", {
                      state: { data: row.original },
                    })
                  }
                  className="comn-btn bg-primary edit-btn-svg"
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  // RTK QUERY
  const [driverRequestApi] = useLazyDriverRequestQuery();

  const [list, setList] = useState([]);
  // const [from, setFrom] = useState();
  // const [to, setTo] = useState();
  const [editModal, setEditModal] = useState(false);
  const [reason, setReason] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  // const getList = () => {
  //   let params = `?start_date=${from}&end_date=${to}`;
  //   if (from && to) {
  //     driverRequestApi(params)
  //       .unwrap()
  //       .then((res) => {
  //         setList(res?.request_drivers.reverse());
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   } else if (!from && !to) {
  //     let params = "";
  //     driverRequestApi(params)
  //       .unwrap()
  //       .then((res) => {
  //         setList(res?.request_drivers);
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // };

  const reasonReject = (data) => {
    setReason(data);
    setEditModal(true);
  };

  // useEffect(() => {
  //   getList();
  // }, [from, to]);

  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
            <Col md={3} xs={6} className="px-2 my-2">
              <span>From Date</span>
              <input
                type="date"
                className="form-control"
                onChange={startDate}
                value={from}
              />
            </Col>
            <Col md={3} xs={6} className="px-2 my-2">
              <span>To Date</span>
              <input
                type="date"
                className="form-control"
                onChange={ToDate}
                value={to}
              />
            </Col>
          </div>
          <Table columns={columns} data={request} className="" />
        </div>
      </article>
      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Rejected Reason</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Rejected Reason
            </label>
            <textarea
              placeholder="reason"
              type="text"
              name="reason"
              className="bg-transparent"
              value={reason}
              disabled
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={() => setEditModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default Requests;
