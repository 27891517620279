import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import swal from "sweetalert";
import { toast } from "react-toastify";
import {
  useLazyPeakChargesViewQuery,
  useLazyPeakViewQuery,
  usePeakAddMutation,
  usePeakChargesAddedMutation,
  usePeakEditMutation,
  usePeakupdatedChargesMutation,
} from "../../../constants/redux/api/api";

const AddPeak = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state.type;
  const data = location?.state?.ride_id;
  const id = location?.state?.id;
  console.log(location, "loc");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [percentage, setpercentage] = useState("");
  const [chargesfor, setCharefor] = useState("");
  const [types, setType] = useState("");
  const [km, setKm] = useState("");
  const [peak, setPeak] = useState([]);
  const [night, setNigt] = useState([]);
  const [btn, setBtn] = useState(false);
  const [btnTo, setBtnTo] = useState("");
  const [chargesforErr, setChareforErr] = useState(false);
  const [percentageErr, setpercentageErr] = useState(false);
  const [fromErr, setFromErr] = useState(false);
  const [typesErr, setTypeErr] = useState(false);
  const [toErr, setToErr] = useState(false);

  // HANDLE CHANGE EVENT

  const handleChangePeak = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPeak((val) => ({ ...val, [name]: value }));
  };

  const handleChangeNight = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNigt((val) => ({ ...val, [name]: value }));
  };

  // RTK QUERY
  const [peakViewAPi] = useLazyPeakChargesViewQuery();
  const [peakChagesAddApi] = usePeakChargesAddedMutation();
  const [peakChargesUpdateApi] = usePeakupdatedChargesMutation();

  // PEAK ADD & EDIT
  const viewApi = () => {
    peakViewAPi(id)
      .unwrap()
      .then((res) => {
        console.log(res);
        const data = res?.peak_charge;
        setFrom(data?.from_time)
        setTo(data?.to_time)
        setpercentage(data?.percentage)
        setCharefor(data?.charge_for);
        setType(data?.type)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (id) {
      viewApi();
    }
  }, []);

  const peakAdd = () => {
    // console.log(data);
    if (
      from?.length == "" ||
      percentage?.length == "" ||
      chargesfor?.length == "" ||
      to?.length == "" ||
      types?.length == ""
    ) {
      setToErr(true);
      setFromErr(true);
      setpercentageErr(true);
      setChareforErr(true);
      setTypeErr(true);
    } else {
      const formData = new FormData();
      formData.append("charge_for", chargesfor);
      // formData.append("charge_for ", chargesfor);
      formData.append("ride_id", data?.id);
      formData.append("from_time", from);
      formData.append("to_time", to);
      formData.append("percentage", percentage);
      formData.append("type", types);
      if (type == "editPeak") {
        formData.append("_method", "PUT");
        peakChargesUpdateApi({ payload: formData, id: id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit",{state:{tab:"Vechile",data:data,}});
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        peakChagesAddApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit",{state:{tab:"Vechile",data:data,}});
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <>
      <aside>
        {type === "edit" ? (
          <TopHeader type="editFare" />
        ) : (
          <TopHeader type="addFare" />
        )}

        <article>
          {/* <Tabs
            defaultActiveKey={lg_fairtype == "Peak" ? "Detail-View" : "Tax"}
            id="uncontrolled-tab-example"
            className="mb-3"
          > */}

          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <label>Peak Fare test</label>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Start Time
                      </label>
                      <input
                        type="time"
                        id="example-if-email"
                        placeholder="start time"
                        name="from_time"
                        className="bg-transparent"
                        onChange={(e) => setFrom(e.target.value)}
                        value={from}
                      />
                      {from?.length == 0 && fromErr && (
                        <p className="err-p">Enter From</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        End Time
                      </label>
                      <input
                        type="time"
                        id="example-if-email"
                        placeholder="end time"
                        name="to_time"
                        onChange={(e) => setTo(e.target.value)}
                        value={to}
                        className="bg-transparent"
                      />
                      {to?.length == 0 && toErr && (
                        <p className="err-p">Enter To</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        peak Percentage
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="peak percentage"
                        name="percentage"
                        onChange={(e) => setpercentage(e.target.value)}
                        value={percentage}
                        className="bg-transparent"
                      />
                      {percentage?.length == 0 && percentageErr && (
                        <p className="err-p">Enter Percentage</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Charges For
                      </label>

                      <select
                        className="bg-transparent"
                        onChange={(e) => setCharefor(e.target.value)}
                        value={chargesfor}
                      >
                        <option value={""}>Select Charges</option>
                        <option value={"1"}>Intercity</option>
                        <option value={"2"}>Intracity</option>
                        <option value={"3"}>sharecab</option>
                      </select>
                      {chargesfor?.length == 0 && chargesforErr && (
                        <p className="err-p">Enter Charges</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        peak
                      </label>

                      <select
                        className="bg-transparent"
                        onChange={(e) => setType(e.target.value)}
                        value={types}
                      >
                        <option value={""}>Select Charges</option>
                        <option value={"1"}>Peak Fare</option>
                        <option value={"2"}>Night Fare</option>
                      </select>
                      {types?.length == 0 && typesErr && (
                        <p className="err-p">Enter peak</p>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
              <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                <button
                  className="comn-btn bg-primary px-3 py-2 me-2"
                  onClick={peakAdd}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          {/* <Tab eventKey="Tax" title="Night Fare">
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <label>Night Fare</label>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Start Time
                          </label>
                          <input
                            type="time"
                            id="example-if-email"
                            placeholder="start time"
                            name="from_time"
                            onChange={handleChangeNight}
                            value={night?.from_time}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            End Time
                          </label>
                          <input
                            type="time"
                            id="example-if-email"
                            placeholder="end time"
                            name="to_time"
                            onChange={handleChangeNight}
                            value={night?.to_time}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Night Fare
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="night fare"
                            name="percentage"
                            onChange={handleChangeNight}
                            value={night?.percentage}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={nightAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                  <br></br>
                </div>
              </div>
            </Tab> */}
          {/* </Tabs> */}
        </article>
      </aside>
    </>
  );
};

export default AddPeak;
