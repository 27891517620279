import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, Tab, Tabs } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import {
  useLazyActiveUserListQuery,
  useLazyDriverAccpetQuery,
  useLazyNotificationDeleteQuery,
  useLazyNotificationListQuery,
  useLazyNotificationViewQuery,
  useLazyRideSearchViewQuery,
  useLazyUserDriverNotificationListQuery,
  usePushNotificationMutation,
} from "../../../constants/redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}
function TableView({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 40 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}
const PushNotification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [types, setTypes] = useState("");
  const [driver, setDriver] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [typesErr, setTypesErr] = useState(false);
  const [driverErr, setDriverErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [imagePre, setImagePre] = useState("");
  const [driverList, setDriverList] = useState([]);
  const [user, setUser] = useState("");
  const [userErr, setUserErr] = useState(false);
  const [userList, setUserList] = useState([]);
  const [description, setDescription] = useState("");
  const [viewId, setViewId] = useState("");
  const [btn, setBtn] = useState(false);
  const [type, setType] = useState("");
  const [usernotificationList, setUserNotificationList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [firstView, setFirstView] = useState([]);
  const [secondView, setSeacondView] = useState([]);
  const [thierdView, setTriedView] = useState([]);
  const [viewModel, setViewModel] = useState(false);

  //RTK
  const [driverAcceptApi] = useLazyDriverAccpetQuery();
  const [userListApi] = useLazyActiveUserListQuery();
  const [pushApi] = usePushNotificationMutation();
  const [notificationList] = useLazyNotificationListQuery();
  const [notificationView] = useLazyNotificationViewQuery();
  const [notificationDelete] = useLazyNotificationDeleteQuery();
  const [userNotification] = useLazyUserDriverNotificationListQuery();

  //RTK
  const [userView] = useLazyRideSearchViewQuery();
  const apiDriver = () => {
    driverAcceptApi()
      .unwrap()
      .then((res) => {
        setDriverList(res?.drivers);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const apiUser = () => {
    userListApi()
      .unwrap()
      .then((res) => {
        console.log(res);
        setUserList(res?.active_users);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  //vehicle list
  const getList = () => {
    notificationList()
      .unwrap()
      .then((res) => {
        // console.log(res, "res");
        setList(res?.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //vehicle list
  const getNotificationList = () => {
    userNotification()
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        // setList(res?.notifications);
        setUserNotificationList(res?.bookings);
        setSearchList(res?.search_rides);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    apiDriver();
    apiUser();
    getList();
    getNotificationList();
  }, []);

  const columnsView = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columnsView2 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columnsView3 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <>{moment(value).format("DD/MM/YYYY")}</>;
        },
      },

      {
        Header: "Tittle",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      // {
      //   Header: "Image",
      //   accessor: "img_url",
      //   Cell: ({ value, row }) => {
      //     return <img src={value} style={{ width: "80px", height: "50px" }} />;
      //   },
      // },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                style={{ margin: "0px 5px" }}
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() => setViewId(row.original.id)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              {permissons?.includes("CAN-DELETE-PUSH NOTIFICATIONS") ? (
                // <td className="flex-ac text-center">
                <button
                  className="comn-btn bg-danger edit-btn-svg"
                  onClick={() => deleteFares(row.original.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              ) : // </td>
              null}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const columns2 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Booking Id",
        accessor: "id",
        Cell: ({ value, row }) => {
          return (
            <p
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() =>
                navigate("/booking/view-booking", {
                  state: { id: row.original.id },
                })
              }
            >
              {value}
            </p>
          );
        },
      },

      {
        Header: "User Name",
        accessor: "user.name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "driver.name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      // {
      //   Header: "Image",
      //   accessor: "img_url",
      //   Cell: ({ value, row }) => {
      //     return <img src={value} style={{ width: "80px", height: "50px" }} />;
      //   },
      // },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                style={{ margin: "0px 5px" }}
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() =>
                  navigate("/pushnotification/view", {
                    state: { data: row.original, type: "ride" },
                  })
                }
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const columns3 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "User Id",
        accessor: "id",
        Cell: ({ value, row }) => {
          return (
            <p
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() =>
                navigate("/user/view-profile", {
                  state: { id: row.original.id },
                })
              }
            >
              {value}
            </p>
          );
        },
      },

      {
        Header: "User Name",
        accessor: "user.name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {/* <button
                style={{ margin: "0px 5px" }}
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() =>
                  navigate("/pushnotification/view", {
                    state: {data: row.original,type:'search' },
                  })}
              >
                <FontAwesomeIcon icon={faEye} />
              </button> */}
              <td className="flex-ac text-center">
                {(row?.original?.first_sent_drivers == "[]" ||
                  row?.original?.first_sent_drivers == null ||
                  row?.original?.first_sent_drivers == "null") &&
                (row?.original?.first_sent_drivers == "[]" ||
                  row?.original?.first_sent_drivers == null ||
                  row?.original?.first_sent_drivers == "null") ? (
                  <p className="text-danger">Driver Not Found</p>
                ) : (
                  <button
                    style={{ margin: "0px 5px" }}
                    className="comn-btn bg-primary edit-btn-svg"
                    // onClick={() =>
                    // navigate("/pushnotification/view", {
                    //   state: { data: row.original, type: "search" },
                    // })
                    // }
                    onClick={() => apiUserView(row?.original?.id)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                )}
              </td>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const [list, setList] = useState([]);

  const deleteFares = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        notificationDelete(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };
  const apiUserView = (id) => {
    userView(id)
      .unwrap()
      .then((res) => {
        console.log(res);
        // setUserList(res?.active_users);
        // setListView();
        setFirstView(res?.first_sent_notifications);
        setSeacondView(res?.second_sent_notifications);
        setTriedView(res?.third_sent_notifications);
        setViewModel(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getView = () => {
    notificationView(viewId)
      .unwrap()
      .then((res) => {
        setEditModal(true);
        setType("view");
        console.log(res?.notification, "res");
        const data = res?.notification;
        setTitle(data?.title);
        setImage(res?.name);
        // setTypes(data?.)
        setDescription(data?.description);
        // setList(res?.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (viewId) {
      getView();
    }
  }, [viewId]);
  const notificyAdd = () => {
    if (
      title?.length == "" ||
      types?.length == "" ||
      description?.length == "" ||
      (types == "3"
        ? user?.length == ""
        : types == "4"
        ? driver?.length == ""
        : "")
    ) {
      setTitleErr(true);
      setTypesErr(true);
      setDescriptionErr(true);
      if (types == "3") {
        setUserErr(true);
      }
      if (types == "4") {
        setDriverErr(true);
      }
    } else {
      setBtn(true);
      dispatch(saveLoader(true));
      const formdata = new FormData();
      formdata.append("type", types);
      formdata.append("title", title);
      formdata.append("message", description);
      formdata.append("user_id", user);
      formdata.append("driver_id", driver);

      pushApi(formdata)
        .unwrap()
        .then((res) => {
          setAddModal(false);
          setBtn(false);
          dispatch(saveLoader(false));
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          getList();
          setTypes("");
          setDriver("");
          setUser("");
          setTitle("");
          setImage("");
          setDescription("");
        })
        .catch((err) => {
          console.log(err);
          setBtn(false);
          dispatch(saveLoader(false));
        });
    }
  };

  return (
    <aside>
      <TopHeader type="push-notification" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Vechile"
          id="uncontrolled-tab-example"
          className="mb-3"
          // onSelect={(e) => setActive(e)}
          fill
        >
          <Tab eventKey="Detail" title="pushnotification">
            <div className="p-2 bg-white rounded-2">
              {/* <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end">
                <div className="flex-ac-jc"> */}
              {/* {permissons?.includes("CAN-ADD-NOTIFICATION") ? ( */}
              <button
                onClick={() => setAddModal(true)}
                className="comn-btn bg-primary1 px-3 py-2"
                style={{
                  width: "150px",
                  margin: "auto 0 10px auto",
                  display: "block",
                }}
              >
                Add Notification
              </button>
              {/* ) : null} */}
              {/* </div>
              </div> */}
              <Table columns={columns} data={list} />
            </div>
          </Tab>
          <Tab eventKey="Vechile" title="Message">
            <div className="p-2 bg-white rounded-2">
              <Table columns={columns2} data={usernotificationList} />
            </div>
          </Tab>
          <Tab eventKey="message" title="Search Ride Notification">
            <div className="p-2 bg-white rounded-2">
              <Table columns={columns3} data={searchList} />
            </div>
          </Tab>
        </Tabs>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add PushNotification</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Tittle
            </label>
            <input
              type="title"
              id="example-if-email"
              name="title"
              placeholder="title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            {title?.length == "" && titleErr && (
              <p className="err-p">Enter Title </p>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Send To Type
            </label>
            <select
              name="type"
              onChange={(e) => setTypes(e.target.value)}
              value={types}
            >
              <option selected>select type</option>
              <option value={1}>All User</option>
              <option value={2}>All Drivers</option>
              <option value={3}>Single User</option>
              <option value={4}>Single Driver</option>
            </select>
            {types?.length == "" && typesErr && (
              <p className="err-p">Enter Types </p>
            )}
          </div>
          {types == "3" && (
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Send to User
              </label>
              <select
                name="type"
                onChange={(e) => setUser(e.target.value)}
                value={user}
              >
                <option selected>select type</option>
                {userList?.map((item) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {user?.length == "" && userErr && (
                <p className="err-p">Enter User </p>
              )}
            </div>
          )}
          {types == "4" && (
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Send To Driver
              </label>
              <select
                name="type"
                onChange={(e) => setDriver(e.target.value)}
                value={driver}
              >
                <option value={""}>select user</option>
                {driverList?.map((item, ind) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {driver?.length == "" && driverErr && (
                <p className="err-p">Enter Driver</p>
              )}
            </div>
          )}
          <div className="form-group col-12 col-sm-12 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Description
                </label>
                <textarea
                  placeholder="Description"
                  type="text"
                  className="bg-transparent"
                  cols="30"
                  rows="10"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                {description?.length == "" && descriptionErr && (
                  <p className="err-p">Enter Description</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setAddModal(false);
              setTitle("");
              setTypes("");
              setDescription("");
            }}
            className="comn-btn bg-hash2 px-3 py-2 mt-4 "
          >
            Cancel
          </button>

          <button
            type="button"
            className={`comn-btn bg-success px-3 py-2 mt-4 ms-2 ${
              btn && "opacity-50"
            }`}
            data-dismiss="modal"
            onClick={notificyAdd}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
      <Modal
        className="table-modal"
        show={EditModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>View PushNotification</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Tittle
            </label>
            <input
              type="title"
              id="example-if-email"
              name="title"
              placeholder="title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              disabled
            />
            {title?.length == "" && titleErr && (
              <p className="err-p">Enter Title </p>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Send To{" "}
            </label>
            <input value={image} disabled />
            {types?.length == "" && typesErr && (
              <p className="err-p">Enter Types </p>
            )}
          </div>
          {types == "3" && (
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Send to User
              </label>
              <select
                name="type"
                onChange={(e) => setUser(e.target.value)}
                value={user}
              >
                <option selected>select type</option>
                {userList?.map((item) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {user?.length == "" && userErr && (
                <p className="err-p">Enter User </p>
              )}
            </div>
          )}
          {types == "4" && (
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Send To Driver
              </label>
              <select
                name="type"
                onChange={(e) => setDriver(e.target.value)}
                value={driver}
              >
                <option value={""}>select user</option>
                {driverList?.map((item, ind) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {driver?.length == "" && driverErr && (
                <p className="err-p">Enter Driver</p>
              )}
            </div>
          )}
          <div className="form-group col-12 col-sm-12 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Description
                </label>
                <textarea
                  placeholder="Description"
                  type="text"
                  className="bg-transparent"
                  cols="30"
                  rows="10"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled
                ></textarea>
                {description?.length == "" && descriptionErr && (
                  <p className="err-p">Enter Description</p>
                )}
              </div>
            </div>
          </div>
          {/* <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Image
            </label>
            <input
              type="file"
              id="example-if-email"
              name="img"
              placeholder="img"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
            {image?.length == "" && imageErr && (
              <p className="err-p">Enter Image</p>
            )}
            {image ? (
              <div className="w-90 prev-Image">
                <img className="w-100" src={URL.createObjectURL(image)} />
              </div>
            ) : null}
          </div> */}
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setEditModal(false);
              setTitle("");
              setTypes("");
              setDescription("");
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal
        className="table-modal"
        show={viewModel}
        onHide={() => setViewModel(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div
          className="px-2 py-2 col-12 d-flex flex-wrap"
          style={{ overflowY: "scroll"}}
        >
          <div className="px-1 col-sm-12 modal-label-input">
            <h4 className="">First Send Driver</h4>
          </div>
          <div className="p-2 bg-white w-100 rounded-2 ">
            <TableView columns={columnsView} data={firstView} />
          </div>
          {secondView?.length > 0 ? (
            <>
              <div className="px-1 col-sm-12 modal-label-input">
                <h4>Second Send Driver</h4>
              </div>
              <div className="p-2 bg-white w-100 rounded-2 ">
                <TableView columns={columnsView2} data={secondView} />
              </div>
            </>
          ) : (
            ""
          )}
          {thierdView?.length > 0 ? (
            <>
              <div className="px-1 col-sm-12 modal-label-input">
                <h4>Third Send Driver</h4>
              </div>
              <div className="p-2 bg-white w-100 rounded-2 ">
                <TableView columns={columnsView3} data={thierdView} />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </aside>
  );
};

export default PushNotification;
