import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import useToken from "../../Storage/useToken";
import { BASE_URL, URL } from "./constants";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const currentUrl = window.location.href;
      if (
        localStorage.getItem("company_token") &&
        currentUrl.includes("company")
      ) {
        headers.set(
          "Authorization",
          "Bearer " + localStorage.getItem("company_token")
        );
      } else {
        headers.set("Authorization", "Bearer " + localStorage.getItem("token"));
      }
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["profileData", "menuList", "tableList"],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => ({
        url: URL.LOGOUT,
      }),
    }),

    // Change Password
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PASSWORD,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // ROLE LIST
    roleList: builder.query({
      query: () => ({
        url: URL.ROLE,
      }),
    }),

    // ROLE ADD
    roleAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // ROLE VIEW
    roleView: builder.query({
      query: ({ role_id }) => ({
        url: `${URL.ROLE}/${role_id}/edit`,
      }),
    }),

    // ROLE EDIT
    roleEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // // ROLE MODULE
    // roleModule: builder.query({
    //   query: () => ({
    //     url: URL.ROLE + "/create",
    //   }),
    // }), // ROLE MODULE

    roleCheckList: builder.query({
      query: () => ({
        url: URL.ROLECHECKLIST,
      }),
    }),

    // USERS LIST
    userList: builder.query({
      query: (param) => ({
        url: URL.USERS + param,
      }),
    }),
    // USERS LIST
    userList1: builder.query({
      query: () => ({
        url: URL.USERS ,
      }),
    }),

    // ROLE STATUS
    roleStatus: builder.query({
      query: (id) => ({
        url: URL.ROLE + "/status/" + id,
      }),
    }),

    // ROLE DELETE
    roleDelete: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // ADMIN LIST
    adminList: builder.query({
      query: () => ({
        url: URL.ADMINS,
      }),
    }),

    // ADMIN ADD
    adminAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADMINS,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // ADMIN EDIT
    adminEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ADMINS + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN VIEW
    adminView: builder.query({
      query: (id) => ({
        url: URL.ADMINS + "/" + id,
      }),
    }),

    // ADMIN STATUS
    adminStatus: builder.query({
      query: (id) => ({
        url: URL.ADMIN + "/status/" + id,
      }),
    }),

    // ADMIN ROLES
    adminRoles: builder.query({
      query: () => ({
        url: URL.ADMIN_ROLES,
      }),
    }),

    // ADMIN DELETE
    adminDelete: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ADMINS + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // DRIVER REUEST
    driverRequest: builder.query({
      query: (param) => ({
        url: URL.DRIVER_REQUSET + param,
      }),
    }),

    // DRIVER VIEW REQ & REJE
    driverRequestView: builder.query({
      query: (id) => ({
        url: URL.DRIVER_REQUSET_REJECT + "/view/" + id,
      }),
    }),

    // DRIVER PROOF ACCEPT
    driverAccept: builder.mutation({
      query: (payload) => ({
        url: URL.PROOF_ACCEPT,
        method: "POST",
        body: payload,
      }),
    }),

    // DRIVER LIST
    driverList: builder.query({
      query: (param) => ({
        url: URL.DRIVERS + param,
      }),
    }),

    // DRIVER STATUS
    driverStatus: builder.query({
      query: (id) => ({
        url: URL.DRIVER_STATUS + id,
      }),
    }),

    // RIDE TYPE LIST
    rideTypeList: builder.query({
      query: () => URL.RIDE_TYPE,
    }),

    // RIDE TYPE ADD
    rideTypeAdd: builder.mutation({
      query: (payload) => ({
        url: URL.RIDE_TYPE,
        method: "POST",
        body: payload,
      }),
    }),

    // RIDE TYPE EDIT
    rideTypeEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.RIDE_TYPE + "/update/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // RIDE TYPE VIEW
    rideTypeView: builder.query({
      query: (id) => URL.RIDE_TYPE + "/" + id,
    }),

    // RIDE TYPE DELETE
    rideTypeDelete: builder.mutation({
      query: (id) => ({
        url: URL.RIDE_TYPE + "/" + id,
        method: "DELETE",
      }),
    }),

    // RIDE TYPE STATUS
    rideTypeStatus: builder.query({
      query: (id) => "/status/" + id,
    }),
    // subscriptionList LIST
    subscriptionList: builder.query({
      query: () => ({
        url: URL.SUBSCRIPTIONS,
      }),
    }),

    // SUBSCRIPTION ADD
    subscriptionAdd: builder.mutation({
      query: (payload) => ({
        url: URL.SUBSCRIPTIONS,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // SUBSCRIPTION VIEW
    subscriptionView: builder.query({
      query: (id) => URL.SUBSCRIPTIONS + "/" + id,
    }),

    //  SUBSCRIPTION EDIT
    subscriptionEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.SUBSCRIPTIONS + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    //  SUBSCRIPTION DELETE
    subscriptionDelete: builder.mutation({
      query: (id) => ({
        url: URL.SUBSCRIPTIONS + "/" + id,
        method: "DELETE",
      }),
    }),

    // ADMIN STATUS
    subscriptionStatus: builder.query({
      query: (id) => ({
        url: URL.SUBSCRIPTION + "/status/" + id,
      }),
    }),

    // OTHER CHARGES ADD
    otherChargeAdd: builder.mutation({
      query: (payload) => ({
        url: URL.OTHERCHARGES,
        method: "POST",
        body: payload,
      }),
    }),

    // OTHER CHARGES EDIT
    otherChargeEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.OTHERCHARGES + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // OTHER CHARGES LIST
    otherChargeList: builder.query({
      query: () => URL.OTHERCHARGES,
    }),

    // OTHER CHARGES VIEW
    otherChargeView: builder.query({
      query: (id) => URL.OTHERCHARGES + "/" + id,
    }),

    // OTHER CHARGES DELETE
    otherChargeDelete: builder.mutation({
      query: (id) => ({
        url: URL.OTHERCHARGES + "/" + id,
        method: "DELETE",
      }),
    }),

    // FARE LIST
    fareList: builder.query({
      query: () => URL.FARE,
    }),

    // FARE VIEW
    fareView: builder.query({
      query: (id) => URL.FARE + "/" + id,
    }),

    // FARE ADD
    fareAdd: builder.mutation({
      query: (payload) => ({
        url: URL.FARE,
        method: "POST",
        body: payload,
      }),
    }),

    // FARE EDIT
    fareEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.FARE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // FARE DELETE
    fareDelete: builder.mutation({
      query: (id) => ({
        url: URL.FARE + "/" + id,
        method: "DELETE",
      }),
    }),

    // PEAK LIST
    peakList: builder.query({
      query: () => URL.PEAK,
    }),

    // PEAK VIEW
    peakView: builder.query({
      query: (id) => URL.PEAK + "/" + id,
    }),

    // PEAK ADD
    peakAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PEAK,
        method: "POST",
        body: payload,
      }),
    }),

    // PEAK EDIT
    peakEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PEAK + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // PEAK DELETE
    peakDelete: builder.mutation({
      query: (id) => ({
        url: URL.PEAK + "/" + id,
        method: "DELETE",
      }),
    }),

    //DRIVER LIST
    driverListRequest: builder.query({
      query: (params) => URL.DRIVER_LIST_REQUESTS + params,
    }),
    //DRIVER LIST
    driverListNew: builder.query({
      query: (params) => URL.DRIVER_LIST + params,
    }),
    //DRIVER VIEW
    driverViewRequest: builder.query({
      query: (id) => URL.DRIVER_LIST_VIEW + id,
    }),

    //STORE ADDED
    addedStore: builder.mutation({
      query: (payload) => ({
        url: URL.RIDE_STORE,
        method: "POST",
        body: payload,
      }),
    }),

    //STORE LIST
    listVehicle: builder.query({
      query: () => URL.RIDE_STORE,
    }),
    //View
    vehicleView: builder.query({
      query: (id) => URL.RIDE_STORE + "/" + id,
    }),
    //STORE EDITED
    editStore: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.RIDE_STORE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    //STORE DELETE
    DeleteStore: builder.mutation({
      query: (id ) => ({
        url: URL.RIDE_STORE + "/" + id,
        method: "DELETE",
      }),
    }),
    //CHARGES ADDED
    addedCharges: builder.mutation({
      query: (payload) => ({
        url: URL.OTHER_CHARGES,
        method: "POST",
        body: payload,
      }),
    }),

    //CHARGES LIST
    listCharges: builder.query({
      query: (params) => URL.OTHER_CHARGES + params,
    }),
    //CHARGES LIST
    chargesview: builder.query({
      query: (id) => URL.OTHER_CHARGES + "/" + id,
    }),
    //CHARGES delete
    deleteCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.OTHER_CHARGES + "/" + id,
        method: "DELETE",
        body: payload,
      }),
    }),
    //CHARGES update
    updatedCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.OTHER_CHARGES + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    //PEAK CHAREGHES LIST
    peakChargesList: builder.query({
      query: (params) => URL.PEAK_CHARGES + params,
    }),
    //PEAK CHAREGHES LIST
    peakChargesView: builder.query({
      query: (id) => URL.PEAK_CHARGES + "/" + id,
    }),
    //PEAK CHAREGHES ADDED
    peakChargesAdded: builder.mutation({
      query: (payload) => ({
        url: URL.PEAK_CHARGES,
        method: "POST",
        body: payload,
      }),
    }),
    //CHARGES update
    peakupdatedCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PEAK_CHARGES + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    deletePeakCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PEAK_CHARGES + "/" + id,
        method: "DELETE",
        body: payload,
      }),
    }),
    //DISTANCE
    //PEAK DISTANCE LIST
    distanceChargesList: builder.query({
      query: (params) => URL.DISTANCE + params,
    }),
    distanceChargesView: builder.query({
      query: (id) => URL.DISTANCE + "/" + id,
    }),
    //PEAK DISTANCE ADDED
    distanceChargesAdded: builder.mutation({
      query: (payload) => ({
        url: URL.DISTANCE,
        method: "POST",
        body: payload,
      }),
    }),
    //DISTANCE update
    distanceupdatedCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.DISTANCE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    deletedistanceCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.DISTANCE + "/" + id,
        method: "DELETE",
        body: payload,
      }),
    }),
    //PLACE
    addPlace: builder.mutation({
      query: (payload) => ({
        url: URL.PLACE,
        method: "POST",
        body: payload,
      }),
    }),

    listPlace: builder.query({
      query: () => URL.PLACE,
    }),
    UpdatePlace: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PLACE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    viewPlace: builder.query({
      query: (id) => URL.PLACE + "/" + id,
    }),
    deletePlaceCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PLACE + "/" + id,
        method: "DELETE",
        body: payload,
      }),
    }),

    //ZONES
    zonePlace: builder.mutation({
      query: (payload) => ({
        url: URL.ZONE,
        method: "POST",
        body: payload,
      }),
    }),
    zoneUpdatePlace: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ZONE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    viewZone: builder.query({
      query: (id) => URL.ZONE + "/" + id,
    }),
    listZone: builder.query({
      query: () => URL.ZONE,
    }),
    deleteZoneCharges: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ZONE + "/" + id,
        method: "DELETE",
        body: payload,
      }),
    }),

    // ADMIN OFFLINE
    companyList: builder.query({
      query: (params) => ({
        url: URL.OFFLINE + params,
      }),
    }),
    preBookingAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PREBOOKING,
        method: "POST",
        body: payload,
      }),
    }),
    driverAccpet: builder.query({
      query: () => ({
        url: URL.ACCEPT_DRIVER,
      }),
    }),
    firstSeachAdd: builder.mutation({
      query: (payload) => ({
        url: URL.FIRST_ACCEPT,
        method: "POST",
        body: payload,
      }),
    }),
    secondseachAdd: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.SECOND_ACCEPT + id,
        method: "POST",
        body: payload,
      }),
    }),
    searchRide: builder.mutation({
      query: (payload) => ({
        url: URL.SEARCH_RIDE_STATUS,
        method: "POST",
        body: payload,
      }),
    }),
    rideFare: builder.mutation({
      query: (payload) => ({
        url: URL.RIDEFARE,
        method: "POST",
        body: payload,
      }),
    }),
    offlineAcceptList: builder.query({
      query: (params) => ({
        url: URL.OFFLINE_ACCEPT_LIST + params,
      }),
    }),
    offlineAcceptView: builder.query({
      query: (id) => ({
        url: URL.OFFLINE_ACCEPT_VIEW + id,
      }),
    }),

    //SHARE CAB OFFLINE
    shareCabRideList: builder.mutation({
      query: (payload) => ({
        url: URL.SHARE_CAB_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    createShareCabRide: builder.mutation({
      query: (payload) => ({
        url: URL.CREATE_SHARE_CAB_RIDE,
        method: "POST",
        body: payload,
      }),
    }),
    editShareCabRide: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.EDIT_SHARE_CAB_RIDE + id,
        method: "POST",
        body: payload,
      }),
    }),
    viewShareCab: builder.query({
      query: (id) => ({
        url: URL.VIEW_SHARE_CAB + id,
      }),
    }),
    shareCabDriverList: builder.mutation({
      query: (payload) => ({
        url: URL.LIST_SHARE_CAB,
        method: "POST",
        body: payload,
      }),
    }),
    shareAvailableClick: builder.mutation({
      query: (payload) => ({
        url: URL.SEAT_AVAILABLE_CLICK,
        method: "POST",
        body: payload,
      }),
    }),
    shareCabPrice: builder.mutation({
      query: (payload) => ({
        url: URL.SHARE_CAB_PRICE,
        method: "POST",
        body: payload,
      }),
    }),
    adminSharecabRequest: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN_SHARECAB_RIDE,
        method: "POST",
        body: payload,
      }),
    }),

    //company
    companyListSetting: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    companyAdd: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_ADDED,
        method: "POST",
        body: payload,
      }),
    }),
    companyEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.COMPANY_EDIT + id,
        method: "POST",
        body: payload,
      }),
    }),
    companyView: builder.query({
      query: (id) => ({
        url: URL.COMPANY_VIEW + id,
      }),
    }),
    companyStatus: builder.query({
      query: (id) => ({
        url: URL.COMPANY_STATUS + id,
      }),
    }),
    companyDelete: builder.query({
      query: (id) => ({
        url: URL.COMPANY_DELETE + id,
      }),
    }),

    // COMPANY USER
    companyUserList: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_USER_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    companyUserAdd: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_USER_ADD,
        method: "POST",
        body: payload,
      }),
    }),
    companyUserEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.COMPANY_USER_EDIT + id,
        method: "POST",
        body: payload,
      }),
    }),
    companyUserStatus: builder.query({
      query: (id) => ({
        url: URL.COMPANY_USER_STATUS + id,
      }),
    }),
    companyUserView: builder.query({
      query: (id) => ({
        url: URL.COMPANY_USER_VIEW + id,
      }),
    }),
    companyUserDelete: builder.query({
      query: (id) => ({
        url: URL.COMPANY_USER_DELETE + id,
      }),
    }),
    UserView: builder.query({
      query: (id) => ({
        url: URL.USER_VIEW + id,
      }),
    }),

    marginList: builder.query({
      query: () => ({
        url: URL.MARGIN,
      }),
    }),
    marginView: builder.query({
      query: (id) => ({
        url: URL.MARGIN + "/" + id,
      }),
    }),
    marginAdd: builder.mutation({
      query: (payload) => ({
        url: URL.MARGIN,
        method: "POST",
        body: payload,
      }),
    }),
    marginEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.MARGIN + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    marginDelete: builder.mutation({
      query: (id) => ({
        url: URL.MARGIN + "/" + id,
        method: "DELETE",
      }),
    }),

    //PUSH NOTIFICATION
    pushNotification: builder.mutation({
      query: (payload) => ({
        url: URL.NOTIFICATION_ADD,
        method: "POST",
        body: payload,
      }),
    }),
    notificationList: builder.query({
      query: () => ({
        url: URL.NOTIFICATION_LIST,
      }),
    }),
    notificationView: builder.query({
      query: (id) => ({
        url: URL.NOTIFICATION_VIEW + id,
      }),
    }),
    notificationDelete: builder.query({
      query: (id) => ({
        url: URL.NOTIFICATION_DELETE + id,
      }),
    }),
    activeUserList: builder.query({
      query: () => ({
        url: URL.ACTIVE_USER_LIST,
      }),
    }),
    cancelOrder: builder.query({
      query: (params) => ({
        url: URL.CANCEL_ORDER + params,
      }),
    }),
    scheduleOrder: builder.query({
      query: (params) => ({
        url: URL.SCHEDULED_BOOKING_LIST + params,
      }),
    }),
    completedOrder: builder.query({
      query: (params) => ({
        url: URL.COMPLETED_BOOKING_LIST + params,
      }),
    }),
    ongoingOrder: builder.query({
      query: (params) => ({
        url: URL.ONGOING_BOOKING_LIST + params,
      }),
    }),

    //COUPONS
    couponsList: builder.mutation({
      query: (payload) => ({
        url: URL.COUPONS_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    couponsAdd: builder.mutation({
      query: (payload) => ({
        url: URL.COUPONS_CREATE,
        method: "POST",
        body: payload,
      }),
    }),
    couponsUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.COUPONS_UPDATE + id,
        method: "POST",
        body: payload,
      }),
    }),
    couponsDelete: builder.mutation({
      query: (id) => ({
        url: URL.COUPONS_DELETE + id,
        method: "POST",
      }),
    }),
    couponsView: builder.query({
      query: (id) => ({
        url: URL.COUPONS_VIEW + id,
      }),
    }),
    //REWARDS
    rewardsList: builder.mutation({
      query: () => ({
        url: URL.REWARDS_LIST,
        method: "GET",
      }),
    }),
    rewardsAdd: builder.mutation({
      query: (payload) => ({
        url: URL.REWARDS_CREATE,
        method: "POST",
        body: payload,
      }),
    }),
    rewardsUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.REWARDS_UPDATE + id,
        method: "POST",
        body: payload,
      }),
    }),
    rewardsDelete: builder.mutation({
      query: (id) => ({
        url: URL.REWARDS_DELETE + id,
        method: "DELETE",
      }),
    }),
    rewardsView: builder.query({
      query: (id) => ({
        url: URL.REWARDS_VIEW + id,
      }),
    }),

    //SUPPORT
    SupportList: builder.mutation({
      query: (payload) => ({
        url: URL.SUPPORT_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    SupportView: builder.query({
      query: (id) => ({
        url: URL.SUPPORT_VIEW + id,
      }),
    }),
    SupportReply: builder.mutation({
      query: (payload) => ({
        url: URL.SUPPORT_REPLY,
        method: "POST",
        body: payload,
      }),
    }),
    userRequestList: builder.mutation({
      query: (payload) => ({
        url: URL.USER_REQUEST_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    presetedList: builder.mutation({
      query: (payload) => ({
        url: URL.PRESETED_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    scheduleView: builder.query({
      query: (id) => ({
        url: URL.SCHEDULED_BOOKING_VIEW + id,
      }),
    }),
    userRideHistory: builder.query({
      query: (id) => ({
        url: URL.USER_RIDE_HISTORY + id,
      }),
    }),
    userRideHistoryView: builder.query({
      query: (id) => ({
        url: URL.USER_RIDE_HISTORY_VIEW + id,
      }),
    }),
    userRideHistoryStatus: builder.query({
      query: (id) => ({
        url: URL.USER_RIDE_HISTORY_STATUS + id,
      }),
    }),
    driverRideHistory: builder.query({
      query: (id) => ({
        url: URL.DRIVER_RIDE_HISTORY + id,
      }),
    }),
    driverRideHistoryView: builder.query({
      query: (id) => ({
        url: URL.DRIVER_RIDE_HISTORY_VIEW + id,
      }),
    }),
    //REFFER
    refferUserList: builder.query({
      query: (params) => ({
        url: URL.REFER_USER_LIST + params ,
      }),
    }),
    //REVENUE
    revenueSubscriptionList: builder.query({
      query: (params) => ({
        url: URL.REVENUE_SUBSCRIPTION + params ,
      }),
    }),
    revenueInhouseList: builder.query({
      query: (params) => ({
        url: URL.REVENUE_INHOUSE + params ,
      }),
    }),
    revenueSharecabList: builder.query({
      query: (params) => ({
        url: URL.REVENUE_SHARECAB + params ,
      }),
    }),
    reviewUserList: builder.query({
      query: (params) => ({
        url: URL.REVIEW_USERS  ,
      }),
    }),
    reviewDriverList: builder.query({
      query: (params) => ({
        url: URL.REVIEW_DRIVER  ,
      }),
    }),
    reportList: builder.query({
      query: (params) => ({
        url: URL.REPORT_LIST + params,
      }),
    }),
    exportReportList: builder.query({
      query: (params) => ({
        url: URL.REPORT_EXCEL + params,
      }),
    }),
    shareCabRideHistoryList: builder.query({
      query: (Id) => ({
        url: URL.DRIVER_SHARE_CAB_RIDE_HISTORY + Id,
      }),
    }),
    companyDriverUpdate: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_DRIVER_UPDATE ,
        method: "POST",
        body: payload,
      }),
    }),
    //DASHBOARD
    DashBoardOverallList: builder.query({
      query: () => ({
        url: URL.DASHBOARD_OVERALL ,
      }),
    }),
    DashBoardPieList: builder.query({
      query: () => ({
        url: URL.DASHBOARD_PERCENTAGE ,
      }),
    }),
    DashBoardRevenueList: builder.query({
      query: (params) => ({
        url: URL.DASHBOARD_REVENUE + params,
      }),
    }),
    DashBoardPayTaxList: builder.query({
      query: (params) => ({
        url: URL.DASHBOAD_PAY_TAX_GRAGH +params,
      }),
    }),
    DashBoardSubScriptionList: builder.query({
      query: (params) => ({
        url: URL.DASHBOAD_SUBSCRIPTION +params,
      }),
    }),
    BooingInvoice: builder.query({
      query: (id) => ({
        url: URL.BOOKING_INVOICE + id,
      }),
    }),
    UserDriverNotificationList: builder.query({
      query: () => ({
        url: URL.USER_DRIVER_NOTIFICATION_LIST ,
      }),
    }),
    UserDriverNotificationView: builder.query({
      query: (id) => ({
        url: URL.USER_DRIVER_NOTIFICATION_View + id,
      }),
    }),
    rideSearchView: builder.query({
      query: (id) => ({
        url: URL.RIDE_SEARCH_NOTIFICTION + id,
      }),
    }),
//--------------------------------------------------------------------------------------------------------------------------//
    //COMPANY LOGIN
    companyLogin: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_LOGIN,
        method: "POST",
        body: payload,
      }),
    }),
    companyLogout: builder.query({
      query: () => ({
        url: URL.COMPANY_LOGOUT,
      }),
    }),
    companyChangePassword: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_CHANGE_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),
    //COMPANY OFFLINE
    companyOfflineList: builder.query({
      query: (params) => ({
        url: URL.OFFLINE_COMPANY + params,
      }),
    }),
    companypreBookingAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PREBOOKING_COMPANY,
        method: "POST",
        body: payload,
      }),
    }),
    companyDriverAccpet: builder.query({
      query: () => ({
        url: URL.ACCEPT_DRIVER_COMPANY,
      }),
    }),
    companyfirstSeachAdd: builder.mutation({
      query: (payload) => ({
        url: URL.FIRST_ACCEPT_COMPANY,
        method: "POST",
        body: payload,
      }),
    }),
    CompanySecondseachAdd: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.SECOND_ACCEPT_COMPANY + id,
        method: "POST",
        body: payload,
      }),
    }),
    companySearchRide: builder.mutation({
      query: (payload) => ({
        url: URL.SEARCH_RIDE_STATUS_COMPANY,
        method: "POST",
        body: payload,
      }),
    }),
    companyRideFare: builder.mutation({
      query: (payload) => ({
        url: URL.RIDEFARE_COMPANY,
        method: "POST",
        body: payload,
      }),
    }),
    companyOfflineAcceptList: builder.query({
      query: (params) => ({
        url: URL.OFFLINE_ACCEPT_LIST_COMPANY + params,
      }),
    }),
    companyofflineAcceptView: builder.query({
      query: (id) => ({
        url: URL.OFFLINE_ACCEPT_VIEW_COMPANY + id,
      }),
    }),
    companyRideFare: builder.mutation({
      query: (payload) => ({
        url: URL.RIDEFARE_COMPANY,
        method: "POST",
        body: payload,
      }),
    }),

    //DRIVER
    companydriverAcceptOffline: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_ACCEPT_DRIVER,
        method: "POST",
        body: payload,
      }),
    }),
    //USER
    companyService: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_SERVICE,
        method: "POST",
        body: payload,
      }),
    }),
    //SHARE_CAB
    CompanyShareCabDriverList: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_LIST_SHARE_CAB,
        method: "POST",
        body: payload,
      }),
    }),
    CompanyCreateShareCabRide: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_CREATE_SHARE_CAB_RIDE,
        method: "POST",
        body: payload,
      }),
    }),
    CompanyEditShareCabRide: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.COMPANY_EDIT_SHARE_CAB_RIDE + id,
        method: "POST",
        body: payload,
      }),
    }),
    CompanyViewShareCab: builder.query({
      query: (id) => ({
        url: URL.COMPANY_VIEW_SHARE_CAB + id,
      }),
    }),
    CompanyShareAvailableClick: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_SEAT_AVAILABLE_CLICK,
        method: "POST",
        body: payload,
      }),
    }),
    CompanyShareCabPrice: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_SHARE_CAB_PRICE,
        method: "POST",
        body: payload,
      }),
    }),
    CompanyAdminSharecabRequest: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_COMPANY_ADMIN_SHARECAB_RIDE,
        method: "POST",
        body: payload,
      }),
    }),
    CompanyShareCabRideList: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_SHARE_CAB_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    BookingHistoryList: builder.mutation({
      query: () => ({
        url: URL.COMPANY_BOOKING_HISTORY_LIST,
        method: "GET",
        // body: payload,
      }),
    }),
    BookingHistoryCompletedList: builder.mutation({
      query: () => ({
        url: URL.COMPANY_BOOKING_HISTORY_COMPLETED,
        method: "GET",
        // body: payload,
      }),
    }),
    CompanyBookingHistoryView: builder.mutation({
      query: (Id) => ({
        url: URL.COMPANY_BOOKING_HISTORY_VIEW + Id,
        method: "GET",
      }),
    }),
    companyCancelList: builder.query({
      query: (params) => ({
        url: URL.COMPANY_CANCEL_BOOKING_LIST + params,
      }),
    }),
    companyScheduleBookingList: builder.query({
      query: (params) => ({
        url: URL.COMPANY_SCHEDULE_BOOKING_LIST + params,
      }),
    }),
    CompanyshareCabPresetListApi: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_PRESETED_RIDE_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    CompanyDriverList: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_DRIVER_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    companyProfileViewApi: builder.query({
      query: (id) => ({
        url: URL.COMPANY_PROFILE_VIEW + id,
      }),
    }),
    companyRideHistoryApi: builder.query({
      query: (id) => ({
        url: URL.COMPANY_RIDE_HISTORY + id,
      }),
    }),
    companyshareCabRideHistoryList: builder.query({
      query: (Id) => ({
        url: URL.COMPANY_DRIVER_SHARE_CAB_RIDE_HISTORY + Id,
      }),
    }),
    companyRevenueSubscriptionList: builder.query({
      query: (params) => ({
        url: URL.COMPANY_REVENUE_SUBSCRIPTION + params,
      }),
    }),
    companyRevenueShareList: builder.query({
      query: (params) => ({
        url: URL.COMPANY_SHARECAB_LIST + params,
      }),
    }),
    companyRevenueInhouseList: builder.query({
      query: (params) => ({
        url: URL.COMPANY_INHOUSE_REVENUE + params,
      }),
    }),
    scheduleViewCompany: builder.query({
      query: (id) => ({
        url: URL.COMPANY_SCHEDULE_BOOKING_VIEW + id,
      }),
    }),
    //dashboard
    companyDashboardDriverCount: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_DASHBOARD_OVERALL ,
        method:"POST",
        body:payload,
      }),
    }),
    companyDashboardPercentageList: builder.query({
      query: () => ({
        url: URL.COMPANY_DASHBOARD_PERCENTAGE  ,
      }),
    }),
    companyDashboardRevenue: builder.query({
      query: () => ({
        url: URL.COMPANY_DASHBOARD_REVENUE ,
      }),
    }),
    companyDashboardPayTax: builder.query({
      query: () => ({
        url: URL.COMPANY_DASHBOAD_PAY_TAX_GRAGH ,
      }),
    }),
    companyDashboardSubsRevenue: builder.query({
      query: () => ({
        url: URL.COMPANY_SUBS_REVENUE_GRAPH ,
      }),
    }),
  
  }),
});

export const {
  useLoginMutation,
  useLazyLogoutQuery,
  useChangePasswordMutation,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminListQuery,
  useLazyAdminViewQuery,
  useLazyAdminStatusQuery,
  useAdminDeleteMutation,
  useRoleAddMutation,
  useLazyRoleCheckListQuery,
  useLazyRoleListQuery,
  useLazyRoleViewQuery,
  useRoleDeleteMutation,
  useLazyRoleStatusQuery,
  useRoleEditMutation,
  useLazyAdminRolesQuery,
  useLazyDriverRequestQuery,
  useLazyDriverRequestViewQuery,
  useDriverAcceptMutation,
  useLazyDriverListQuery,
  useLazyDriverStatusQuery,
  useRideTypeAddMutation,
  useRideTypeEditMutation,
  useRideTypeDeleteMutation,
  useLazyRideTypeListQuery,
  useLazyRideTypeViewQuery,
  useLazyRideTypeStatusQuery,
  useOtherChargeAddMutation,
  useOtherChargeEditMutation,
  useLazyOtherChargeListQuery,
  useLazyFareListQuery,
  useLazyFareViewQuery,
  useFareAddMutation,
  useFareEditMutation,
  useLazyPeakListQuery,
  useLazyPeakViewQuery,
  usePeakAddMutation,
  usePeakEditMutation,
  useLazyOtherChargeViewQuery,
  useOtherChargeDeleteMutation,
  useFareDeleteMutation,
  usePeakDeleteMutation,
  useLazyUserList1Query,
  useLazyUserListQuery,
  useLazySubscriptionListQuery,
  useSubscriptionAddMutation,
  useLazySubscriptionViewQuery,
  useSubscriptionEditMutation,
  useLazySubscriptionStatusQuery,
  useLazyDriverListRequestQuery,
  useLazyDriverViewRequestQuery,
  useLazyDriverListNewQuery,
  useAddedStoreMutation,
  useLazyListVehicleQuery,
  useLazyVehicleViewQuery,
  useEditStoreMutation,
  useAddedChargesMutation,
  useLazyListChargesQuery,
  useDeleteChargesMutation,
  useLazyChargesviewQuery,
  useUpdatedChargesMutation,
  useLazyPeakChargesListQuery,
  usePeakChargesAddedMutation,
  usePeakupdatedChargesMutation,
  useLazyDistanceChargesListQuery,
  useDistanceChargesAddedMutation,
  useDistanceupdatedChargesMutation,
  useDeletePeakChargesMutation,
  useDeletedistanceChargesMutation,
  useLazyDistanceChargesViewQuery,
  useLazyPeakChargesViewQuery,
  useAddPlaceMutation,
  useLazyListPlaceQuery,
  useZonePlaceMutation,
  useLazyListZoneQuery,
  useDeletePlaceChargesMutation,
  useDeleteZoneChargesMutation,
  useZoneUpdatePlaceMutation,
  useLazyViewZoneQuery,
  useLazyViewPlaceQuery,
  useUpdatePlaceMutation,
  useLazyCompanyListQuery,
  usePreBookingAddMutation,
  useLazyDriverAccpetQuery,
  useFirstSeachAddMutation,
  useSearchRideMutation,
  useSecondseachAddMutation,
  useRideFareMutation,
  useCreateShareCabAdminMutation,
  useShareCabRideListMutation,
  useCompanyListSettingMutation,
  useCompanyAddMutation,
  useCompanyEditMutation,
  useLazyCompanyStatusQuery,
  useLazyCompanyViewQuery,
  useLazyCompanyDeleteQuery,
  useCompanyUserListMutation,
  useCompanyUserAddMutation,
  useCompanyUserEditMutation,
  useLazyCompanyUserDeleteQuery,
  useLazyCompanyUserStatusQuery,
  useLazyCompanyUserViewQuery,
  useLazyUserViewQuery,
  useLazyOfflineAcceptListQuery,
  useLazyOfflineAcceptViewQuery,
  useLazyMarginListQuery,
  useMarginAddMutation,
  useMarginDeleteMutation,
  useMarginEditMutation,
  useLazyMarginViewQuery,
  useCompanyServiceMutation,
  useEditShareCabRideMutation,
  useCreateShareCabRideMutation,
  useShareCabDriverListMutation,
  useLazyViewShareCabQuery,
  useShareAvailableClickMutation,
  useShareCabPriceMutation,
  useAdminSharecabRequestMutation,
  useLazyNotificationDeleteQuery,
  useLazyNotificationListQuery,
  useLazyNotificationViewQuery,
  usePushNotificationMutation,
  useLazyActiveUserListQuery,
  useLazyCancelOrderQuery,
  useLazyScheduleOrderQuery,
  useLazyCompletedOrderQuery,
  useLazyOngoingOrderQuery,
  useCouponsListMutation,
  useCouponsAddMutation,
  useCouponsDeleteMutation,
  useCouponsUpdateMutation,
  useLazyCouponsViewQuery,
  useRewardsAddMutation,
  useRewardsDeleteMutation,
  useRewardsListMutation,
  useRewardsUpdateMutation,
  useLazyRewardsViewQuery,
  useLazySupportViewQuery,
  useSupportListMutation,
  useSupportReplyMutation,
  usePresetedListMutation,
  useUserRequestListMutation,
  useLazyScheduleViewQuery,
  useLazyUserRideHistoryQuery,
  useLazyUserRideHistoryViewQuery,
  useLazyUserRideHistoryStatusQuery,
  useLazyDriverRideHistoryQuery,
  useLazyDriverRideHistoryViewQuery,
  useLazyRefferUserListQuery,
  useLazyRevenueInhouseListQuery,
  useLazyRevenueSharecabListQuery,
  useLazyRevenueSubscriptionListQuery,
  useLazyReviewDriverListQuery,
  useLazyReviewUserListQuery,
  useLazyReportListQuery,
  useLazyExportReportListQuery,
  useLazyShareCabRideHistoryListQuery,
  useCompanyDriverUpdateMutation,
  useLazyDashBoardOverallListQuery,
  useLazyDashBoardPieListQuery,
  useLazyDashBoardPayTaxListQuery,
  useLazyDashBoardRevenueListQuery,
  useLazyDashBoardSubScriptionListQuery,
  useLazyBooingInvoiceQuery,
  useLazyUserDriverNotificationListQuery,
  useLazyUserDriverNotificationViewQuery,
  useLazyRideSearchViewQuery,
  useDeleteStoreMutation,
  //----------------------------------------------------------//
  //Company
  useCompanyChangePasswordMutation,
  useCompanyLoginMutation,
  useLazyCompanyLogoutQuery,
  useLazyCompanyOfflineListQuery,
  useLazyCompanyOfflineAcceptListQuery,
  useLazyCompanyofflineAcceptViewQuery,
  useCompanydriverAcceptOfflineMutation,
  useCompanypreBookingAddMutation,
  useCompanyfirstSeachAddMutation,
  useCompanySecondseachAddMutation,
  useCompanySearchRideMutation,
  useCompanyRideFareMutation,
  useCompanyShareCabDriverListMutation,
  useCompanyCreateShareCabRideMutation,
  useCompanyEditShareCabRideMutation,
  useLazyCompanyViewShareCabQuery,
  useCompanyAdminSharecabRequestMutation,
  useCompanyShareCabPriceMutation,
  useCompanyShareAvailableClickMutation,
  useCompanyShareCabRideListMutation,
  useBookingHistoryCompletedListMutation,
  useBookingHistoryListMutation,
  useCompanyBookingHistoryViewMutation,
  useLazyCompanyCancelListQuery,
  useLazyCompanyScheduleBookingListQuery,
  useCompanyshareCabPresetListApiMutation,
  useCompanyDriverListMutation,
  useLazyCompanyProfileViewApiQuery,
  useLazyCompanyRideHistoryApiQuery,
  useLazyCompanyshareCabRideHistoryListQuery,
  useLazyCompanyRevenueShareListQuery,
  useLazyCompanyRevenueInhouseListQuery,
  useLazyCompanyRevenueSubscriptionListQuery,
  useLazyScheduleViewCompanyQuery,
  useCompanyDashboardDriverCountMutation,
  useLazyCompanyDashboardPercentageListQuery,
  useLazyCompanyDashboardPayTaxQuery,
  useLazyCompanyDashboardRevenueQuery,
  useLazyCompanyDashboardSubsRevenueQuery,
} = api;
