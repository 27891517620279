import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";
import { useDispatch } from "react-redux";
import {
  useAddedChargesMutation,
  useDistanceChargesAddedMutation,
  useDistanceupdatedChargesMutation,
  useFareAddMutation,
  useFareEditMutation,
  useLazyChargesviewQuery,
  useLazyDistanceChargesViewQuery,
  useLazyFareViewQuery,
  useLazyOtherChargeViewQuery,
  useOtherChargeAddMutation,
  useOtherChargeEditMutation,
  usePeakChargesAddedMutation,
  usePeakupdatedChargesMutation,
  useUpdatedChargesMutation,
} from "../../../constants/redux/api/api";

const AddFareSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.ride_id;
  const type = location?.state?.type;
  const id = location?.state?.id;

  console.log("data", data);

  const [base, setBase] = useState([]);
  const [distance, setDistance] = useState([]);
  const [wait, setWait] = useState([]);
  const [peak, setPeak] = useState([]);
  const [night, setNigt] = useState([]);
  const [btn, setBtn] = useState(false);
  const [btnTo, setBtnTo] = useState("");

  const [time, timeKm] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [km, setKm] = useState("");
  const [price, setPrice] = useState("");
  const [chargesforErr, setChareforErr] = useState(false);
  const [chargesfor, setCharefor] = useState("");
  const [kmErr, setKmErr] = useState(false);
  const [timeErr, setTimeErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [fromErr, setFromErr] = useState(false);
  const [toErr, setToErr] = useState(false);
  const [types, setTypes] = useState("");
  const [typesErr, setTypesErr] = useState(false);
  const [round, setRound] = useState("");
  const [roundErr, setRoundErr] = useState(false);
  // HANDLE CHANGE EVENT

  const handleChangeBase = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setBase((val) => ({ ...val, [name]: value }));
  };

  const handleChangeDistance = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setDistance((val) => ({ ...val, [name]: value }));
  };

  const handleChangeWaiting = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setWait((val) => ({ ...val, [name]: value }));
  };

  const handleChangePeak = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPeak((val) => ({ ...val, [name]: value }));
  };

  const handleChangeNight = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNigt((val) => ({ ...val, [name]: value }));
  };

  const [addChargesApi] = useAddedChargesMutation();
  const [viewCharesApi] = useLazyChargesviewQuery();
  const [updateCharesApi] = useUpdatedChargesMutation();
  const [distanceAddApi] = useDistanceChargesAddedMutation();
  const [peakdistanceUpdateApi] = useDistanceupdatedChargesMutation();
  const [distanceViewApi] = useLazyDistanceChargesViewQuery();
  const viewApi = () => {
    viewCharesApi(id)
      .unwrap()
      .then((res) => {
        console.log(res?.other_charge);
        const data = res?.other_charge;
        setKm(data?.min_km_time);
        timeKm(data?.min_km_time);
        setPrice(data?.amount);
        setRound(data?.amount)
        setCharefor(data?.charge_for);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (id && type != "editBaseFare") {
      viewApi();
    }
  }, []);
  const viewDistanceApi = () => {
    distanceViewApi(id)
      .unwrap()
      .then((res) => {
        console.log(res);
        const data = res?.price;
        setFrom(data?.from);
        setTo(data?.to);
        setPrice(data?.amount);
       
        setCharefor(data?.charge_for);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (id && type == "editBaseFare") {
      viewDistanceApi();
    }
  }, []);
  const handelAdd = () => {
    if (km?.length == "" || price?.length == "" || chargesfor?.length == "") {
      setKmErr(true);
      setPriceErr(true);
      setChareforErr(true);
    } else {
      setBtn(true);
      dispatch(saveLoader(true));
      const formData = new FormData();
      formData.append("charge_for", chargesfor);
      // formData.append("charge_for ", chargesfor);
      formData.append("ride_id", data?.id);
      formData.append("min_km_time", km);
      formData.append("amount", price);
      formData.append("type", "1");
      if (type == "editDistanceFare") {
        formData.append("_method", "PUT");
        updateCharesApi({ payload: formData, id: id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit", { state: { data: data } });
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log(err);
            dispatch(saveLoader(false));
            setBtn(false);
          });
      } else {
        setBtn(true);
        dispatch(saveLoader(true));
        addChargesApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit", { state: { data: data } });
            dispatch(saveLoader(false));
            setBtn(false);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            console.log(err);
            setBtn(false);
          });
      }
    }
  };
  const waitingAdd = () => {
    console.log(data);
    if (time?.length == "" || chargesfor?.length == "" || price?.length == "") {
      setTimeErr(true);
      setChareforErr(true);
      setPriceErr(true);
    } else {
      const formData = new FormData();
      formData.append("charge_for", chargesfor);
      // formData.append("charge_for ", chargesfor);
      formData.append("ride_id", data?.id);
      formData.append("min_km_time", time);

      formData.append("amount", price);

      formData.append("type", "2");
      if (type == "editWaitingFare") {
        setBtn(true);
        dispatch(saveLoader(true));
        formData.append("_method", "PUT");
        updateCharesApi({ payload: formData, id: id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit", { state: { tab: "Tax", data: data } });
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
            dispatch(saveLoader(false));
          });
      } else {
        setBtn(true);
        dispatch(saveLoader(true));
        addChargesApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit", { state: { tab: "Tax", data: data } });
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
            dispatch(saveLoader(false));
          });
      }
    }
  };
  const RoundAdd = () => {
    console.log(data);
    if (time?.length == "" || chargesfor?.length == "" || round?.length == "") {
      setTimeErr(true);

      setChareforErr(true);

      setRoundErr(true);
    } else {
      setBtn(true);
      dispatch(saveLoader(true));
      const formData = new FormData();
      formData.append("charge_for", chargesfor);
      // formData.append("charge_for ", chargesfor);
      formData.append("ride_id", data?.id);
      formData.append("min_km_time", time);

      formData.append("amount", round);

      formData.append("type", "3");
      if (type == "editRoundFare") {
        formData.append("_method", "PUT");
        updateCharesApi({ payload: formData, id: id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit", { state: { tab: "Round", data: data } });
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
            dispatch(saveLoader(false));
          });
      } else {
        setBtn(true);
        dispatch(saveLoader(true));
        addChargesApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit", { state: { tab: "Round", data: data } });
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
            dispatch(saveLoader(false));
          });
      }
    }
  };
  const peakAdd = () => {
    // console.log(data);
    if (from?.length == "" || price?.length == "" || chargesfor?.length == "") {
      setToErr(true);
      setFromErr(true);
      setPriceErr(true);
      setChareforErr(true);
    } else {
      dispatch(saveLoader(true));
      const formData = new FormData();
      formData.append("charge_for", chargesfor);
      formData.append("ride_id", data?.id);
      formData.append("from", from);
      if (to) {
        formData.append("to", to);
      }
      formData.append("amount", price);
      if (type == "editBaseFare") {
        formData.append("_method", "PUT");
        setBtn(true);
        peakdistanceUpdateApi({ payload: formData, id: id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            navigate("/vehicle-edit", { state: { tab: "Detail", data: data } });
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
            dispatch(saveLoader(false));
          });
      } else {
        setBtn(true);
        dispatch(saveLoader(true));
        distanceAddApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/vehicle-edit", { state: { tab: "Detail", data: data } });
          })
          .catch((err) => {
            console.log(err);
            setBtn(false);
            dispatch(saveLoader(false));
          });
      }
    }
  };

  return (
    <>
      <aside>
        {data?.id && type === "edit" ? (
          <TopHeader type="editFare" data={data}/>
        ) : data?.id ? (
          <TopHeader type="addFare" data={data}/>
        ) :""}

        <article>
          {type == "addBaseFare" || type == "editBaseFare" ? (
            <>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="dist-fare d-flex justify-content-between">
                    <label className="permission-text txt-primary4 text-uppercase">
                      Distance Fare
                    </label>
                    <span className="">
                      Note: You Should Give One Higher From Km without To Km
                    </span>
                  </div>
                  <Form className="d-flex flex-wrap">
                    {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Fare Plan Name
                          </label>
                          <input
                            id="example-if-email"
                            name="title"
                            placeholder="fare plan name"
                            className="bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            From
                          </label>
                          <input
                            id="example-if-email"
                            name="from"
                            placeholder="From"
                            className="bg-transparent"
                            onChange={(e) => setFrom(e.target.value)}
                            value={from}
                            type="text"
                          />
                          {from?.length == 0 && fromErr && (
                            <p className="err-p">Enter From</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            To
                          </label>
                          <input
                            placeholder="To"
                            name="to"
                            className="bg-transparent"
                            type="text"
                            onChange={(e) => setTo(e.target.value)}
                            value={to}
                            disabled={btnTo == null ? true : false}
                          />
                          {/* {to?.length == 0 && toErr && (
                            <p className="err-p">Enter To</p>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Price
                          </label>
                          <input
                            placeholder="price"
                            name="amount"
                            className="bg-transparent"
                            type="text"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                          />
                          {price?.length == 0 && priceErr && (
                            <p className="err-p">Enter Charges</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Charges For
                          </label>

                          <select
                            className="bg-transparent"
                            onChange={(e) => setCharefor(e.target.value)}
                            value={chargesfor}
                          >
                            <option value={""}>Select Charges</option>
                            <option value={"1"}>Intercity</option>
                            <option value={"2"}>Intracity</option>
                            <option value={"3"}>sharecab</option>
                          </select>
                          {chargesfor?.length == 0 && chargesforErr && (
                            <p className="err-p">Enter Charges</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className={`comn-btn bg-primary px-3 py-2 me-2${
                        btn && "opacity-50"
                      }`}
                      onClick={peakAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {type == "addDistanceFare" || type == "editDistanceFare" ? (
            <>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      Base Fare
                    </p>
                  </div>

                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            KM
                          </label>
                          <input
                            id="example-if-email"
                            name="min_km_time"
                            placeholder="km"
                            className="bg-transparent"
                            type="text"
                            onChange={(e) => setKm(e.target.value)}
                            value={km}
                          />
                          {km?.length == 0 && kmErr && (
                            <p className="err-p">Enter Km</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Price
                          </label>
                          <input
                            placeholder="amount"
                            name="amount"
                            className="bg-transparent"
                            type="text"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                          />
                          {price?.length == 0 && priceErr && (
                            <p className="err-p">Enter Price</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Charges For
                          </label>

                          <select
                            className="bg-transparent"
                            onChange={(e) => setCharefor(e.target.value)}
                            value={chargesfor}
                          >
                            <option value={""}>Select Charges</option>
                            <option value={"1"}>Intercity</option>
                            <option value={"2"}>Intracity</option>
                            <option value={"3"}>sharecab</option>
                          </select>
                          {chargesfor?.length == 0 && chargesforErr && (
                            <p className="err-p">Enter Charges</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className={`comn-btn bg-primary px-3 py-2 me-2${
                        btn && "opacity-50"
                      }`}
                      onClick={handelAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {type == "addWaitingFare" || type == "editWaitingFare" ? (
            <>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      Waiting Price
                    </p>
                  </div>
                  <Form className="d-flex flex-wrap">
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Type
                          </label>

                          <select
                            className="bg-transparent"
                            onChange={(e) => setTypes(e.target.value)}
                            value={types}
                          >
                            <option value={""}>Select Type</option>
                            <option value={"2"}>Waiting Price</option>
                            <option value={"3"}>Round Price</option>
                          </select>
                          {types?.length == 0 && typesErr && (
                            <p className="err-p">Enter Types</p>
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Minutes
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="Minutes"
                            name="min_km_time"
                            className="bg-transparent"
                            onChange={(e) => timeKm(e.target.value)}
                            value={time}
                          />
                          {time?.length == 0 && timeErr && (
                            <p className="err-p">Enter Time</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Waiting Price
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="Waiting Price"
                            name="amount"
                            className="bg-transparent"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                          />
                          {price?.length == 0 && priceErr && (
                            <p className="err-p">Enter Price</p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* {types == "3" && (
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Round Price
                            </label>
                            <input
                              type="text"
                              id="example-if-email"
                              placeholder="Round Price"
                              name="amount"
                              className="bg-transparent"
                              onChange={(e) => setRound(e.target.value)}
                              value={round}
                            />
                            {round?.length == 0 && roundErr && (
                              <p className="err-p">Enter Round Price</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )} */}
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Charges For
                          </label>

                          <select
                            className="bg-transparent"
                            onChange={(e) => setCharefor(e.target.value)}
                            value={chargesfor}
                          >
                            <option value={""}>Select Charges</option>
                            <option value={"1"}>Intercity</option>
                            <option value={"2"}>Intracity</option>
                            <option value={"3"}>sharecab</option>
                          </select>
                          {chargesfor?.length == 0 && chargesforErr && (
                            <p className="err-p">Enter Charges</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>

                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className={`comn-btn bg-primary px-3 py-2 me-2${
                        btn && "opacity-50"
                      }`}
                      onClick={waitingAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {type == "addRoundFare" || type == "editRoundFare" ? (
            <>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      Round Price
                    </p>
                  </div>
                  <Form className="d-flex flex-wrap">
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Type
                          </label>

                          <select
                            className="bg-transparent"
                            onChange={(e) => setTypes(e.target.value)}
                            value={types}
                          >
                            <option value={""}>Select Type</option>
                            <option value={"2"}>Waiting Price</option>
                            <option value={"3"}>Round Price</option>
                          </select>
                          {types?.length == 0 && typesErr && (
                            <p className="err-p">Enter Types</p>
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Minutes
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="Minutes"
                            name="min_km_time"
                            className="bg-transparent"
                            onChange={(e) => timeKm(e.target.value)}
                            value={time}
                          />
                          {time?.length == 0 && timeErr && (
                            <p className="err-p">Enter Time</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* {types == "2" && (
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Waiting Price
                            </label>
                            <input
                              type="text"
                              id="example-if-email"
                              placeholder="Waiting Price"
                              name="amount"
                              className="bg-transparent"
                              onChange={(e) => setPrice(e.target.value)}
                              value={price}
                            />
                            {price?.length == 0 && priceErr && (
                              <p className="err-p">Enter Price</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )} */}
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Round Price
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="Round Price"
                            name="amount"
                            className="bg-transparent"
                            onChange={(e) => setRound(e.target.value)}
                            value={round}
                          />
                          {round?.length == 0 && roundErr && (
                            <p className="err-p">Enter Round Price</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Charges For
                          </label>

                          <select
                            className="bg-transparent"
                            onChange={(e) => setCharefor(e.target.value)}
                            value={chargesfor}
                          >
                            <option value={""}>Select Charges</option>
                            <option value={"1"}>Intercity</option>
                            <option value={"2"}>Intracity</option>
                          </select>
                          {chargesfor?.length == 0 && chargesforErr && (
                            <p className="err-p">Enter Charges</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>

                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className={`comn-btn bg-primary px-3 py-2 me-2${
                        btn && "opacity-50"
                      }`}
                      onClick={RoundAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {/* {type == "addPeakFare" || type == "editPeak" ? (
            <>
              {(lg_fairtype === "Peak" || lg_fairtype == "All") && (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <label>Peak Fare test</label>
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Start Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="start time"
                              name="from_time"
                              className="bg-transparent"
                              onChange={handleChangePeak}
                              value={peak?.from_time}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              End Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="end time"
                              name="to_time"
                              onChange={handleChangePeak}
                              value={peak?.to_time}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Peak Fare
                            </label>
                            <input
                              type="text"
                              id="example-if-email"
                              placeholder="peak fare"
                              name="percentage"
                              onChange={handleChangePeak}
                              value={peak?.percentage}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                      <button
                        className="comn-btn bg-primary px-3 py-2 me-2"
                        onClick={peakAdd}
                        disabled={btn ? true : false}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {(lg_fairtype === "Night" || lg_fairtype === "All") && (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <label>Night Fare</label>
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Start Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="start time"
                              name="from_time"
                              onChange={handleChangeNight}
                              value={night?.from_time}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              End Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="end time"
                              name="to_time"
                              onChange={handleChangeNight}
                              value={night?.to_time}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Night Fare
                            </label>
                            <input
                              type="text"
                              id="example-if-email"
                              placeholder="night fare"
                              name="percentage"
                              onChange={handleChangeNight}
                              value={night?.percentage}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                      <button
                        className="comn-btn bg-primary px-3 py-2 me-2"
                        onClick={nightAdd}
                        disabled={btn ? true : false}
                      >
                        Submit
                      </button>
                    </div>
                    <br></br>
                  </div>
                </div>
              )}
            </>
          ) : null} */}
        </article>
      </aside>
    </>
  );
};

export default AddFareSetting;
