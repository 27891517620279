import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import SubscriptionRevenue from "./SubscriptionRevenue";
import ShareRideList from "../Drivers/ShareRideList";
import InhouseRevenue from "./InhouseRevenue";

const RevenueList = () => {
  const [data, setData] = useState();
  return (
    <aside>
      <TopHeader type="revenues" />

      <div className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Subscription">
            <SubscriptionRevenue setData={setData} />
          </Tab>
          <Tab eventKey="Detail" title="Share Ride">
            <ShareRideList data={data} />
          </Tab>
          <Tab eventKey="Tax" title="Inhouse">
            <InhouseRevenue data={data} />
          </Tab>
        </Tabs>
      </div>
    </aside>
  );
};

export default RevenueList;
