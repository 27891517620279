import React, { useState, useEffect } from "react";
import { Col, Form, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import Input from "../component/UI/input";
import {
  hidePwdImg,
  LoginLeft,
  Logo,
  LogoImg,
  showPwdImg,
} from "../../assets/img"
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// import useToken from "../../constants/Storage/useToken";
// import useUser from "../../constants/redux/api/api";
import { useCompanyLoginMutation} from "../../constants/redux/api/api";
import useToken from "../../constants/Storage/useToken";
import { jssPreset } from "@material-ui/core";
import { saveLoader } from "../../constants/redux/slice/loaderNoti";

const CompanyLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, setToken } = useToken();
  // const { user, setUser } = useUser();
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [nameErr, setnameErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [LoginAPi] = useCompanyLoginMutation();

  const submitHandle = () => {
    if (name?.length == 0 || pass?.length < 8) {
      setnameErr(true);
      setpassErr(true);
    } else {
      setBtn(true)
      dispatch(saveLoader(true));
      let formdata = new FormData();
      formdata.append("username", name);
      formdata.append("password", pass);
      LoginAPi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setBtn(false);
            dispatch(saveLoader(false));
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            // setToken(res?.token);
            // setUser(res?.company);
            localStorage.setItem("type",2);
            localStorage.setItem("company_token", res?.token);
            localStorage.setItem("company_user",JSON.stringify(res?.company));
            setTimeout(() => {
              navigate("/company");
              window.location.reload();
            }, 500);
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  return (
    <Col
      xs={12}
      className="d-flex vh-100 log-in justify-content-center align-items-center"
    >
      <Col xs={12} md={6} className="left-log-in bg-white">
        <div className="flex-ac-jc">
          <Image className="w-50" src={LogoImg} />
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        className="vh-100 flex-ac-jc bg-background1"
        style={{ background: "#86ca18" }}
      >
        <div className="bg-background rounded-3 px-4 py-5 w-75 login-box">
          <div className="flex-ac-jc">
            <Image className="log-logo me-2" src={""} />
          </div>
          <h3 className="d-flex justify-content-center txt-primary4 mt-0">Company Login</h3>
          <h4 className="sign-in-text txt-primary4 mb-0 mt-0">Sign In</h4>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              User Name
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="username"
              className="px-2 mt-1"
              value={name}
              autocomplete="off"
            />
            {name?.length == 0 && nameErr && (
              <span className="err">Enter the Username</span>
            )}
          </div>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              Password
            </label>
            <div className="pwd-container">
              <input
                type={isRevealPwd ? "text" : "password"}
                name="password"
                onChange={(e) => setPass(e.target.value)}
                placeholder="******"
                className="px-2 mt-1"
                value={pass}
                autocomplete="off"
              />
              <img
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
            {pass?.length < 8 && passErr && (
              <span className="err">Enter 8 digit Password</span>
            )}
          </div>

          {/* <Link
            to="/forgot-password"
            className="d-flex flex-row-reverse"
            style={{ fontSize: "14px", marginTop: "2px" }}
          >
            Forgot Password
          </Link> */}
          <div className="d-flex">
            <button
              onClick={submitHandle}
              className={`comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none me-2  ${ btn && "opacity-50"}`}
              disabled={btn ? true : false}
            >
              Login
            </button>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default CompanyLogin;
