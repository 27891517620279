import React, { useEffect, useState } from "react";
import Fare from "./fare";
import OtherCharge from "./otherCharge";
import PeakFare from "./peak";
import WaitingFare from "./waiting";
import { Tab, Tabs } from "react-bootstrap";
import { useLazyListChargesQuery } from "../../../constants/redux/api/api";
import { useLocation } from "react-router-dom";

function FareSetting({ data }) {
  const location = useLocation();
  const [chargesList, setChargesList] = useState([]);
  const [waitingList, setWaitingList] = useState([]);
  const [active, setActive] = useState("Detail-View");
  const [roundList, SetRoundList] = useState([]);

  const [chargeListApi] = useLazyListChargesQuery();

  const getList = () => {
    const params = `?ride_id=${data?.id}`;
    chargeListApi(params)
      .unwrap()
      .then((res) => {
        // console.log(res?.other_charges, "res");
        let temp = res?.other_charges?.filter((x) => x.type == 1);

        setChargesList(temp);
        let temp3 = res?.other_charges?.filter((x) => x.type == 2);
        setWaitingList(temp3);

        let temp2 = res?.other_charges?.filter((x) => x.type == 3);
        SetRoundList(temp2)
      })       
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
  }, []);
  useEffect(() => {
    if (location?.state?.tab) {
      setActive(location?.state?.tab);
    }
  }, []);

  return (
    <>
      <aside>
        <article>
          <div className="pt-3">
            <Tabs
              activeKey={active}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e) => setActive(e)}
            >
              <Tab eventKey="Detail-View" title="Base Fare">
                <OtherCharge
                  chargesList={chargesList}
                  getList={getList}
                  data={data}
                />
              </Tab>
              <Tab eventKey="Detail" title="Distance Fare">
                <Fare data={data} />
              </Tab>
              <Tab eventKey="Tax" title="Waiting Fare">
                <WaitingFare
                  waitingList={waitingList}
                  getList={getList}
                  data={data}
                  types="waiting"
                />
              </Tab>
              <Tab eventKey="Vechile" title="Peak Fare">
                <PeakFare data={data} />
              </Tab>
              <Tab eventKey="Round" title="Round Fare">
                <WaitingFare
                  roundList={roundList}
                  getList={getList}
                  data={data}
                  types="round"
                />
              </Tab>
            </Tabs>
          </div>
        </article>

        {/* <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <label>Base Fare</label>
            <OtherCharge />
          </div>
        </div>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="dist-fare d-flex justify-content-between">
              <label className="">Distance Fare</label>
              <span className="">Note: You Should Give One Higher From Km</span>
            </div>
            <Fare />
          </div>
        </div>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <label>Waiting Fare</label>
            <WaitingFare />
          </div>
        </div>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <label>Peak Fare</label>
            <PeakFare />
          </div>
        </div> */}
      </aside>
    </>
  );
}

export default FareSetting;
