import React, { useRef } from "react";
import { useState, useEffect } from "react";
import TopHeader from "../../../component/TopHeader";
import autoPng from "../../../assets/img/auto.jpg";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  useCompanyAdminSharecabRequestMutation,
  useCompanydriverAcceptOfflineMutation,
  useCompanyfirstSeachAddMutation,
  useCompanypreBookingAddMutation,
  useCompanySearchRideMutation,
  useCompanySecondseachAddMutation,
  useCompanyShareAvailableClickMutation,
  useCompanyShareCabPriceMutation,
  useCompanyShareCabRideListMutation,
  useCreateShareCabAdminMutation,
  useFirstSeachAddMutation,
  useLazyDriverAccpetQuery,
  useLazyListPlaceQuery,
  useLazyListVehicleQuery,
  useLazyUserListQuery,
  usePreBookingAddMutation,
  useSearchRideMutation,
  useSecondseachAddMutation,
  useShareCabRideListMutation,
} from "../../../constants/redux/api/api";
import moment from "moment";
import CompanyDistance from "./CompanyDistance";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";
import { TbArmchair2 } from "react-icons/tb";
import TopHeader2 from "../../../component/TopHeader2";

function CompanyAddOffline() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const status = location?.state?.status;
  const userid = JSON?.parse(localStorage?.getItem("company_user"));

  const [locationData, setLocationData] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [primaryNo, setPrimaryNo] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [primaryNErro, setPrimaryNoErr] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(0);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [timeErr, setTimeErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [user, setUser] = useState();
  const [selectUser, setSelectUser] = useState("");
  const [selectDriver, setSelectDriver] = useState("");
  const [selectSet, setSelectSet] = useState("");
  const [types, setType] = useState("");
  const [ridetypes, setRideType] = useState("");
  const [selectUserErr, setSelectUserErr] = useState(false);
  const [selectSetErr, setSelectSetErr] = useState(false);
  const [typesErr, setTypeErr] = useState(false);
  const [ridetypesErr, setRideTypeErr] = useState(false);
  const [searchId, setSearchId] = useState();
  const [amount, setAmount] = useState();
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [search, setSearch] = useState(false);
  const [value, setValue] = useState(false);
  const [searchThird, setSearchThird] = useState(false);
  const [toErr, setToErr] = useState(false);
  const [fromErr, setfromErr] = useState(false);
  const [showStatus, setShowStatus] = useState();
  const [btnEnable, setBtnEnable] = useState(false);
  const [bookingType, setBookingType] = useState(0);
  const [availableRides, setAvailableRides] = useState(false);
  const [userLists, setUserList] = useState([]);
  const [list, setList] = useState([]);
  const [driver, setDriver] = useState([]);
  const [userType, setUserType] = useState("");
  const [userTypeErr, setUserTypeErr] = useState(false);
  const [fareEstimation, setFareEstimation] = useState("");
  const [to, setTo] = useState("");
  const [from, setfrom] = useState("");
  const [place, setPlace] = useState([]);
  const [driverList, setDriverList] = useState([]);
  // const [toErr, setToErr] = useState(false);
  // const [fromErr, setfromErr] = useState(false);
  const [roundTime, setRoundTime] = useState("");
  const [roundTimeErr, setRoundTimeErr] = useState("");
  const [roundDate, setRoundDate] = useState("");
  const [roundDateErr, setRoundDateErr] = useState(false);
  const [driverPop, setDriverPop] = useState(false);
  const [seatList, setSeatList] = useState([]);
  const [shareCabId, setShareCabId] = useState("");
  const [btn, setBtn] = useState(false);

  console.log(seatList, "seatList", shareCabId);
  const handelModel = (item) => {
    setShareCabId(item?.id);
    setSeatList(item?.ride_id?.ride_seat_prices);
    setDriverPop(true);
  };

  const [option3, setOption3] = useState([
    { value: "1", label: "Intercity" },
    { value: "2", label: "Intracity" },
  ]);
  const [option4, setOption4] = useState([
    { value: "1", label: "Oneway" },
    { value: "4", label: "Round" },
  ]);
  const [option5, setOption5] = useState([
    { value: "exist", label: "Existing" },
    { value: "new", label: "New" },
  ]);

  const [typeBook, setTypeBook] = useState("");
  const [userListApi] = useLazyUserListQuery();
  const [listVehicleApi] = useLazyListVehicleQuery();
  const [driverAcceptApi] = useCompanydriverAcceptOfflineMutation();
  const [preBookingAddApi] = useCompanypreBookingAddMutation();
  const [firstSearchApi] = useCompanyfirstSeachAddMutation();
  const [secondSearchApi] = useCompanySecondseachAddMutation();
  const [searchRideApi] = useCompanySearchRideMutation();
  //SHARE CAB API
  const [shareCabRideListApi] = useCompanyShareCabRideListMutation();
  const [placeListApi] = useLazyListPlaceQuery();
  const [shareAvailableClick] = useCompanyShareAvailableClickMutation();
  const [shareCabPrice] = useCompanyShareCabPriceMutation();
  const [AdminSharecab] = useCompanyAdminSharecabRequestMutation();
  const apiHandler = () => {
    userListApi()
      .unwrap()
      .then((res) => {
        const temp = [];

        res?.users?.map((item, ind) => {
          temp.push({
            value: item?.id,
            label: item?.name,
          });
        });
        setUserList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const apiplaceList = () => {
    placeListApi()
      .unwrap()
      .then((res) => {
        const temp = [];

        res?.places?.map((item, ind) => {
          temp.push({
            value: item?.id,
            label: item?.title,
          });
        });
        setPlace(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const apiDriver = () => {
    driverAcceptApi()
      .unwrap()
      .then((res) => {
        console.log(res);

        const option5s = [];
        res?.drivers?.map((item, ind) => {
          option5s.push({
            value: item?.id,
            label: item?.name,
          });
        });
        setDriver(option5s);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getList = () => {
    listVehicleApi()
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        const temp = [];
        res?.rides?.map((item, ind) => {
          temp.push({
            value: item?.id,
            label: item?.name,
          });
        });

        setList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
    apiDriver();
  }, []);

  useEffect(() => {
    apiHandler();
    apiplaceList();
  }, []);

  // const options = user?.map(({ name, id, mobile }) => {
  //   return {
  //     value: id,
  //     label: name + " / " + mobile,
  //   };
  // });

  // console.log("loc", selectUser?.value?.length);

  // console.log(locationData?.routes[0]?.legs[0]?.duration);

  const addBooking = () => {
    if (userType == "") setUserTypeErr(true);
    else if (
      (userType?.value == "exist" &&
        (selectUser == "" ||
          selectSet == "" ||
          types == "" ||
          ridetypes == "" ||
          (bookingStatus == "4" &&
            (date?.length == "" || time?.length == "")) ||
          (ridetypes?.value == "4" &&
            (roundTime?.length == "" || roundDate?.length == "")))) ||
      (userType?.value == "new" &&
        (types == "" ||
          selectSet == "" ||
          ridetypes == "" ||
          (bookingStatus == "4" &&
            (date?.length == "" || time?.length == "")) ||
          (ridetypes?.value == "4" &&
            (roundTime?.length == "" || roundDate?.length == "")) ||
          name?.length == 0 ||
          mobile?.length == 0 ||
          email?.length == 0 ||
          primaryNo?.length == 0))
    ) {
      if (userType?.value == "exist") {
        setSelectUserErr(true);
        setSelectSetErr(true);
        setTypeErr(true);
        setRideTypeErr(true);
        if (bookingStatus == "4") {
          setTimeErr(true);
          setDateErr(true);
        }
        if (ridetypes?.value == "4") {
          setRoundTimeErr(true);
          setRoundDateErr(true);
        }
      } else {
        setSelectSetErr(true);
        setTypeErr(true);
        setRideTypeErr(true);
        setNameErr(true);
        setEmailErr(true);
        setMobileErr(true);
        setPrimaryNoErr(true);
        if (bookingStatus == "4") {
          setTimeErr(true);
          setDateErr(true);
        }
        if (ridetypes?.value == "4") {
          setRoundTimeErr(true);
          setRoundDateErr(true);
        }
      }
    } else {
      let formdata = new FormData();
      let whole_string = locationData?.routes[0]?.legs[0]?.distance?.text;
      let split_string = whole_string?.split(/(\d+.\d)/);
      formdata.append(
        "user_pickup_latitude",
        locationData?.routes[0]?.legs[0]?.start_location?.lat()
      );
      formdata.append(
        "user_pickup_longitude",
        locationData?.routes[0]?.legs[0]?.start_location?.lng()
      );
      formdata.append(
        "user_drop_latitude",
        locationData?.routes[0]?.legs[0]?.end_location?.lat()
      );
      formdata.append(
        "user_drop_longitude",
        locationData?.routes[0]?.legs[0]?.end_location?.lng()
      );
      formdata.append(
        "pickup_address",
        locationData?.routes[0]?.legs[0]?.start_address
      );
      formdata.append(
        "drop_address",
        locationData?.routes[0]?.legs[0]?.end_address
      );
      formdata.append(
        "ride_duration",
        locationData?.routes[0]?.legs[0]?.duration?.text
      );
      formdata.append("ride_id", selectSet?.value);
      formdata.append("distance", split_string[1]);
      formdata.append("status", bookingStatus);
      formdata.append("booking_by", "2");
      formdata.append("type", types?.value);
      if (selectDriver?.value) {
        formdata.append("driver_id", selectDriver?.value);
      }
      formdata.append("company_id", userid?.company_id);
      // formdata.append("admin_id", "");

      if (selectUser?.value == null) {
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("mobile", mobile);
        formdata.append("fcm_id", "");
        formdata.append("primary_mobile", primaryNo);
        formdata.append("user_type", userType?.value);
      } else {
        formdata.append("user_type", userType?.value);
        formdata.append("user_id", selectUser?.value);
      }
      formdata.append("ride_type", ridetypes?.value);
      if (ridetypes?.value == "4") {
        formdata.append("round_return_time", roundTime);
      }
      if (bookingStatus == 4) {
        let prebooking_time = [date, time];

        if (selectDriver?.value) {
          formdata.append("schedule_booking_time", prebooking_time.toString());
          formdata.append("booking_for", "4");
        }
        formdata.append("prebooking_time", prebooking_time.toString());
        dispatch(saveLoader(true));
        setBtnEnable(true);
        preBookingAddApi(formdata)
          .then((res) => {
            if (res?.status == "success") {
              dispatch(saveLoader(false));
              setBtnEnable(false);
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/company/offline-booking");
            }
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtnEnable(false);
            console.log(err);
          });
      } else if (bookingStatus == 0) {
        if (selectDriver?.value) {
          formdata.append("booking_for", "3");
        }
        setBtnEnable(true);
        dispatch(saveLoader(true));
        firstSearchApi(formdata)
          .then((res) => {
            setBtnEnable(false);
            dispatch(saveLoader(false));
            if (res?.data?.status == "success") {
              if (res?.data?.otp) {
                toast.success(res?.data?.message, {
                  position: "top-right",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  autoClose: 2000,
                });
                navigate("/company/offline-booking");
              } else {
                setSearchId(res?.data?.search_ride_id);
                setOpen(true);
                setSearch(true);
              }
            }
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            console.log(err);
            setBtnEnable(false);
          });
      }
    }
  };

  // console.log(searchId);

  // const searchStatus = () => {
  //   let data = {
  //     search_ride_id: searchId,
  //   };
  //   if (searchId?.length != "") {
  //     api.offlineStatus(data).then((res) => {
  //       setShowStatus(res?.search_ride_status);
  //     });
  //   }
  // };

  const [distanceKm, setDistanceKm] = useState();

  // const searchAuto = () => {
  //   let data = {};
  //   if (search == true) {
  //     data.distance = 1.5;
  //     setSearch(false);
  //   } else {
  //     data.distance = 2.5;
  //   }

  //   api.offlineSecondSearch(searchId, data).then((res) => {
  //     // console.log("res", res);
  //     setDistanceKm(res?.distance);
  //     setValue(false);
  //   });
  // };

  const tryAgainLater = () => {
    alert("Driver Not Allocated! Try Again Later");
    navigate("/offline-booking");
  };

  const searchAuto = (kms) => {
    let data = {
      distance: kms,
    };
    secondSearchApi({ id: searchId, payload: data })
      .unwrap()
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const searchStatus = () => {
    console.log("res");
    let data = {
      search_ride_id: searchId,
    };
    let rideStatus = searchRideApi(data)
      .unwrap()
      .then((resp) => {
        console.log("res", resp);
        setShowStatus(resp?.search_ride_status);
        if (resp?.search_ride_status == 1) {
          alert("Driver not allocated");
          navigate("/company/offline-booking");
        } else if (resp?.search_ride_status == 2) {
          alert("Ride allocated");
          navigate("/company/offline-booking");
        } else if (resp?.search_ride_status == 3) {
          alert("Cancelled");
          navigate("/company/offline-booking");
        } else if (resp?.search_ride_status == 4) {
          alert("Prebooking");
          navigate("/company/offline-booking");
        }

        return resp?.search_ride_status;
      });
    return rideStatus;
  };

  

  const rideAvail = () => {
    console.log("rideAvail", searchId);
    let rideStatus0 = searchStatus();
    console.log(rideStatus0, "rideStatus frm setTimeout 0");
    setTimeout(async () => {
      let rideStatus0 = await searchStatus();
      console.log(rideStatus0, "rideStatus frm setTimeout 0");
      if (rideStatus0 == 0) {
        setTimeout(async () => {
          let rideStatus1 = await searchStatus();
          console.log(rideStatus1, "rideStatus frm setTimeout 1");
          if (rideStatus1 == 0) {
            console.log("1rd ride cancell");
            searchAuto(4);
            setTimeout(async () => {
              let rideStatus2 = await searchStatus();
              console.log(rideStatus2, "rideStatus frm setTimeout 2");
              if (rideStatus2 == 0) {
                searchAuto(8);
                setTimeout(async () => {
                  let rideStatus3 = await searchStatus();
                  console.log(rideStatus3, "rideStatus frm setTimeout 3");
                  if (rideStatus3 == 0) {
                    console.log("3nd ride cancell");
                    setSearch(false);
                    setSearchId("");

                    // let rep = {
                    //   response: {
                    //     data: {
                    //       message: "Driver is not available",
                    //     },
                    //   },
                    // };
                  } else {
                    console.log("3rd ride success");
                  }
                }, 0);
                console.log("2nd ride cancell");
              } else {
                console.log("2nd ride success");
              }
            }, 0);
          } else {
            console.log("1rd ride success");
          }
        }, 0);
      } else {
        console.log("0rd ride success");
      }
    }, 0);
  };

  useEffect(() => {
    if (searchId) {
      rideAvail();
    }
  }, [searchId]);

  

  const shareCab = () => {
    if (
      userType == "" ||
      from?.length == "" ||
      to?.length == "" ||
      selectSet == ""
    ) {
      setUserTypeErr(true);
      setfromErr(true);
      setToErr(true);
      setSelectSetErr(true)
    } else if (
      (userType?.value == "exist" && selectUser == "") ||
      (userType?.value == "new" &&
        (name?.length == 0 ||
          mobile?.length == 0 ||
          email?.length == 0 ||
          primaryNo?.length == 0))
    ) {
      if (userType?.value == "exist") {
        setSelectUserErr(true);
      } else {
        setNameErr(true);
        setEmailErr(true);
        setMobileErr(true);
        setPrimaryNoErr(true);
      }
    } else {
      dispatch(saveLoader(true));
      setBtnEnable(true);
      const formData = new FormData();
      formData.append("from_place_id", from?.value);
      formData.append("to_place_id", to?.value);
      shareCabRideListApi(formData)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtnEnable(false);
          // console.log(res?.rides);
          setDriverList(res?.rides);
          setAvailableRides(true);
        })
        .catch((err) => {
          console.log(err);
          dispatch(saveLoader(false));
          setBtnEnable(false);
        });
    }
  };
  const ckeckAvailableity = (ind, item) => {
    const formdata = new FormData();
    formdata.append("from_place_id", from?.value);
    formdata.append("to_place_id", to?.value);
    formdata.append("seat_no", item?.seat_no);
    formdata.append("share_cab_ride_id", shareCabId);
    setBtn(true);
    dispatch(saveLoader(true));
    shareAvailableClick(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getPriceApi(ind, item);
        }
      })
      .catch((err) => {
        setDriverPop(false)
        setBtn(false);
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  const getPriceApi = (ind, item) => {
    const formdata = new FormData();
    formdata.append("from_place_id", from?.value);
    formdata.append("to_place_id", to?.value);
    formdata.append("type", "3");
    formdata.append("seat_no", item?.seat_no);
    formdata.append("share_cab_ride_id", shareCabId);
    setBtn(true);
    dispatch(saveLoader(true));
    shareCabPrice(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          priceAdd(ind, res?.estimated_ride_fare);
        }
      })
      .catch((err) => {
        // setDriverPop(false)
        setBtn(false);
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  const priceAdd = (ind, price) => {
    const temp = [...seatList];
    temp[ind] = {
      ...temp[ind],
      price: price,
    };
    setSeatList(temp);
  };
  const SubmitAdminShareCab = () => {
    const formdata = new FormData();
    const sum = seatList?.reduce((acc, cur) => {
      return acc + (cur?.price ?? 0);
    }, 0);
    formdata.append("final_amount", sum);
    formdata.append("from_place_id", from?.value);
    formdata.append("to_place_id", to?.value);
    formdata.append("share_cab_ride_id", shareCabId);
    formdata.append("position", "7");
    if (userType?.value == "exist") {
      formdata.append("user_id", selectUser?.value);
    } else {
      formdata.append("name", name);
      formdata.append("mobile", mobile);
      formdata.append("email", email);
      formdata.append("sos_number", primaryNo);
    }
    seatList?.map((item, ind) => {
      if (item?.price) {
        formdata.append(`seats[${ind}][seat_no]`, item?.seat_no);
        formdata.append(`seats[${ind}][amount]`, item?.price);
      }
    });
    setBtn(true);
    dispatch(saveLoader(true));
    AdminSharecab(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log(res);
        if (res?.status == "success") {
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
        }
        setDriverPop(false)
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtn(false);
        console.log(err);
      });
  };

  return (
    <aside>
      {type === "view" ? (
        <TopHeader2 type="viewBooking" />
      ) : (
        <TopHeader2 type="addBooking" />
      )}

      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="flex-ac-jb">
              <p
                className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                for=""
              ></p>
              <span>Booking By: Share Auto</span>
            </div>
            <div className="">
              <div className="tab-tabs">
                <Tabs
                  defaultActiveKey="Detail-View"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(e) => console.log(e)}
                >
                  <Tab eventKey="Detail-View" title="Type">
                    <div className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="label-off" for="">
                              User Type
                            </label>
                            <div className="seac-box">
                              <Select
                                value={userType}
                                onChange={(e) => setUserType(e)}
                                options={option5}
                                class="bg-transparent"
                              >
                                {/* <option>Select User</option>
                              {userLists?.map((item) => {
                                return <option>{item?.name}</option>;
                              })} */}
                              </Select>
                              {userType == "" && userTypeErr && (
                                <p className="err-p">Enter User </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {userType?.value == "exist" && (
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="label-off" for="">
                                User Name
                              </label>
                              <div className="seac-box">
                                <Select
                                  value={selectUser}
                                  onChange={(e) => setSelectUser(e)}
                                  options={userLists}
                                  class="bg-transparent"
                                >
                                  {/* <option>Select User</option>
                              {userLists?.map((item) => {
                                return <option>{item?.name}</option>;
                              })} */}
                                </Select>
                                {selectUser == "" && selectUserErr && (
                                  <p className="err-p bo">Enter User </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="label-off" for="">
                              Vehicle Type
                            </label>
                            <div className="seac-box">
                              <Select
                                value={selectSet}
                                onChange={(e) => setSelectSet(e)}
                                options={list}
                                class="bg-transparent"
                              />
                              {selectSet == "" && selectSetErr && (
                                <p className="err-p">Enter Vehicle Type</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="label-off" for="">
                              Ride Type
                            </label>
                            <div className="seac-box">
                              <Select
                                value={ridetypes}
                                onChange={(e) => setRideType(e)}
                                options={option4}
                                class="bg-transparent"
                              />
                              {ridetypes == "" && ridetypesErr && (
                                <p className="err-p y-1">Enter Ride </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {ridetypes?.label == "Round" && (
                        <div className="form-group col-12  d-flex flex-wrap">
                          <div className="form-group col-12 col-sm-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="text-primary-dark" for="">
                                  Ride Date
                                </label>
                                <input
                                  type="date"
                                  id="example-if-email"
                                  placeholder="from"
                                  name="from"
                                  className="bg-transparent"
                                  onChange={(e) => setRoundDate(e.target.value)}
                                  value={roundDate}
                                />
                                {roundDate?.length == "" && roundDateErr && (
                                  <p className="err-p">Enter Round Date</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="label-off" for="">
                                  Ride Time
                                </label>
                                <input
                                  value={roundTime}
                                  placeholder="Ride Time"
                                  name="time"
                                  className="bg-transparent"
                                  type="time"
                                  onChange={(e) => setRoundTime(e.target.value)}
                                />
                                {roundTime?.length == "" && roundTimeErr && (
                                  <p className="err-p">Enter Round Time</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="label-off" for="">
                              Type
                            </label>
                            <div className="seac-box">
                              <Select
                                value={types}
                                onChange={(e) => setType(e)}
                                options={option3}
                                class="bg-transparent"
                              />
                            </div>
                            {types == "" && typesErr && (
                              <p className="err-p">Enter Types </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="label-off" for="">
                              Accept Driver
                            </label>
                            <div className="seac-box">
                              <Select
                                value={selectDriver}
                                onChange={(e) => setSelectDriver(e)}
                                options={driver}
                                class="bg-transparent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {userType?.value == "new" && (
                        <>
                          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="label-off" for="">
                                  User Name
                                </label>
                                <input
                                  id="example-if-email"
                                  name="username"
                                  placeholder="user name"
                                  onChange={(e) => setName(e.target.value)}
                                  value={name}
                                  className="bg-transparent"
                                  type="text"
                                />
                                {name?.length == 0 && nameErr && (
                                  <p className="err-p">Enter Name </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="text-primary-dark" for="">
                                  Mobile
                                </label>
                                {/* <input
                        id="example-if-email"
                        name="mobile"
                        placeholder="mobile"
                        onChange={(e) => setMobile(e.target.value)}
                        value={mobile}
                        className="bg-transparent"
                        type="number"
                      /> */}
                                <input
                                  onInput={(e) => {
                                    if (
                                      e.target.value.length > e.target.maxLength
                                    )
                                      e.target.value = e.target.value.slice(
                                        0,
                                        e.target.maxLength
                                      );
                                    if (e.target.value.length !== 10) {
                                      e.target.setCustomValidity(
                                        "invalid Number"
                                      );
                                    } else if (e.target.value.length == 10) {
                                      e.target.setCustomValidity("");

                                      setMobile(e.target.value);
                                    }
                                  }}
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                  maxlength={10}
                                  onChange={(e) => setMobile(e.target.value)}
                                  value={mobile}
                                  type="number"
                                  className="bg-transparent"
                                  placeholder="mobile"
                                />
                                {mobile?.length == "" && mobileErr && (
                                  <p className="err-p">Enter Mobile </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-12 col-sm-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="text-primary-dark" for="">
                                  Email
                                </label>
                                <input
                                  value={email}
                                  placeholder="email"
                                  name="email"
                                  className="bg-transparent"
                                  type="text"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                {email == "" && emailErr && (
                                  <p className="err-p">Enter Email</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-12 col-sm-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="text-primary-dark" for="">
                                  Primary Contact
                                </label>
                                {/* <input
                        placeholder="Primary Contact"
                        name="sos"
                        className="bg-transparent"
                        onChange={(e) => setPrimaryNo(e.target.value)}
                        value={primaryNo}
                        type="number"
                      /> */}
                                <input
                                  onInput={(e) => {
                                    if (
                                      e.target.value.length > e.target.maxLength
                                    )
                                      e.target.value = e.target.value.slice(
                                        0,
                                        e.target.maxLength
                                      );
                                    if (e.target.value.length !== 10) {
                                      e.target.setCustomValidity(
                                        "invalid Number"
                                      );
                                    } else if (e.target.value.length == 10) {
                                      e.target.setCustomValidity("");

                                      setPrimaryNo(e.target.value);
                                    }
                                  }}
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                  maxlength={10}
                                  onChange={(e) => setPrimaryNo(e.target.value)}
                                  value={primaryNo}
                                  type="number"
                                  className="bg-transparent"
                                  placeholder="Primary Contact"
                                />
                                {primaryNo?.length == "" && primaryNErro && (
                                  <p className="err-p">Enter Primary</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="check-color my-3">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          onClick={(e) => setBookingStatus(4)}
                          checked={bookingStatus == 4 ? true : false}
                        />
                        <label class="form-check-label" htmlFor="inlineRadio1">
                          Pre Booking
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option2"
                          onClick={(e) => setBookingStatus(0)}
                          checked={bookingStatus == 0 ? true : false}
                        />
                        <label class="form-check-label" htmlFor="inlineRadio1">
                          Instant Booking
                        </label>
                      </div>
                    </div>

                    {bookingStatus == 4 ? (
                      <>
                        <div className="form-group col-12  d-flex flex-wrap">
                          <div className="form-group col-12 col-sm-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="text-primary-dark" for="">
                                  Pre Book Date
                                </label>
                                <input
                                  type="date"
                                  id="example-if-email"
                                  placeholder="from"
                                  name="from"
                                  className="bg-transparent"
                                  onChange={(e) => setDate(e.target.value)}
                                  value={date}
                                />
                                {date?.length == "" && dateErr && (
                                  <p className="err-p">Enter Date</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="text-primary-dark" for="">
                                  Pre Book Time
                                </label>
                                <input
                                  type="time"
                                  id="example-if-email"
                                  placeholder="from"
                                  name="from"
                                  onChange={(e) => setTime(e.target.value)}
                                  value={time}
                                  className="bg-transparent"
                                />
                                {time?.length == "" && timeErr && (
                                  <p className="err-p">Enter Time</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="p-3">
                      <div className="col-12 p-3 bg-white rounded-2">
                        <CompanyDistance
                          setLocationData={setLocationData}
                          selectSet={selectSet}
                          type={types}
                          setFareEstimation={setFareEstimation}
                          fareEstimation={fareEstimation}
                          userType={userType}
                          setUserTypeErr={setUserTypeErr}
                          selectUser={selectUser}
                          types={types}
                          ridetypes={ridetypes}
                          roundTime={roundTime}
                          name={name}
                          mobile={mobile}
                          email={email}
                          primaryNo={primaryNo}
                          setSelectUserErr={setSelectUserErr}
                          setSelectSetErr={setSelectSetErr}
                          setTypeErr={setTypeErr}
                          setRideTypeErr={setRideTypeErr}
                          setRoundTimeErr={setRoundTimeErr}
                          setNameErr={setNameErr}
                          setEmailErr={setEmailErr}
                          setMobileErr={setMobileErr}
                          setPrimaryNoErr={setPrimaryNoErr}
                          roundDate={roundDate}
                          setRoundDateErr={setRoundDateErr}
                          bookingStatus={bookingStatus}
                          date={date}
                          time={time}
                          setTimeErr={setTimeErr}
                          setDateErr={setDateErr}
                          setBtnEnable={setBtnEnable}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 d-flex justify-content-center pr-2 mb-2">
                      <button
                        className={`comn-btn bg-primary px-3 py-2 me-2 ${
                          btnEnable && "opacity-50"
                        }`}
                        onClick={() => addBooking()}
                        disabled={btnEnable == true ? true : false}
                      >
                        Submit Bookings
                      </button>
                      {/* <button onClick={() => setOpen(true)}>open</button> */}
                    </div>
                  </Tab>
                  <Tab eventKey="Detail" title="Share Cab">
                    <>
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="label-off" for="">
                                User Type
                              </label>
                              <div className="seac-box">
                                <Select
                                  value={userType}
                                  onChange={(e) => setUserType(e)}
                                  options={option5}
                                  class="bg-transparent"
                                ></Select>
                                {userType == "" && userTypeErr && (
                                  <p className="err-p">Enter User </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {userType?.value == "exist" && (
                          <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                            <div className="form-check ps-0">
                              <div className="px-1 col-sm-12 modal-label-input">
                                <label className="label-off" for="">
                                  User Name
                                </label>
                                <div className="seac-box">
                                  <Select
                                    value={selectUser}
                                    onChange={(e) => setSelectUser(e)}
                                    options={userLists}
                                    class="bg-transparent"
                                  >
                                    {/* <option>Select User</option>
                              {userLists?.map((item) => {
                                return <option>{item?.name}</option>;
                              })} */}
                                  </Select>
                                  {selectUser == "" && selectUserErr && (
                                    <p className="err-p bo">Enter User </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="label-off" for="">
                                Cab Type
                              </label>
                              <div className="seac-box">
                                <Select
                                  value={selectSet}
                                  onChange={(e) => setSelectSet(e)}
                                  options={list}
                                  class="bg-transparent"
                                />
                                {selectSet == "" && vehicleErr && (
                                  <p className="err-p">Enter Vehicle Type</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* {typeBook && ( */}
                        {/* <div className="form-group col-12  d-flex flex-wrap"> */}
                        {userType?.value == "new" && (
                          <>
                            <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                              <div className="form-check ps-0">
                                <div className="px-1 col-sm-12 modal-label-input">
                                  <label className="label-off" for="">
                                    User Name
                                  </label>
                                  <input
                                    id="example-if-email"
                                    name="username"
                                    placeholder="user name"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className="bg-transparent"
                                    type="text"
                                  />
                                  {name?.length == 0 && nameErr && (
                                    <p className="err-p">Enter Name </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                              <div className="form-check ps-0">
                                <div className="px-1 col-sm-12 modal-label-input">
                                  <label className="text-primary-dark" for="">
                                    Mobile
                                  </label>

                                  <input
                                    onInput={(e) => {
                                      if (
                                        e.target.value.length >
                                        e.target.maxLength
                                      )
                                        e.target.value = e.target.value.slice(
                                          0,
                                          e.target.maxLength
                                        );
                                      if (e.target.value.length !== 10) {
                                        e.target.setCustomValidity(
                                          "invalid Number"
                                        );
                                      } else if (e.target.value.length == 10) {
                                        e.target.setCustomValidity("");

                                        setMobile(e.target.value);
                                      }
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                    maxlength={10}
                                    onChange={(e) => setMobile(e.target.value)}
                                    value={mobile}
                                    type="number"
                                    className="bg-transparent"
                                    placeholder="Primary Contact"
                                  />
                                  {mobile?.length == "" && mobileErr && (
                                    <p className="err-p">Enter Mobile </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-12 col-sm-6 ps-0">
                              <div className="form-check ps-0">
                                <div className="px-1 col-sm-12 modal-label-input">
                                  <label className="text-primary-dark" for="">
                                    Email
                                  </label>
                                  <input
                                    placeholder="email"
                                    name="email"
                                    className="bg-transparent"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                  {email == "" && emailErr && (
                                    <p className="err-p">Enter Email</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-12 col-sm-6 ps-0">
                              <div className="form-check ps-0">
                                <div className="px-1 col-sm-12 modal-label-input">
                                  <label className="text-primary-dark" for="">
                                    Primary Contact
                                  </label>
                                  {/* <input
                        placeholder="Primary Contact"
                        name="sos"
                        className="bg-transparent"
                        onChange={(e) => setPrimaryNo(e.target.value)}
                        value={primaryNo}
                        type="number"
                      /> */}
                                  <input
                                    onInput={(e) => {
                                      if (
                                        e.target.value.length >
                                        e.target.maxLength
                                      )
                                        e.target.value = e.target.value.slice(
                                          0,
                                          e.target.maxLength
                                        );
                                      if (e.target.value.length !== 10) {
                                        e.target.setCustomValidity(
                                          "invalid Number"
                                        );
                                      } else if (e.target.value.length == 10) {
                                        e.target.setCustomValidity("");

                                        setPrimaryNo(e.target.value);
                                      }
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                    maxlength={10}
                                    onChange={(e) =>
                                      setPrimaryNo(e.target.value)
                                    }
                                    value={primaryNo}
                                    type="number"
                                    className="bg-transparent"
                                    placeholder="Primary Contact"
                                  />
                                  {primaryNo?.length == "" && primaryNErro && (
                                    <p className="err-p">Enter Primary</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="form-group col-12 col-sm-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                From
                              </label>

                              <Select
                                value={from}
                                onChange={(e) => setfrom(e)}
                                options={place}
                                class="bg-transparent"
                              ></Select>
                              {from?.length == "" && fromErr && (
                                <p className="err-p">Enter From </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                To
                              </label>

                              <Select
                                value={to}
                                onChange={(e) => setTo(e)}
                                options={place}
                                class="bg-transparent"
                              ></Select>
                              {to?.length == "" && toErr ? (
                                <p className="err-p">Enter To </p>
                              ) : from?.length == "" ? (
                                ""
                              ) : from == to ? (
                                <p className="err-p">
                                  From and to place is not same
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="label-off" for="">
                                Cab Id
                              </label>
                              <div className="seac-box">
                                <Select
                                  value={selectSet}
                                  onChange={(e) => setSelectSet(e)}
                                  options={list}
                                  class="bg-transparent"
                                />
                                {selectSet == "" && selectSetErr && (
                                  <p className="err-p">Enter Cab Id</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-12 d-flex justify-content-end">
                        <button
                          onClick={() => {
                            shareCab();
                          }}
                          className={`d-flex justify-content-end comn-btn bg-primary px-3 py-2 me-2 mt-4 ${
                            btnEnable && "opacity-50"
                          }`}
                          disabled={btnEnable ? true : false}
                        >
                          Find
                        </button>
                      </div>
                      {availableRides && (
                        <div className="form-group col-12 col-sm-12 col-lg-12 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                Available Rides Details
                              </label>
                              <div
                                style={{
                                  height: "300px",
                                  overflow: "auto",
                                }}
                              >
                                <div className="d-flex flex-wrap mt-2">
                                  {driverList?.length>0 ? driverList.map((item, ind) => {
                                    return (
                                      <>
                                           <div
                                          className="form-group col-3 col-sm-2 col-lg-3 ps-0"
                                          onClick={() => {
                                            handelModel(item);
                                          }}
                                          role="button"
                                        >
                                          <div className="form-check ps-0">
                                            <div className="px-1 col-sm-12 modal-label-input">
                                              <label
                                                className="text-primary-dark"
                                                for=""
                                              >
                                                Cab Driver
                                              </label>
                                              <p className="bg-transparent mt-2">
                                                {item?.driver?.name}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="form-group col-4 col-sm-4 col-lg-3 ps-0"
                                          onClick={() => {
                                            handelModel(item);
                                          }}
                                          role="button"
                                        >
                                          <div className="form-check ps-0">
                                            <div className="px-1 col-sm-12 modal-label-input">
                                              <label
                                                className="text-primary-dark"
                                                for=""
                                              >
                                                Cab Name
                                              </label>
                                              <p className="bg-transparent mt-2">
                                                {item?.ride_id?.name}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group col-4 col-sm-4 col-lg-3 ps-0">
                                          <div className="form-check ps-0">
                                            <div className="px-1 col-sm-12 modal-label-input">
                                              <label
                                                className="text-primary-dark"
                                                for=""
                                              >
                                                From & To Address
                                              </label>
                                              <p className="bg-transparent mt-2">
                                                {item?.from_place?.title}-
                                                {item?.to_place?.title}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group col-4 col-sm-4 col-lg-3 ps-0">
                                          <div className="form-check ps-0">
                                            <div className="px-1 col-sm-12 modal-label-input">
                                              <label
                                                className="text-primary-dark"
                                                for=""
                                              >
                                                Cab Time
                                              </label>
                                              <p className="bg-transparent mt-2">
                                                {moment(item?.ride_on).format(
                                                  "DD/MM/YYYY hh:mm A"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }):"No Data"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* </div> */}

                      {/* )} */}
                    </>
                  </Tab>
                </Tabs>
              </div>
              {/* <div
                className="
              d-flex flex-wrap align-items-center mb-3"
              >
                <button
                  className="me-3"
                  type="button"
                  onClick={() => setBookingType(0)}
                >
                  Exist User
                </button>
                <button type="button" onClick={() => setBookingType(1)}>
                  New User
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </article>
      {open && (
        <div
          className="customPopup"
          style={{ zIndex: "999999", top: 0, left: 0, bottom: 0, right: 0 }}
        >
          <div className="customPopupDesign">
            <h5 className="text-center mb-2">
              {search == true ? "Searching Auto..." : "Share Auto"}
            </h5>
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="text-primary">
                  <b>
                    {search == true
                      ? `Fare Estimation: Rs.${fareEstimation}`
                      : ""}
                  </b>
                </p>
              </div>
              <div className="">
                {search == true ? (
                  <p className="">
                    Status:{" "}
                    {showStatus == 0
                      ? "Searching"
                      : showStatus == 1
                      ? "Driver not allocate"
                      : showStatus == 2
                      ? "Driver allocated"
                      : showStatus == 3
                      ? "Canceled"
                      : showStatus == 4
                      ? "Prebooking"
                      : "Searching"}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            {search == true ? (
              <div className="auto-move py-4">
                <img className="auto-img" src={autoPng} />
              </div>
            ) : (
              <div className="">
                <div className="auto-move ">
                  <img className="auto-imgs" src={autoPng} />
                </div>
                <div className="d-flex justify-content-center py-3">
                  <button
                    onClick={addBooking}
                    className="comn-btn btn btn-outline-success px-3 py-2 me-2 "
                    disabled={btnEnable == true ? true : false}
                  >
                    Search Again
                  </button>
                  <button
                    onClick={(e) => {
                      setSearchId("");
                      setOpen(false);
                    }}
                    className="comn-btn btn btn-outline-warning px-3 py-2 me-2 "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal show={opens} onHide={() => setOpens(false)}>
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          <Modal.Title>
            <h5 className="text-align-center">Searching Auto...</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <p className="text-primary">
              <b>Estimated Amount: {"Rs. " + fareEstimation}</b>
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <p className="">
              Status:{" "}
              {showStatus == 0
                ? "Searching"
                : showStatus == 1
                ? "Driver not allocate"
                : showStatus == 2
                ? "Driver allocated"
                : showStatus == 3
                ? "Canceled"
                : showStatus == 4
                ? "Prebooking"
                : "Searching"}
            </p>
          </div>
          {search == true ? (
            <div className="auto-move py-4">
              <img className="auto-img" src={autoPng} />
            </div>
          ) : (
            <div className="">
              <div className="auto-move ">
                <img className="auto-imgs" src={autoPng} />
              </div>
              <div className="d-flex justify-content-center py-3">
                <button
                  onClick={addBooking}
                  className="comn-btn btn btn-outline-success px-3 py-2 me-2 "
                  disabled={btnEnable == true ? true : false}
                >
                  Search Again
                </button>
                <button
                  onClick={(e) => {
                    setSearchId("");
                    setOpens(false);
                  }}
                  className="comn-btn btn btn-outline-warning px-3 py-2 me-2 "
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {/* {value == true ? (
            <div class="d-flex justify-content-center p-3">
              <button
                onClick={distanceKm == 2.5 ? tryAgainLater : searchAuto}
                className="comn-btn bg-primary px-3 py-2 me-2 "
              >
                Search Again
              </button>
            </div>
          ) : (
            <div class="d-flex justify-content-center p-3">
              <Timer
                value={value}
                setValue={setValue}
                searchStatus={searchStatus}
              />
            </div>
          )} */}
        </Modal.Body>
      </Modal>
      <Modal
        className="table-modal"
        show={driverPop}
        onHide={() => setDriverPop(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Vehicle Types</h4>
          </div>
          {seatList?.map((item, ind) => {
            return (
              <div className="px-1 col-sm-6 modal-label-input" key={ind}>
                <p
                  style={{
                    width: "50px",
                    height: "35px",
                    marginLeft: "45px",
                    color: "black",
                  }}
                >
                  {item?.seat_no}
                </p>
                <button
                  for=""
                  role="button"
                  disabled={btn ? true : false}
                  onClick={() => ckeckAvailableity(ind, item)}
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <TbArmchair2
                    style={{
                      width: "50px",
                      height: "35px",
                      marginLeft: "35px",
                      color: "green",
                    }}
                  />
                </button>
                <p
                  style={{
                    width: "50px",
                    height: "35px",
                    marginLeft: "45px",
                    color: "black",
                  }}
                >
                  {item?.price}
                </p>
              </div>
            );
          })}
        </div>

        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setDriverPop(false);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className={`comn-btn bg-success px-3 py-2 me-2 ${
              btn && "opacity-50"
            }`}
            data-dismiss="modal"
            onClick={SubmitAdminShareCab}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
}

export default CompanyAddOffline;
